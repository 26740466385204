import React from 'react';
import { Button, Popconfirm } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { ChildrenProp } from '../types/generic';
import Menu from './Menu';
import LogoBlackIcon from '@app-components/Icons/LogoBlackIcon';
import { useNavigate } from 'react-router-dom';
import { useUserSession } from 'src/hooks/user-session';

const MainLayout = ({ children }: ChildrenProp) => {
  const { user } = useUserSession();
  const handleLogout = () => {
    Cookies.remove('bcc_session');
    window.location.reload();
  };

  return (
    <div className="App">
      <header className="font-bold grid grid-cols-2 text-lg w-full h-20 p-6 pl-24 block absolute left-0 top-0 bg-white z-10 uppercase">
        <div>BCC Center</div>
        <div>
          <Popconfirm title="Cerrar sesión" onConfirm={handleLogout}>
            <LogoutOutlined className="float-right mt-1" />
          </Popconfirm>
          <p className="float-right mr-6 text-sm">
            <UserOutlined className="mr-1" />
            <span className="mt-1 block float-right">{user?.name}</span>
          </p>
        </div>
      </header>
      <div className="bg-zinc-800 text-white min-h-full w-20 block absolute left-0 top-0 z-10">
        <div className="w-full bg-orange-600 py-6 grid justify-center">
          <LogoBlackIcon />
        </div>
        <Menu />
      </div>

      <div className="pl-20 w-full min-h-screen bg-gray-50 pt-20 pb-10">
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
