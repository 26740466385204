import React from 'react';
import { AreaDTO } from '@app-entities/Area';
import { HTTPFetchStateStatus } from '@app-entities/common';

export interface AreasReducerState {
  status?: HTTPFetchStateStatus;
  data?: AreaDTO[];
}
export interface AreasReducerAction {
  type: string;
  payload: AreasReducerState;
}
export type AreasReducerDispatcher = React.Dispatch<AreasReducerAction>;
export type AreasReducer = (
  state: AreasReducerState,
  action: AreasReducerAction,
) => {};

export const areasReducer = (
  state: AreasReducerState,
  action: AreasReducerAction,
): AreasReducerState => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload.status };
    case 'SET_DATA':
      return { ...state, status: 'READY', data: action.payload.data };
    default:
      return state;
  }
};
