import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { FormInstance } from 'antd';
import { create } from 'zustand';

interface CategoryFormState {
  isFormOpen: boolean;
  currentCategory: ServiceCategoryDTO | null;
  form: FormInstance | null;
  openForm: (category?: ServiceCategoryDTO) => void;
  setFormInstance: (form: FormInstance) => void;
  closeForm: () => void;
}

export const categoryFormStore = create<CategoryFormState>((set) => ({
  isFormOpen: false,
  form: null,
  currentCategory: null,
  openForm: (category) =>
    set((state) => {
      if (state.form) {
        state.form.resetFields();

        if (category) {
          state.form.setFieldsValue(category);
        }
      }

      return { currentCategory: category ?? null, isFormOpen: true };
    }),
  setFormInstance: (form) => set((state) => ({ form })),
  closeForm: () => set((state) => ({ isFormOpen: false })),
}));
