import React, { useContext, useEffect, useState } from 'react';
import { Form, FormInstance } from 'antd';
import MainLayout from '@app-components/Layout/MainLayout';

import { CreateQuoteContext } from '@app-contexts/quotes/CreateQuoteContext';
import { CreateQuoteContextProvider } from '@app-contexts/quotes/CreateQuoteContextProvider';
import FirstSage from './FirstStage';
import SecondStage from './SecondStage';
import ThirdStage from './ThirdStage';
import { useParams } from 'react-router-dom';
import { fetchQuote } from '@app-actions/quotes';
import ContentLoader from '@app-components/Layout/ContentLoader';
import dayjs from '@app-utils/extendedDaysJs';
import {
  QuoteCreateSelectedAreasConfigMap,
  QuoteCreateSelectedServiceConfigMap,
} from '@app-reducers/quote-create';
import { fetchAreaTypes } from '@app-actions/area-types';
import { fetchAreas } from '@app-actions/areas';
import { fetchServiceCategories } from '@app-actions/service-categories';
import { fetchServices } from '@app-actions/services';
import { withAuthentication } from '@app-components/HOCs/withAuthentication';

const InjectFormFromContext = () => {
  const {
    areaTypesReducer: [areaTypes, areaTypesDispatch],
    areasReducer: [areas, areasDispatch],
    serviceCategoriesReducer: [serviceCategories, serviceCategoriesDispatch],
    servicesReducer: [services, servicesDispatch],
    form: formInstance,
    creationDataReducer: [creationData, creationDataDispatch],
  } = useContext(CreateQuoteContext);
  const { id } = useParams();
  const form = formInstance as FormInstance;
  const [quoteToUpdateLoaded, setQuoteToUpdateLoaded] =
    useState<boolean>(false);

  const preLoadedDataReady =
    areas.data && areaTypes.data && services.data && serviceCategories.data;

  useEffect(() => {
    if (id && preLoadedDataReady) {
      fetchQuote(id).then((quote) => {
        const startDate = dayjs(quote.event_start_date);
        const endDate = dayjs(quote.event_end_date);
        const selectedAreas: QuoteCreateSelectedAreasConfigMap = {};
        const formValues: { [key: string]: any } = {
          start_date: startDate,
          end_date: endDate,
          event_name: quote.event_name,
          company: quote.company,
          atention_to: quote.atention_to,
          phone: quote.phone,
          email: quote.email,
          expected_attendees: quote.expected_attendees,
          requester_name: quote.requester_name,
          tax_invoice_data: quote.tax_invoice_data,
        };

        quote.areas.forEach((area) => {
          const selectedServices: QuoteCreateSelectedServiceConfigMap = {};

          area.services.map((service) => {
            formValues[`${area.area_id}_${service.service_id}_service_days`] =
              service.days;
            formValues[
              `${area.area_id}_${service.service_id}_service_quantity`
            ] = service.quantity;

            selectedServices[service.service_id] = {
              data: services.data?.find(
                (serviceInList) => serviceInList.id === service.service_id,
              )!,
              quantity: service.quantity,
              days: service.days,
              is_included: service.is_included,
            };
          });

          formValues[`${area.area_id}_entry_type`] = area.entry_type;
          formValues[`${area.area_id}_event_type`] = area.event_type;
          formValues[`${area.area_id}_used_space`] = area.used_space;

          selectedAreas[area.area_id] = {
            data: areas.data?.find(
              (areaInList) => areaInList.id === area.area_id,
            )!,
            installation_days: area.installation_days,
            uninstallation_days: area.uninstallation_days,
            installation_same_day: area.installation_same_day,
            entry_type: area.entry_type,
            event_type: area.event_type,
            used_space: area.used_space,
            selected_services: selectedServices,
            generic_services: area.generic_services.map((genericService) => {
              formValues[`${area.area_id}_${genericService.id}_service_name`] =
                genericService.name;
              formValues[
                `${area.area_id}_${genericService.id}_service_description`
              ] = genericService.description;
              formValues[
                `${area.area_id}_${genericService.id}_service_quantity`
              ] = genericService.quantity;
              formValues[`${area.area_id}_${genericService.id}_service_days`] =
                genericService.days;
              formValues[`${area.area_id}_${genericService.id}_service_price`] =
                genericService.price;

              return {
                id: genericService.id,
                category: serviceCategories.data?.find(
                  (serviceCategory) =>
                    serviceCategory.id === genericService.category.id,
                )!,
                name: genericService.name,
                description: genericService.description,
                quantity: genericService.quantity,
                days: genericService.days,
                price: genericService.price,
                is_included: genericService.is_included,
              };
            }),
          };
        });

        creationDataDispatch({
          type: 'SET_PRELOADED_DATA',
          payload: {
            start_date: startDate,
            end_date: endDate,
            current_stage: 1,
            event_discount_percentage: quote.event_discount_percentage,
            installation_discount_percentage:
              quote.installation_discount_percentage,
            uninstallation_discount_percentage:
              quote.uninstallation_discount_percentage,
            selectedAreas: selectedAreas,
          },
        });

        form.setFieldsValue(formValues);

        setQuoteToUpdateLoaded(true);
      });
    }
  }, [areas, areaTypes, services, serviceCategories]);

  useEffect(() => {
    (async () => {
      fetchAreaTypes(areaTypesDispatch);
      fetchAreas(areasDispatch);
      fetchServiceCategories(serviceCategoriesDispatch);
      fetchServices(servicesDispatch);
    })();
  }, []);

  return id && !quoteToUpdateLoaded ? (
    <ContentLoader />
  ) : (
    <Form name="basic" autoComplete="off" form={form}>
      {creationData.current_stage === 1 && (
        <FirstSage areaTypes={areaTypes} areas={areas}></FirstSage>
      )}
      {creationData.current_stage === 2 && (
        <SecondStage
          serviceCategories={serviceCategories}
          services={services}
        ></SecondStage>
      )}
      {creationData.current_stage === 3 && <ThirdStage></ThirdStage>}
    </Form>
  );
};
const QuotesCreatePage = () => {
  return (
    <MainLayout>
      <CreateQuoteContextProvider>
        <InjectFormFromContext />
      </CreateQuoteContextProvider>
    </MainLayout>
  );
};

export default withAuthentication(QuotesCreatePage);
