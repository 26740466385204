import React from 'react';
import { H2Title } from '@app-components/Layout/Text';
import { Form, Input, Switch } from 'antd';
import { areaTypeFormStore } from '../../hooks/area-type-form-store';
import { AreaTypeDTO } from '@app-entities/AreaType';

const AreaTypeForm = ({
  currentAreaType,
}: {
  currentAreaType: AreaTypeDTO | null;
}) => {
  const form = areaTypeFormStore((state) => state.form) ?? undefined;

  return (
    <>
      <H2Title>{currentAreaType ? 'Editar' : 'Nueva'} clasificación</H2Title>
      <Form
        form={form}
        initialValues={{
          is_active: true,
        }}
      >
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Nombre</span>}
            name="name"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un nombre.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Activo</span>}
            name="is_active"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default AreaTypeForm;
