import React, { useEffect, useReducer, useState } from 'react';
import { Modal, Form, message } from 'antd';
import ServiceForm from './ServiceForm';
import { serviceFormStore } from '../../hooks/service-form-store';
import {
  createService,
  fetchServices,
  updateService,
} from '@app-actions/services';
import { CreateServiceDTO } from '@app-entities/CreateService';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { ServicesReducerDispatcher } from '@app-reducers/services';

const ServiceCreateUpdateModal = ({
  serviceCategory,
  servicesDispatch,
}: {
  serviceCategory: ServiceCategoryDTO;
  servicesDispatch: ServicesReducerDispatcher;
}) => {
  const [createFormInstance] = Form.useForm();
  const isOpen = serviceFormStore((state) => state.isFormOpen);
  const form = serviceFormStore((state) => state.form);
  const currentService = serviceFormStore((state) => state.currentService);
  const closeForm = serviceFormStore((state) => state.closeForm);
  const setFormInstance = serviceFormStore((state) => state.setFormInstance);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  useEffect(() => {
    setFormInstance(createFormInstance);
  }, []);

  const handleOk = async () => {
    setIsCreateLoading(true);
    try {
      await form?.validateFields();
      const serviceData: CreateServiceDTO = {
        name: form?.getFieldValue('name'),
        description: form?.getFieldValue('description'),
        price: form?.getFieldValue('price'),
        quantity: form?.getFieldValue('quantity'),
        is_active: form?.getFieldValue('is_active'),
        enable_day_quantity: form?.getFieldValue('enable_day_quantity'),
        has_stock: form?.getFieldValue('has_stock') ?? false,
        service_category_id: serviceCategory.id,
      };

      if (!currentService) {
        await createService(serviceData);
      } else {
        await updateService(currentService.id, serviceData);
      }

      closeForm();
      fetchServices(servicesDispatch, serviceCategory.id);
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }

    setIsCreateLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      confirmLoading={isCreateLoading}
      onCancel={closeForm}
    >
      <ServiceForm currentService={currentService} />
    </Modal>
  );
};

export default ServiceCreateUpdateModal;
