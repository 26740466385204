import { createContext } from 'react';
import { FormInstance } from 'antd';
import { AreasReducerDispatcher, AreasReducerState } from '@app-reducers/areas';
import {
  AreaTypesReducerDispatcher,
  AreaTypesReducerState,
} from '@app-reducers/area-types';
import {
  QuoteCreateReducerDispatcher,
  QuoteCreateReducerState,
} from '@app-reducers/quote-create';
import {
  ServicesReducerDispatcher,
  ServicesReducerState,
} from '@app-reducers/services';
import {
  ServiceCategoriesReducerDispatcher,
  ServiceCategoriesReducerState,
} from '@app-reducers/service-categories';

export interface CreateQuoteContextValue {
  areaTypesReducer: [AreaTypesReducerState, AreaTypesReducerDispatcher];
  areasReducer: [AreasReducerState, AreasReducerDispatcher];
  serviceCategoriesReducer: [
    ServiceCategoriesReducerState,
    ServiceCategoriesReducerDispatcher,
  ];
  servicesReducer: [ServicesReducerState, ServicesReducerDispatcher];
  form: FormInstance | null;
  creationDataReducer: [QuoteCreateReducerState, QuoteCreateReducerDispatcher];
}

export const CreateQuoteContext = createContext<CreateQuoteContextValue>({
  areaTypesReducer: [{}, () => ({})],
  areasReducer: [{}, () => ({})],
  serviceCategoriesReducer: [{}, () => ({})],
  servicesReducer: [{}, () => ({})],
  form: null,
  creationDataReducer: [{}, () => ({})],
});
