const QuotesIcon = () => {
  return (
    <svg
      id="menu_cotizaciones"
      data-name="menu cotizaciones"
      xmlns="http://www.w3.org/2000/svg"
      width="16.385"
      height="22.301"
      viewBox="0 0 16.385 22.301"
    >
      <path
        id="Trazado_7573"
        data-name="Trazado 7573"
        d="M881.222-9994.588l.036-.646h1.274v-21H867.524v1.282h-.645v-1.938H883.2v.3q0,10.84,0,21.679c0,.107.013.216.021.323Zm-.61,0c-.013-.214-.025-.429-.039-.659H867.516v-19.029h-.654v.307q0,9.541,0,19.081c0,.1-.014.2-.021.3Zm.959-6.108v-.25q0-3.713,0-7.427a1,1,0,0,0-1.122-1.126c-3.608,0-7.216.01-10.824-.008a1.068,1.068,0,0,0-1.135,1.129c.02,2.476.008,4.951.008,7.427v.255Zm-4.591-6.512v.965h-7.818v-.965Zm-7.819,3.271h7.823v.957h-7.823Zm0-.675v-.95h7.818v.95Zm-.006,2.319h7.823v.94h-7.823Zm7.83-5.6h-7.834c0-.223-.012-.445,0-.667a.3.3,0,0,1,.3-.288c.043,0,.087,0,.13,0H876.8c.057,0,.114,0,.19.009Zm3.919,1.644h-3.248v-.956h3.248Zm0,3.269h-3.248v-.957h3.248Zm-3.245-1.636v-.949H880.9v.949Zm0,2.313H880.9v.954H877.66Zm0-5.582v-.965h.656c.725,0,1.451,0,2.176,0,.311,0,.418.108.42.417,0,.151,0,.3,0,.457,0,.021,0,.042-.01.09Zm-8.284-6.4a.988.988,0,0,0-.879,1.06.993.993,0,0,0,1.046.9c.2,0,.392-.006.588,0a.322.322,0,0,1,.322.318.307.307,0,0,1-.311.324c-.309-.019-.641.115-.927-.112a.085.085,0,0,0-.079,0c-.166.1-.328.2-.5.3a1.065,1.065,0,0,0,.84.489v.627h.655v-.627l.091-.017a.991.991,0,0,0,.879-1.062.993.993,0,0,0-1.047-.9c-.2,0-.392.006-.588,0a.322.322,0,0,1-.321-.318.306.306,0,0,1,.311-.324c.309.02.641-.116.926.112a.085.085,0,0,0,.08,0c.166-.095.329-.2.534-.318a3.192,3.192,0,0,0-.405-.326,3.056,3.056,0,0,0-.473-.172v-.6h-.648v.626Zm10.213,2.6v-.629H872.1v.629Zm1.967,13.487v-.622h-4.228v.622Zm0,1.306v-.619H877.33v.619Zm-9.453-16.73v.633h4.06v-.633Zm9.152-1.331h.628v-.628h-.628Zm.626.686h-.628v.627h.628Z"
        transform="translate(-866.841 10016.889)"
        fill="#ef9751"
      />
    </svg>
  );
};

export default QuotesIcon;
