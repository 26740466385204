import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Select,
  InputNumber,
  Checkbox,
  FormInstance,
  Input,
  Alert,
} from 'antd';
import { LeftOutlined, CloseOutlined } from '@ant-design/icons';
import {
  nextStage,
  previousStage,
  configureSelectedArea,
  getEventDays,
  getInstallationCalculatedDays,
} from '@app-actions/quote-create';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import { H2Title, H1Title } from '@app-components/Layout/Text';
import { CreateQuoteContext } from '@app-contexts/quotes/CreateQuoteContext';
import { Label } from '@app-components/Layout/Form';
import {
  ENTRY_TYPE,
  EVENT_TYPES,
  FETCH_FINISHED_STATUS,
} from '@app-utils/constants';
import QuoteTotals from './QuoteTotals';
import { ServiceDTO } from '@app-entities/Service';
import { UUID } from '@app-entities/common';
import {
  QuoteCreateGenericServiceConfig,
  QuoteCreateSelectedAreaConfig,
  QuoteCreateSelectedServiceConfig,
  QuoteCreateSelectedServiceConfigMap,
} from '@app-reducers/quote-create';
import { FakeInput } from '@app-components/Layout/Form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import DatesHeading from './DatesHeading';
import { EntryType } from '@app-entities/EntryType';
import { EventType } from '@app-entities/EventType';
import ContentLoader from '@app-components/Layout/ContentLoader';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { v4 as uuidv4 } from 'uuid';
import { ServicesReducerState } from '@app-reducers/services';
import { ServiceCategoriesReducerState } from '@app-reducers/service-categories';
import { getInstallationStartDate, isNotEmpty } from '@app-utils/helpers';
import { getServiceAvailability } from '@app-actions/services';
import dayjs from '@app-utils/extendedDaysJs';
import { ServiceAvailabilityDTO } from '@app-entities/ServiceAvailability';

type ServicesByTypeMap = {
  [key: UUID]: ServiceDTO[];
};

interface ServiceAvailabilityValidationResult {
  success: boolean;
  message: string | null;
}

const SecondStage = ({
  services,
  serviceCategories,
}: {
  services: ServicesReducerState;
  serviceCategories: ServiceCategoriesReducerState;
}) => {
  const {
    creationDataReducer: [creationData, creationDataDispatch],
    form: formInstance,
  } = useContext(CreateQuoteContext);
  const [serviceAvailabilityErrors, setServiceAvailabilityErrors] = useState<
    ServiceAvailabilityValidationResult[]
  >([]);

  const form = formInstance as FormInstance;
  const servicesByTypeMap: ServicesByTypeMap = {};
  const createServicesByTypeMapMap = () => {
    if (!services || !services.data) return;
    services.data.forEach((service) => {
      if (servicesByTypeMap.hasOwnProperty(service.service_category_id)) {
        servicesByTypeMap[service.service_category_id].push(service);
      } else {
        servicesByTypeMap[service.service_category_id] = [service];
      }
    });
  };

  createServicesByTypeMapMap();

  const onEventTypeChange = (
    value: string,
    option: any,
    selectedAreaId: string,
  ) => {
    configureSelectedArea(creationDataDispatch, {
      ...creationData.selectedAreas![selectedAreaId],
      event_type: value as EventType,
    });
  };

  const onEntryTypeChange = (
    value: string,
    option: any,
    selectedAreaId: string,
  ) => {
    configureSelectedArea(creationDataDispatch, {
      ...creationData.selectedAreas![selectedAreaId],
      entry_type: value as EntryType,
    });
  };

  const onUsedSpaceChange = (value: number | null, selectedAreaId: string) => {
    configureSelectedArea(creationDataDispatch, {
      ...creationData.selectedAreas![selectedAreaId],
      used_space: value,
    });
  };

  const handleServicesChange = (
    value: string,
    option: any,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const existsInArray = Object.values(
      selectedArea.selected_services ?? {},
    ).find((selectedService) => selectedService.data.id === value);
    if (existsInArray) return;

    const serviceData: ServiceDTO | undefined = services.data?.find(
      (service) => service.id === value,
    );
    if (!serviceData) return;

    const installationAndEventDays: number =
      getInstallationCalculatedDays(creationData, selectedArea) +
      getEventDays(creationData);

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      selected_services: {
        ...selectedArea.selected_services,
        [serviceData.id]: {
          data: serviceData,
          ...(serviceData.has_stock ? { days: installationAndEventDays } : {}),
        },
      },
    });
  };

  const onSelectedServiceQuantityChange = (
    value: number | null,
    selectedService: QuoteCreateSelectedServiceConfig,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      selected_services: {
        ...selectedArea.selected_services,
        [selectedService.data.id]: {
          ...selectedService,
          quantity: value,
        },
      },
    });
  };

  const onSelectedServiceDaysChange = (
    value: number | null,
    selectedService: QuoteCreateSelectedServiceConfig,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      selected_services: {
        ...selectedArea.selected_services,
        [selectedService.data.id]: {
          ...selectedService,
          days: value,
        },
      },
    });
  };

  const removeSelectedService = (
    serviceId: string,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    if (!selectedArea?.selected_services?.hasOwnProperty(serviceId)) return;

    const newSelectedServices: QuoteCreateSelectedServiceConfigMap = {
      ...selectedArea.selected_services,
    };
    delete newSelectedServices[serviceId];

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      selected_services: newSelectedServices,
    });
  };

  const setServiceIncluded = (
    e: CheckboxChangeEvent,
    selectedService: QuoteCreateSelectedServiceConfig,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      selected_services: {
        ...selectedArea.selected_services,
        [selectedService.data.id]: {
          ...selectedService,
          is_included: e.target.checked,
        },
      },
    });
  };

  const setGenericServiceIncluded = (
    checked: boolean,
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const foundGenericServiceIndex = selectedArea.generic_services!.findIndex(
      (genericService) => genericService.id === id,
    );
    const currentGenericService =
      selectedArea.generic_services![foundGenericServiceIndex];
    const newGenericServices = [...selectedArea.generic_services!];

    newGenericServices[foundGenericServiceIndex] = {
      ...currentGenericService,
      is_included: checked,
      ...(checked ? { price: 2 } : {}),
    };

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const setGenericServiceName = (
    value: string | null,
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const foundGenericServiceIndex = selectedArea.generic_services!.findIndex(
      (genericService) => genericService.id === id,
    );
    const currentGenericService =
      selectedArea.generic_services![foundGenericServiceIndex];
    const newGenericServices = [...selectedArea.generic_services!];

    newGenericServices[foundGenericServiceIndex] = {
      ...currentGenericService,
      name: value,
    };

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const setGenericServiceDescription = (
    value: string | null,
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const foundGenericServiceIndex = selectedArea.generic_services!.findIndex(
      (genericService) => genericService.id === id,
    );
    const currentGenericService =
      selectedArea.generic_services![foundGenericServiceIndex];
    const newGenericServices = [...selectedArea.generic_services!];

    newGenericServices[foundGenericServiceIndex] = {
      ...currentGenericService,
      description: value,
    };

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const onGenericServiceQuantityChange = (
    value: number | null,
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const foundGenericServiceIndex = selectedArea.generic_services!.findIndex(
      (genericService) => genericService.id === id,
    );
    const currentGenericService =
      selectedArea.generic_services![foundGenericServiceIndex];
    const newGenericServices = [...selectedArea.generic_services!];

    newGenericServices[foundGenericServiceIndex] = {
      ...currentGenericService,
      quantity: value,
    };

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const onGenericServiceDaysChange = (
    value: number | null,
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const foundGenericServiceIndex = selectedArea.generic_services!.findIndex(
      (genericService) => genericService.id === id,
    );
    const currentGenericService =
      selectedArea.generic_services![foundGenericServiceIndex];
    const newGenericServices = [...selectedArea.generic_services!];

    newGenericServices[foundGenericServiceIndex] = {
      ...currentGenericService,
      days: value,
    };

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const onGenericServicePriceChange = (
    value: number | null,
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    const foundGenericServiceIndex = selectedArea.generic_services!.findIndex(
      (genericService) => genericService.id === id,
    );
    const currentGenericService =
      selectedArea.generic_services![foundGenericServiceIndex];
    const newGenericServices = [...selectedArea.generic_services!];

    newGenericServices[foundGenericServiceIndex] = {
      ...currentGenericService,
      price: isNotEmpty(value) ? value : null,
    };

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const removeGenericService = (
    id: UUID,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    if (!selectedArea?.generic_services) return;

    const foundGenericServiceIndex = selectedArea?.generic_services.findIndex(
      (genericService) => genericService.id === id,
    );

    if (foundGenericServiceIndex < 0) return;

    const newGenericServices: QuoteCreateGenericServiceConfig[] = [
      ...selectedArea.generic_services,
    ];
    newGenericServices.splice(foundGenericServiceIndex, 1);

    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: newGenericServices,
    });
  };

  const addGenericService = (
    category: ServiceCategoryDTO,
    selectedArea: QuoteCreateSelectedAreaConfig,
  ) => {
    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      generic_services: [
        ...(selectedArea?.generic_services ?? []),
        {
          id: uuidv4(),
          category,
        },
      ],
    });
  };

  const validateServiceStock = async () => {
    setServiceAvailabilityErrors([]);

    const servicesTotalQuantityMap: {
      [key: UUID]: ServiceDTO & {
        totalQuantity: number;
        startDate: dayjs.Dayjs;
      };
    } = {};

    Object.values(creationData.selectedAreas ?? {}).forEach((selectedArea) => {
      Object.values(selectedArea.selected_services ?? {}).forEach(
        (selectedService) => {
          const installationStartDate = getInstallationStartDate(
            creationData.start_date!,
            selectedArea.installation_days!,
          );

          if (
            servicesTotalQuantityMap.hasOwnProperty(selectedService.data.id)
          ) {
            servicesTotalQuantityMap[selectedService.data.id].totalQuantity +=
              selectedService.quantity ?? 0;

            if (
              installationStartDate <
              servicesTotalQuantityMap[selectedService.data.id].startDate
            ) {
              servicesTotalQuantityMap[selectedService.data.id].startDate =
                installationStartDate;
            }
          } else {
            servicesTotalQuantityMap[selectedService.data.id] = {
              ...selectedService.data,
              startDate: installationStartDate,
              totalQuantity: selectedService.quantity ?? 0,
            };
          }
        },
      );
    });

    const messages: ServiceAvailabilityValidationResult[] = await Promise.all(
      Object.values(servicesTotalQuantityMap).map(
        async (serviceTotalQuantity) => {
          const availabilityPerDay: ServiceAvailabilityDTO[] =
            await getServiceAvailability(
              serviceTotalQuantity.id,
              serviceTotalQuantity.startDate,
              creationData.end_date!,
            );
          const minAvailable = Math.min(
            ...availabilityPerDay.map((availability) => availability.available),
          );

          const success = serviceTotalQuantity.totalQuantity <= minAvailable;
          const message = `${serviceTotalQuantity.name} no cuenta con stock suficiente. Disponible: ${minAvailable}. Total agregado: ${serviceTotalQuantity.totalQuantity}`;

          return {
            success,
            message: !success ? message : null,
          };
        },
      ),
    );

    if (messages.find((message) => !message.success)) {
      setServiceAvailabilityErrors(
        messages.filter((message) => !message.success),
      );

      throw new Error("Some validations didn't pass");
    }
  };

  const servicesFetchFinished =
    FETCH_FINISHED_STATUS.includes(services.status ?? 'LOADING') &&
    FETCH_FINISHED_STATUS.includes(serviceCategories.status ?? 'LOADING');

  return (
    <CenteredContainer width="normal">
      <div className="grid grid-cols-2">
        <H1Title>
          <button onClick={() => previousStage(creationDataDispatch)}>
            <LeftOutlined className="text-md relative -top-1 text-orange-600" />
          </button>
          Cotizador
        </H1Title>
        <DatesHeading creationData={creationData} className="mt-2 text-right" />
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-4">
          {Object.values(creationData.selectedAreas ?? {}).map(
            (selectedArea, index) => {
              const availableSpace = Math.min(
                ...Object.values(selectedArea.availability_map ?? {}).map(
                  (dayAvailability) => dayAvailability.available,
                ),
              );

              return (
                <CardPanel className="mb-6" key={index}>
                  <H2Title>{selectedArea.data.name}</H2Title>
                  <div className="grid grid-cols-5 mt-6">
                    <div className="col-span-2 px-6">
                      <Label>Tipo de evento</Label>
                      <Form.Item
                        name={`${selectedArea.data.id}_event_type`}
                        rules={[
                          {
                            required: true,
                            message:
                              'Es necesario selecionar el tipo de evento.',
                          },
                        ]}
                      >
                        <Select
                          options={Object.values(EVENT_TYPES)}
                          onChange={(value: string, option: any) =>
                            onEventTypeChange(
                              value,
                              option,
                              selectedArea.data.id,
                            )
                          }
                        ></Select>
                      </Form.Item>
                    </div>
                    <div className="col-span-2 px-6">
                      <Label>Entrada</Label>
                      <Form.Item
                        name={`${selectedArea.data.id}_entry_type`}
                        rules={[
                          {
                            required: true,
                            message:
                              'Es necesario selecionar el tipo de entrada.',
                          },
                        ]}
                      >
                        <Select
                          options={Object.values(ENTRY_TYPE)}
                          onChange={(value: string, option: any) =>
                            onEntryTypeChange(
                              value,
                              option,
                              selectedArea.data.id,
                            )
                          }
                        ></Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 mt-6">
                    <div className="px-6 col-span-2">
                      <Label>Espacio a ocupar</Label>
                      <Form.Item
                        name={`${selectedArea.data.id}_used_space`}
                        rules={[
                          {
                            required: true,
                            message:
                              'Es necesario selecionar el espacio a ocupar.',
                          },
                        ]}
                        initialValue={
                          selectedArea.data.only_full_area
                            ? Math.min(
                                availableSpace,
                                selectedArea.data.total_area,
                              )
                            : availableSpace
                        }
                      >
                        <InputNumber
                          max={availableSpace}
                          min={1}
                          value={selectedArea.used_space}
                          disabled={selectedArea.data.only_full_area}
                          step={1}
                          className="w-full"
                          addonAfter={
                            <>
                              m<sup>2</sup>
                            </>
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'enter') {
                              e.preventDefault();
                            }
                          }}
                          onChange={(value) =>
                            onUsedSpaceChange(value, selectedArea.data.id)
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="mt-6">
                    {!servicesFetchFinished ? (
                      <ContentLoader />
                    ) : (
                      <>
                        {serviceCategories &&
                          serviceCategories.data &&
                          serviceCategories.data.map(
                            (serviceCategory, index) => {
                              const selectedServices = Object.values(
                                selectedArea.selected_services ?? {},
                              ).filter(
                                (selectedService) =>
                                  selectedService.data.service_category_id ===
                                  serviceCategory.id,
                              );

                              const genericServices = (
                                selectedArea.generic_services ?? []
                              ).filter(
                                (genericService) =>
                                  genericService.category.id ===
                                  serviceCategory.id,
                              );

                              return (
                                <div
                                  className="mb-3 first:border-t border-b border-gray-300 py-8"
                                  key={index}
                                >
                                  <div className="grid grid-cols-3">
                                    <div className="px-6 col-span-2 mb-6">
                                      <Label>{serviceCategory.name}</Label>
                                      <Select
                                        options={(
                                          servicesByTypeMap[
                                            serviceCategory.id
                                          ] ?? []
                                        ).map((service) => ({
                                          label: service.name,
                                          value: service.id,
                                        }))}
                                        className="w-full"
                                        onSelect={(value, option) =>
                                          handleServicesChange(
                                            value,
                                            option,
                                            selectedArea,
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="px-6 col-span-1">
                                      <Button
                                        onClick={async () => {
                                          addGenericService(
                                            serviceCategory,
                                            selectedArea,
                                          );
                                        }}
                                        type="primary"
                                        className="block mt-8"
                                        size="small"
                                      >
                                        Agregar servicio especial
                                      </Button>
                                    </div>
                                  </div>
                                  {!!selectedServices.length ||
                                  !!genericServices.length ? (
                                    <div className="px-6">
                                      <table className="w-full">
                                        <thead>
                                          <tr>
                                            {selectedArea.event_type ===
                                              EVENT_TYPES.CONGRESS.value && (
                                              <th>Incluido</th>
                                            )}
                                            <th className="px-2">Servicio</th>
                                            <th className="px-2">
                                              Especificaciones
                                            </th>
                                            <th className="px-2">Cantidad</th>
                                            <th className="px-2">Días</th>
                                            <th className="px-2">Precio</th>

                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {selectedServices.map(
                                            (selectedService, index) => (
                                              <tr key={`selected-${index}`}>
                                                {selectedArea.event_type ===
                                                  EVENT_TYPES.CONGRESS
                                                    .value && (
                                                  <td className="px-2 w-8 py-1">
                                                    <Checkbox
                                                      checked={
                                                        !!selectedService.is_included
                                                      }
                                                      onChange={(e) =>
                                                        setServiceIncluded(
                                                          e,
                                                          selectedService,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                )}
                                                <td className="w-4/12 px-2 py-1 align-top">
                                                  <FakeInput>
                                                    {selectedService.data.name}
                                                  </FakeInput>
                                                </td>
                                                <td className="px-2 py-1 align-top">
                                                  <FakeInput>
                                                    {
                                                      selectedService.data
                                                        .description
                                                    }
                                                  </FakeInput>
                                                </td>
                                                <td className="w-1/12 px-2 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${selectedService.data.id}_service_quantity`}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: '',
                                                        type: 'integer',
                                                      },
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      className="w-full relative"
                                                      min={1}
                                                      value={
                                                        selectedService.quantity
                                                      }
                                                      defaultValue={
                                                        selectedService.quantity ??
                                                        undefined
                                                      }
                                                      step={1}
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(value) =>
                                                        onSelectedServiceQuantityChange(
                                                          value,
                                                          selectedService,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="w-1/12 px-2 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${selectedService.data.id}_service_days`}
                                                    rules={[
                                                      {
                                                        required:
                                                          selectedService.data
                                                            .enable_day_quantity,
                                                        message: '',
                                                        type: 'integer',
                                                      },
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      className="w-full relative"
                                                      min={1}
                                                      value={
                                                        selectedService.days
                                                      }
                                                      defaultValue={
                                                        selectedService.days ??
                                                        undefined
                                                      }
                                                      step={1}
                                                      // disabled={
                                                      //   !selectedService.data
                                                      //     .enable_day_quantity ||
                                                      //   selectedService.data
                                                      //     .has_stock
                                                      // }
                                                      disabled={
                                                        !selectedService.data
                                                          .enable_day_quantity
                                                      }
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(value) =>
                                                        onSelectedServiceDaysChange(
                                                          value,
                                                          selectedService,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="py1 align-top">
                                                  <FakeInput>
                                                    {selectedService.data.price}
                                                  </FakeInput>
                                                </td>
                                                <td className="px-2 py-1">
                                                  <Button
                                                    type="text"
                                                    onClick={(e) => {
                                                      removeSelectedService(
                                                        selectedService.data.id,
                                                        selectedArea,
                                                      );
                                                    }}
                                                  >
                                                    <CloseOutlined />
                                                  </Button>
                                                </td>
                                              </tr>
                                            ),
                                          )}
                                          {genericServices.map(
                                            (genericService) => (
                                              <tr
                                                key={`generic-${genericService.id}`}
                                              >
                                                {selectedArea.event_type ===
                                                  EVENT_TYPES.CONGRESS
                                                    .value && (
                                                  <td className="px-2 w-8">
                                                    <Checkbox
                                                      checked={
                                                        !!genericService.is_included
                                                      }
                                                      onChange={(e) =>
                                                        setGenericServiceIncluded(
                                                          e.target.checked,
                                                          genericService.id,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                )}
                                                <td className="w-4/12 px-2 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${genericService.id}_service_name`}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          'Es necesario ingresar un valor',
                                                        type: 'string',
                                                      },
                                                      {
                                                        min: 3,
                                                        max: 200,
                                                        message:
                                                          'Debe tener entre 3 y 400 caracteres',
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      className="w-full relative"
                                                      value={
                                                        genericService.name ??
                                                        ''
                                                      }
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(e) =>
                                                        setGenericServiceName(
                                                          e.target.value,
                                                          genericService.id,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="px-2 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${genericService.id}_service_description`}
                                                    rules={[
                                                      {
                                                        required: false,
                                                        type: 'string',
                                                      },
                                                      {
                                                        max: 400,
                                                        message:
                                                          'No puede tener mas de 400 caracteres',
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      className="w-full relative"
                                                      value={
                                                        genericService.description ??
                                                        ''
                                                      }
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(e) =>
                                                        setGenericServiceDescription(
                                                          e.target.value,
                                                          genericService.id,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="w-1/12 px-2 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${genericService.id}_service_quantity`}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: '',
                                                        type: 'integer',
                                                      },
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      className="w-full relative"
                                                      min={1}
                                                      value={
                                                        genericService.quantity
                                                      }
                                                      step={1}
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(value) =>
                                                        onGenericServiceQuantityChange(
                                                          value,
                                                          genericService.id,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="w-1/12 px-2 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${genericService.id}_service_days`}
                                                    rules={[
                                                      {
                                                        required: false,
                                                        message: '',
                                                        type: 'integer',
                                                      },
                                                    ]}
                                                  >
                                                    <InputNumber
                                                      className="w-full relative"
                                                      min={1}
                                                      value={
                                                        genericService.days
                                                      }
                                                      step={1}
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(value) =>
                                                        onGenericServiceDaysChange(
                                                          value,
                                                          genericService.id,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="w-1/12 py-1 align-top">
                                                  <Form.Item
                                                    name={`${selectedArea.data.id}_${genericService.id}_service_price`}
                                                  >
                                                    <InputNumber
                                                      className="w-full relative"
                                                      min={0}
                                                      value={
                                                        genericService.price
                                                      }
                                                      step={1}
                                                      disabled={
                                                        genericService.is_included
                                                      }
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'enter') {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                      onChange={(value) =>
                                                        onGenericServicePriceChange(
                                                          value,
                                                          genericService.id,
                                                          selectedArea,
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </td>
                                                <td className="px-2 py-1">
                                                  <Button
                                                    type="text"
                                                    onClick={(e) => {
                                                      removeGenericService(
                                                        genericService.id,
                                                        selectedArea,
                                                      );
                                                    }}
                                                  >
                                                    <CloseOutlined />
                                                  </Button>
                                                </td>
                                              </tr>
                                            ),
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : (
                                    <p className="w-full text-center">
                                      Sin servicios seleccionados
                                    </p>
                                  )}
                                </div>
                              );
                            },
                          )}
                      </>
                    )}
                  </div>
                </CardPanel>
              );
            },
          )}
        </div>
        <div className="col-span-2">
          <QuoteTotals />
        </div>
      </div>

      {!!serviceAvailabilityErrors.length && (
        <div className="w-8/12 mx-auto mt-8">
          {serviceAvailabilityErrors.map((serviceAvailabilityError, index) => (
            <Alert
              key={index}
              message={serviceAvailabilityError.message}
              type="error"
              className="mb-1"
            />
          ))}
        </div>
      )}
      <div className="w-5/12 mx-auto mt-8 grid grid-cols-2 gap-6">
        <Button
          onClick={() => {
            previousStage(creationDataDispatch);
          }}
          type="ghost"
          className="block shadow-md rounded-3xl"
        >
          Regresar
        </Button>
        <Button
          onClick={async () => {
            try {
              await form.validateFields();
              // await validateServiceStock();
              nextStage(creationDataDispatch);
            } catch (e) {}
          }}
          type="primary"
          className="block"
        >
          Siguiente
        </Button>
      </div>
    </CenteredContainer>
  );
};

export default SecondStage;
