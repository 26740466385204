import React, { useState } from 'react';
import { H2Title } from '@app-components/Layout/Text';
import { Form, Input, InputNumber, Switch } from 'antd';
import { serviceFormStore } from '../../hooks/service-form-store';
import { ServiceDTO } from '@app-entities/Service';

const ServiceForm = ({
  currentService,
}: {
  currentService: ServiceDTO | null;
}) => {
  const form = serviceFormStore((state) => state.form) ?? undefined;
  const [hasStock, setHasStock] = useState(false);

  const handleHasStock = (checked: boolean) => {
    setHasStock(checked);
  };

  return (
    <>
      <H2Title>{currentService ? 'Editar' : 'Nuevo'} item</H2Title>
      <Form
        form={form}
        initialValues={{
          is_active: true,
          enable_day_quantity: false,
          has_stock: false,
        }}
      >
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Nombre</span>}
            name="name"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un nombre.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Precio</span>}
            name="price"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un precio.',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label={<span className="font-bold">Tipo de cobro por día</span>}
            name="enable_day_quantity"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Descripción</span>}
            name="description"
            className="px-6"
          >
            <Input.TextArea />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Activo</span>}
            name="is_active"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={<span className="font-bold">Aplica stock</span>}
            name="has_stock"
            valuePropName="checked"
            className="px-6"
          >
            <Switch onChange={handleHasStock} />
          </Form.Item>
        </div>
        {hasStock && (
          <div className="grid grid-cols-2 mt-6">
            <Form.Item
              label={<span className="font-bold">Cantidad</span>}
              name="quantity"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: 'Es necesario ingresar una cantidad.',
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </div>
        )}
      </Form>
    </>
  );
};

export default ServiceForm;
