import React, { useEffect, useReducer } from 'react';
import { Table, message, Popconfirm, Button } from 'antd';
import {
  SaveOutlined,
  EditOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {
  fetchQuotes,
  approveQuote,
  setLoadingQuotes,
  toggleArchiveQuote,
} from '@app-actions/quotes';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import MainLayout from '@app-components/Layout/MainLayout';
import { TabItem } from '@app-components/Layout/TabNav';
import { QuoteDTO } from '@app-entities/Quote';
import { quotesReducer } from '@app-reducers/quotes';
import PDFDocument from './PDFDocument';
import { FETCH_FINISHED_STATUS, PERMISSIONS } from '@app-utils/constants';
import ContentLoader from '@app-components/Layout/ContentLoader';
import { UUID } from '@app-entities/common';
import { useNavigate } from 'react-router-dom';
import { withAuthentication } from '@app-components/HOCs/withAuthentication';
import { useUserSession } from 'src/hooks/user-session';
import { hasPermission } from '@app-utils/helpers';

const { Column } = Table;

const QuotesListPage = ({
  activeQuotes,
  archivedQuotes,
}: {
  activeQuotes: boolean;
  archivedQuotes: boolean;
}) => {
  const { user } = useUserSession();
  const [quotes, quotesDispatch] = useReducer(quotesReducer, {});
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const refreshQuotes = () => {
    setLoadingQuotes(quotesDispatch, 'LOADING');
    fetchQuotes(quotesDispatch, {
      is_active: activeQuotes,
      is_archived: archivedQuotes,
    });
  };

  useEffect(() => {
    (async () => {
      refreshQuotes();
    })();
  }, [activeQuotes, archivedQuotes]);

  const onPDFSave = async (quote: QuoteDTO) => {
    const pdfblob = await pdf(PDFDocument(quote)).toBlob();

    const pdfUrl = window.URL.createObjectURL(pdfblob);
    const tempLink = document.createElement('a');
    tempLink.href = pdfUrl;
    tempLink.setAttribute(
      'download',
      `Cotización ${quote.folio}-${quote.numbering}`,
    );
    tempLink.click();
    setTimeout(() => URL.revokeObjectURL(tempLink.href), 7000);
  };

  const quotesFetchFinished = FETCH_FINISHED_STATUS.includes(
    quotes.status ?? 'LOADING',
  );

  const handleApproveQuote = async (id: UUID) => {
    try {
      await approveQuote(quotesDispatch, id);
      refreshQuotes();
      messageApi.open({
        type: 'success',
        content: 'Cotización aprobada',
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.response ? error.response.data.message : error.message,
      });
    }
  };

  const handleArchiveQuote = async (quote: QuoteDTO) => {
    try {
      await toggleArchiveQuote(quotesDispatch, quote.id);
      refreshQuotes();
      messageApi.open({
        type: 'success',
        content: `Cotización ${
          quote.is_archived ? 'desarchivada' : 'archivada'
        }`,
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.response ? error.response.data.message : error.message,
      });
    }
  };

  const canViewAll = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.view.value,
  ]);
  const updateQuotesPermission = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.update.value,
  ]);
  const updateSelfQuotesPermission = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.updateSelf.value,
  ]);
  const deleteQuotesPermission = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.delete.value,
  ]);
  const approveQuotesPermission = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.approve.value,
  ]);
  const filteredQuotes = quotes.data
    ? canViewAll
      ? quotes.data
      : quotes.data.filter((quote) => quote.user.id === user?.id)
    : [];

  return (
    <>
      {contextHolder}
      <MainLayout>
        <CenteredContainer width="full">
          <CardPanel>
            <div className="border-b-2 grid grid-cols-5 py-5">
              <div className="col-span-3">
                <TabItem
                  link="/cotizaciones"
                  isActive={!activeQuotes && !archivedQuotes}
                >
                  Realizadas
                </TabItem>
                <TabItem
                  link="/cotizaciones/archivadas"
                  isActive={archivedQuotes}
                >
                  Archivadas
                </TabItem>
                <TabItem link="/cotizaciones/cerradas" isActive={activeQuotes}>
                  Cerradas
                </TabItem>
              </div>
              <div className="col-span-2">
                <Link to={'/cotizaciones/calendario'}>
                  <Button
                    type="ghost"
                    className="block mt-4 shadow-md rounded-3xl float-right"
                    icon={<CalendarOutlined />}
                  >
                    Calendario
                  </Button>
                </Link>
              </div>
            </div>
            <div className="mt-6">
              {!quotesFetchFinished ? (
                <ContentLoader />
              ) : (
                <Table
                  dataSource={filteredQuotes.map(
                    (quote, index): QuoteDTO & { key: number } => ({
                      ...quote,
                      key: index,
                    }),
                  )}
                >
                  <Column
                    title="ID"
                    dataIndex="folio"
                    key="folio"
                    render={(value, quote: QuoteDTO) =>
                      `${quote.folio}-${quote.numbering}`
                    }
                  />
                  <Column
                    title="Cliente"
                    dataIndex="atention_to"
                    key="atention_to"
                  />
                  <Column title="Empresa" dataIndex="company" key="company" />
                  <Column
                    title="Fecha de creación"
                    dataIndex="created_at"
                    key="created_at"
                    render={(value) => dayjs(value).format('DD/MM/YYYY h:s')}
                  />
                  <Column
                    title="Vigencia"
                    dataIndex="expires_at"
                    key="expires_at"
                    render={(value) =>
                      value ? dayjs(value).format('DD/MM/YYYY h:s') : ''
                    }
                  />
                  <Column
                    title="Inicio evento"
                    dataIndex="event_start_date"
                    key="event_start_date"
                    render={(value) => dayjs(value).format('DD/MM/YYYY')}
                  />
                  <Column
                    title="Fin evento"
                    dataIndex="event_end_date"
                    key="event_end_date"
                    render={(value) => dayjs(value).format('DD/MM/YYYY')}
                  />
                  <Column
                    title="Agente"
                    dataIndex="user"
                    key="user"
                    render={(value, record: QuoteDTO) => record.user.name}
                  />
                  <Column
                    title="Acciones"
                    dataIndex="actions"
                    key="actions"
                    render={(value, quote: QuoteDTO) => (
                      <div className="clear-both">
                        {(updateQuotesPermission ||
                          (updateSelfQuotesPermission &&
                            quote.user.id === user?.id)) && (
                          <button
                            className="float-left mr-2"
                            onClick={() =>
                              navigate(`/cotizaciones/${quote.id}`)
                            }
                          >
                            <EditOutlined className="text-xl" />
                          </button>
                        )}
                        {(updateQuotesPermission ||
                          (updateSelfQuotesPermission &&
                            quote.user.id === user?.id)) &&
                          !quote.is_active && (
                            <Popconfirm
                              title={`${
                                quote.is_archived ? 'Desarchivar' : 'Archivar'
                              } cotización`}
                              onConfirm={() => handleArchiveQuote(quote)}
                              okText="Si"
                              cancelText="No"
                            >
                              <button className="float-left mr-2">
                                <SaveOutlined className="text-xl" />
                              </button>
                            </Popconfirm>
                          )}
                        <button className="float-left mr-2">
                          <DownloadOutlined
                            className="text-xl"
                            onClick={() => onPDFSave(quote)}
                          />
                        </button>
                        {approveQuotesPermission &&
                          !quote.is_active &&
                          !quote.is_archived && (
                            <Popconfirm
                              title="Aprobar cotización"
                              onConfirm={() => handleApproveQuote(quote.id)}
                              okText="Si"
                              cancelText="No"
                            >
                              <button className="float-left mr-2">
                                <CheckCircleOutlined className="text-xl" />
                              </button>
                            </Popconfirm>
                          )}
                      </div>
                    )}
                  />
                </Table>
              )}
            </div>
          </CardPanel>
        </CenteredContainer>
      </MainLayout>
    </>
  );
};

export default withAuthentication(QuotesListPage);
