import React from 'react';
import { ChildrenProp } from '../types/generic';

export const Label = ({ children }: ChildrenProp) => {
  return <label className="font-bold w-full block mb-2">{children}</label>;
};

export const FakeInput = ({ children }: ChildrenProp) => {
  return (
    <p className="bg-white block w-full min-h-10 p-3 text-orange-600 font-bold shadow-md">
      {children}
    </p>
  );
};
