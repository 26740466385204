import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Form, InputNumber, FormInstance, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import {
  QuoteCreateUpdateFormData,
  create,
  previousStage,
  update,
} from '@app-actions/quote-create';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import { H2Title, H1Title } from '@app-components/Layout/Text';
import { CreateQuoteContext } from '@app-contexts/quotes/CreateQuoteContext';
import QuoteTotals from './QuoteTotals';
import DatesHeading from './DatesHeading';
import { Label } from '@app-components/Layout/Form';
import { EMAIL_REGEX, PHONE_REGEX } from '@app-utils/constants';
import { isPhoneNumber } from '@app-utils/helpers';
import { useUserSession } from 'src/hooks/user-session';

const ThirdStage = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const {
    creationDataReducer: [creationData, creationDataDispatch],
    form: formInstance,
  } = useContext(CreateQuoteContext);
  const { id } = useParams();
  const { user } = useUserSession();

  const form = formInstance as FormInstance;

  const submitData = async () => {
    setSubmitDisabled(true);
    try {
      const formData: QuoteCreateUpdateFormData = {
        event_name: form.getFieldValue('event_name'),
        company: form.getFieldValue('company'),
        atention_to: form.getFieldValue('atention_to'),
        phone: form.getFieldValue('phone'),
        email: form.getFieldValue('email'),
        tax_invoice_data: form.getFieldValue('tax_invoice_data'),
        event_start_date: form.getFieldValue('event_start_date'),
        event_end_date: form.getFieldValue('event_end_date'),
        requester_name: form.getFieldValue('requester_name'),
        expected_attendees: form.getFieldValue('expected_attendees'),
        userId: user!.id,
      };

      if (!id) {
        await create(creationDataDispatch, creationData, formData);
      } else {
        await update(creationDataDispatch, id, creationData, formData);
      }

      navigate('/cotizaciones');
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
      });
      setSubmitDisabled(false);
    }
  };

  return (
    <>
      {contextHolder}
      <CenteredContainer width="normal">
        <div className="grid grid-cols-2">
          <H1Title>
            <button onClick={() => previousStage(creationDataDispatch)}>
              <LeftOutlined className="text-md relative -top-1 text-orange-600" />
            </button>
            Cotizador
          </H1Title>
        </div>

        <CenteredContainer width="narrow">
          <DatesHeading creationData={creationData} className="mb-2" />
          <CardPanel className="mb-4">
            <H2Title>Últimos datos</H2Title>
            <div className="grid grid-cols-2 mt-6">
              <div className="px-6 col-span-2">
                <Label>Evento</Label>

                <Form.Item
                  name="event_name"
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario el nombre del evento.',
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="grid grid-cols-2 mt-6">
              <div className="px-6">
                <Label>Empresa</Label>

                <Form.Item
                  name="company"
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario ingresar una empresa.',
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="px-6">
                <Label>Atención</Label>
                <Form.Item
                  name="atention_to"
                  className=""
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario ingresar atención a.',
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="grid grid-cols-5 mt-6">
              <div className="px-6 col-span-2">
                <Label>Teléfono</Label>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario ingresar un teléfono.',
                    },
                    {
                      pattern: new RegExp(PHONE_REGEX),
                      validator: async (rule, value) => {
                        if (value && !isPhoneNumber(value, 'MX')) {
                          throw new Error('');
                        }
                      },
                      message: 'El formato del teléfono es inválido.',
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="px-6 col-span-3">
                <Label>Correo</Label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario ingresar un correo.',
                    },
                    {
                      pattern: new RegExp(EMAIL_REGEX),
                      message: 'El formato del correo es inválido.',
                    },
                  ]}
                >
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            {/* <div className="grid grid-cols-5 mt-6">
              <div className="px-6 col-span-5">
                <Label>Datos de facturación</Label>
                <Form.Item
                  name="tax_invoice_data"
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario ingresar datos de facturación.',
                    },
                  ]}
                >
                  <Input.TextArea
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div> */}
            <div className="grid grid-cols-5 mt-6">
              <div className="px-6 col-span-2">
                <Label>Asistencia esperada</Label>
                <Form.Item
                  name="expected_attendees"
                  rules={[
                    {
                      required: true,
                      message: 'Es necesario ingresar la asistencia esperada.',
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    step={1}
                    className="w-full"
                    onKeyDown={(e) => {
                      if (e.key === 'enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </CardPanel>
          <QuoteTotals />
          <div className="w-7/12 mx-auto mt-8 grid grid-cols-2 gap-6">
            <Button
              type="ghost"
              className="block shadow-md rounded-3xl"
              onClick={() => {
                previousStage(creationDataDispatch);
              }}
            >
              Regresar
            </Button>
            <Button
              onClick={async () => {
                try {
                  await form.validateFields();
                  await submitData();
                } catch (e) {}
              }}
              type="primary"
              className="block"
              disabled={submitDisabled}
            >
              Guardar
            </Button>
          </div>
        </CenteredContainer>
      </CenteredContainer>
    </>
  );
};

export default ThirdStage;
