import React from 'react';
import { UserDTO } from '@app-entities/User';
import { HTTPFetchStateStatus } from '@app-entities/common';

export interface UsersReducerState {
  status?: HTTPFetchStateStatus;
  data?: UserDTO[];
}
export interface UsersReducerAction {
  type: string;
  payload: UsersReducerState;
}
export type UsersReducerDispatcher = React.Dispatch<UsersReducerAction>;
export type UsersReducer = (
  state: UsersReducerState,
  action: UsersReducerAction,
) => {};

export const usersReducer = (
  state: UsersReducerState,
  action: UsersReducerAction,
): UsersReducerState => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload.status };
    case 'SET_DATA':
      return { ...state, status: 'READY', data: action.payload.data };
    default:
      return state;
  }
};
