import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import { categoryFormStore } from '../../hooks/service-category-form-store';
import ServiceCategoryForm from './ServiceCategoryForm';
import {
  createServiceCategory,
  fetchServiceCategories,
  updateServiceCategory,
} from '@app-actions/service-categories';
import { CreateServiceCategoryDTO } from '@app-entities/CreateServiceCategory';
import { ServiceCategoriesReducerDispatcher } from '@app-reducers/service-categories';
import { useNavigate } from 'react-router-dom';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { categoryInfoStore } from '../../hooks/service-category-info-store';
import { undefinedIfEmpty } from '@app-utils/helpers';

const ServiceCategoryCreateUpdateModal = ({
  serviceCategoriesDispatch,
  setSelectedServiceCategory,
}: {
  serviceCategoriesDispatch: ServiceCategoriesReducerDispatcher;
  setSelectedServiceCategory: React.Dispatch<
    React.SetStateAction<ServiceCategoryDTO | null>
  >;
}) => {
  const [createFormInstance] = Form.useForm();
  const isOpen = categoryFormStore((state) => state.isFormOpen);
  const form = categoryFormStore((state) => state.form);
  const currentCategory = categoryFormStore((state) => state.currentCategory);
  const closeForm = categoryFormStore((state) => state.closeForm);
  const setFormInstance = categoryFormStore((state) => state.setFormInstance);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const navigate = useNavigate();
  const openCategoryInfoModal = categoryInfoStore((state) => state.openModal);

  useEffect(() => {
    setFormInstance(createFormInstance);
  }, []);

  const handleOk = async () => {
    setIsCreateLoading(true);
    try {
      let createdServiceCategory: ServiceCategoryDTO | null = null;
      await form?.validateFields();
      const serviceCategoryData: CreateServiceCategoryDTO = {
        name: form?.getFieldValue('name'),
        tax_percentage: form?.getFieldValue('tax_percentage') ?? null,
        invoice_concept_label: undefinedIfEmpty(
          form?.getFieldValue('invoice_concept_label'),
        ),
        invoice_description_label: undefinedIfEmpty(
          form?.getFieldValue('invoice_description_label'),
        ),
        invoice_pricing_label: undefinedIfEmpty(
          form?.getFieldValue('invoice_pricing_label'),
        ),
        invoice_quantity_label: undefinedIfEmpty(
          form?.getFieldValue('invoice_quantity_label'),
        ),
        is_active: form?.getFieldValue('is_active'),
      };

      if (!currentCategory) {
        createdServiceCategory = await createServiceCategory(
          serviceCategoryData,
        );
      } else {
        const updatedServiceCategory: ServiceCategoryDTO =
          await updateServiceCategory(currentCategory.id, serviceCategoryData);
        openCategoryInfoModal(updatedServiceCategory);
        setSelectedServiceCategory(updatedServiceCategory);
      }

      closeForm();
      await fetchServiceCategories(serviceCategoriesDispatch);

      if (createdServiceCategory) {
        navigate(`/servicios/${createdServiceCategory.id}`);
      }
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }

    setIsCreateLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      confirmLoading={isCreateLoading}
      onCancel={closeForm}
    >
      <ServiceCategoryForm currentCategory={currentCategory} />
    </Modal>
  );
};

export default ServiceCategoryCreateUpdateModal;
