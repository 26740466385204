import React, { useEffect, useReducer, useState } from 'react';
import { Modal, Form, message } from 'antd';
import AreaForm from './AreaForm';
import { areaFormStore } from '../../hooks/area-form-store';
import { createArea, fetchAreas, updateArea } from '@app-actions/areas';
import { CreateAreaDTO } from '@app-entities/CreateArea';
import { AreaTypeDTO } from '@app-entities/AreaType';
import { AreasReducerDispatcher } from '@app-reducers/areas';
import { AreaPricingType } from '@app-entities/AreaPricingType';
import { PRICING_TYPE } from '@app-utils/constants';

const AreaCreateUpdateModal = ({
  areaType,
  areasDispatch,
}: {
  areaType: AreaTypeDTO;
  areasDispatch: AreasReducerDispatcher;
}) => {
  const [createFormInstance] = Form.useForm();
  const isOpen = areaFormStore((state) => state.isFormOpen);
  const form = areaFormStore((state) => state.form);
  const currentArea = areaFormStore((state) => state.currentArea);
  const closeForm = areaFormStore((state) => state.closeForm);
  const setFormInstance = areaFormStore((state) => state.setFormInstance);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  useEffect(() => {
    setFormInstance(createFormInstance);
  }, []);

  const handleOk = async () => {
    setIsCreateLoading(true);
    try {
      await form?.validateFields();
      const pricingType: AreaPricingType = form?.getFieldValue('pricing_type');
      const areaData: CreateAreaDTO = {
        name: form?.getFieldValue('name'),
        height: form?.getFieldValue('height'),
        width: form?.getFieldValue('width'),
        length: form?.getFieldValue('length'),
        pricing_type: pricingType,
        fixed_price:
          pricingType === PRICING_TYPE.FIXED
            ? form?.getFieldValue('fixed_price')
            : null,
        expo_price:
          pricingType === PRICING_TYPE.DYNAMIC
            ? form?.getFieldValue('expo_price')
            : null,
        congress_price:
          pricingType === PRICING_TYPE.DYNAMIC
            ? form?.getFieldValue('congress_price')
            : null,
        next_fixed_price:
          pricingType === PRICING_TYPE.FIXED
            ? form?.getFieldValue('next_fixed_price')
            : null,
        next_expo_price:
          pricingType === PRICING_TYPE.DYNAMIC
            ? form?.getFieldValue('next_expo_price')
            : null,
        next_congress_price:
          pricingType === PRICING_TYPE.DYNAMIC
            ? form?.getFieldValue('next_congress_price')
            : null,
        only_full_area: form?.getFieldValue('only_full_area') ?? false,
        is_active: form?.getFieldValue('is_active'),
        area_type_id: areaType.id,
      };

      if (!currentArea) {
        await createArea(areaData);
      } else {
        await updateArea(currentArea.id, areaData);
      }

      closeForm();
      fetchAreas(areasDispatch, areaType.id);
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }

    setIsCreateLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      confirmLoading={isCreateLoading}
      onCancel={closeForm}
    >
      <AreaForm currentArea={currentArea} />
    </Modal>
  );
};

export default AreaCreateUpdateModal;
