const LogoBlackIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 249.85 131.94"
    >
      <g>
        <path
          fill="#121037"
          d="M233.86,85.36v19.48c-22.96,10.66-48.57,6.46-61.25-8.97c-9.63-11.71-9.17-31.07-9.17-31.07
		c0.04-6.52,0.91-18.48,9.17-28.86c12.75-16.02,37.46-21.42,61.25-11.51v19.03L226.71,41c-14.11-5.88-28.77-2.67-36.34,6.83
		c-4.9,6.16-5.42,13.26-5.44,17.12c0,0-0.27,11.49,5.44,18.44c7.52,9.15,22.72,11.64,36.34,5.32L233.86,85.36z"
        />
        <path
          fill="#121037"
          d="M158.18,85.75v19.48c-22.96,10.66-48.57,6.46-61.25-8.97c-9.63-11.71-9.17-31.07-9.17-31.07
		c0.04-6.52,0.91-18.48,9.17-28.86c12.75-16.02,37.46-21.42,61.25-11.51v19.03l-7.14-2.46c-14.11-5.88-28.77-2.67-36.34,6.83
		c-4.9,6.16-5.42,13.26-5.44,17.12c0,0-0.27,11.49,5.44,18.44c7.52,9.15,22.72,11.64,36.34,5.32L158.18,85.75z"
        />
        <path
          fill="#121037"
          d="M72.02,60.77c5.48-3.89,9.07-10.29,9.07-17.52c0-11.86-9.61-21.48-21.48-21.48H13.63v86.49l44.6-0.1
		c14.2,0,25.71-11.51,25.71-25.71C83.93,73.34,79.18,65.34,72.02,60.77z M34.05,38.02H51.5c5.2,0,9.41,4.21,9.41,9.41
		c0,5.2-4.21,9.41-9.41,9.41H34.05V38.02z M52.16,92.05H35.28v-20.3h16.88c5.61,0,10.15,4.54,10.15,10.15
		C62.31,87.5,57.77,92.05,52.16,92.05z"
        />
      </g>
    </svg>
  );
};

export default LogoBlackIcon;
