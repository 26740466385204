import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(dayOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

// Keep in mind that this only applies to Dayjs objects, when it the Dayjs is converted
// to JS Date object (dayjs.toDate()) it is converted to UTC, because Date objects always use UTC
dayjs.tz.setDefault('America/Merida');

export default dayjs;
