import { AreaTypeDTO } from '@app-entities/AreaType';
import { create } from 'zustand';

interface AreaTypeInfoState {
  isModalOpen: boolean;
  currentAreaType: AreaTypeDTO | null;
  openModal: (areaType: AreaTypeDTO) => void;
  closeModal: () => void;
}

export const areaTypeInfoStore = create<AreaTypeInfoState>((set) => ({
  isModalOpen: false,
  currentAreaType: null,
  openModal: (areaType) =>
    set((state) => {
      return { currentAreaType: areaType, isModalOpen: true };
    }),
  closeModal: () => set((state) => ({ isModalOpen: false })),
}));
