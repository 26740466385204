import React from 'react';
import { H2Title } from '@app-components/Layout/Text';
import { Form, Input, InputNumber, Switch } from 'antd';
import { categoryFormStore } from '../../hooks/service-category-form-store';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';

const ServiceCategoryForm = ({
  currentCategory,
}: {
  currentCategory: ServiceCategoryDTO | null;
}) => {
  const form = categoryFormStore((state) => state.form) ?? undefined;

  return (
    <>
      <H2Title>{currentCategory ? 'Editar' : 'Nueva'} categoría</H2Title>
      <Form
        form={form}
        initialValues={{
          is_active: true,
        }}
      >
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Nombre</span>}
            name="name"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un nombre.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Porcentaje de IVA</span>}
            name="tax_percentage"
            className="px-6"
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Activo</span>}
            name="is_active"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="w-full mt-2 pt-2 border-t-2 w-full">
          <p className="text-lg font-bold">
            Personalización de cabeceras en PDF
          </p>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Concepto</span>}
            name="invoice_concept_label"
            className="px-6"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Descripción</span>}
            name="invoice_description_label"
            className="px-6"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Precio</span>}
            name="invoice_pricing_label"
            className="px-6"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Cantidad</span>}
            name="invoice_quantity_label"
            className="px-6"
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ServiceCategoryForm;
