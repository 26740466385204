import React, { useContext, useEffect, useState } from 'react';
import {
  DatePicker,
  Form,
  Select,
  Button,
  InputNumber,
  FormInstance,
  Popconfirm,
  Switch,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import CardPanel from '@app-components/Layout/CardPanel';
import { H1Title, H2Title } from '@app-components/Layout/Text';
import { FakeInput, Label } from '@app-components/Layout/Form';
import dayjs from '@app-utils/extendedDaysJs';
import { AreaDTO } from '@app-entities/Area';
import { UUID } from '@app-entities/common';
import { CreateQuoteContext } from '@app-contexts/quotes/CreateQuoteContext';

import {
  QuoteCreateSelectedAreaConfig,
  QuoteCreateSelectedAreasConfigMap,
} from '@app-reducers/quote-create';
import {
  cancel,
  nextStage,
  setEndDate,
  setSelectedAreas,
  removeSelectedArea as removeSelectedAreaAction,
  setStartDate,
  getEventDays,
  getInstallationCalculatedDays,
  getUnistallationCalculatedDays,
} from '@app-actions/quote-create';
import { numberWithCommas } from '@app-utils/helpers';
import DayAvailabilitySquare from './DayAvailabilitySquare';
import { FETCH_FINISHED_STATUS } from '@app-utils/constants';
import ContentLoader from '@app-components/Layout/ContentLoader';
import { AreasReducerState } from '@app-reducers/areas';
import { AreaTypesReducerState } from '@app-reducers/area-types';

type AreasByCategoryMap = {
  [key: UUID]: AreaDTO[];
};

const FirstSage = ({
  areas,
  areaTypes,
}: {
  areas: AreasReducerState;
  areaTypes: AreaTypesReducerState;
}) => {
  const navigate = useNavigate();
  const {
    // areaTypesReducer: [areaTypes, areaTypesDispatch],
    // areasReducer: [areas, areasDispatch],
    form: formInstance,
    creationDataReducer: [creationData, creationDataDispatch],
  } = useContext(CreateQuoteContext);
  const [fieldsGeneralError, setFieldsGeneralError] = useState<string | null>(
    null,
  );

  const form = formInstance as FormInstance;
  const noFieldsError = 'Es necesario seleccionar al menos un espacio';

  // useEffect(() => {
  //   (async () => {
  //     fetchAreaTypes(areaTypesDispatch);
  //     fetchAreas(areasDispatch);
  //   })();
  // }, []);

  const areasByCategoryMap: AreasByCategoryMap = {};
  const createAreasByCategoryMap = () => {
    if (!areas || !areas.data) return;
    areas.data.forEach((area) => {
      if (areasByCategoryMap.hasOwnProperty(area.area_type_id)) {
        areasByCategoryMap[area.area_type_id].push(area);
      } else {
        areasByCategoryMap[area.area_type_id] = [area];
      }
    });
  };

  createAreasByCategoryMap();

  const getDaysList = (quantity: number) => {
    return quantity > 0 ? Array.from(Array(quantity)) : [];
  };

  const addSelectedArea = (areaId: string) => {
    setFieldsGeneralError(null);

    const existsInArray = Object.values(creationData.selectedAreas ?? {}).find(
      (areaConfig) => areaConfig.data.id === areaId,
    );
    if (existsInArray) return;

    const areaData: AreaDTO | undefined = areas.data?.find(
      (area) => area.id === areaId,
    );
    if (!areaData) return;

    const newSelectedAreas: QuoteCreateSelectedAreasConfigMap = {
      ...creationData.selectedAreas,
    };

    setSelectedAreas(
      creationDataDispatch,
      {
        ...newSelectedAreas,
        [areaData.id]: {
          data: areaData,
          used_space: areaData.only_full_area ? areaData.total_area : undefined,
        },
      },
      {
        start_date: creationData.start_date,
        end_date: creationData.end_date,
      },
    );
  };

  const removeSelectedArea = (areaId: string) => {
    if (!creationData.selectedAreas?.hasOwnProperty(areaId)) return;

    if (!(Object.keys(creationData.selectedAreas).length - 1)) {
      setFieldsGeneralError(noFieldsError);
    }

    removeSelectedAreaAction(creationDataDispatch, areaId);
  };

  const onInstallationDaysChange = (value: number | null, areaId: string) => {
    setSelectedAreas(
      creationDataDispatch,
      {
        ...creationData.selectedAreas,
        [areaId]: {
          ...creationData.selectedAreas![areaId],
          installation_days: value,
        },
      },
      {
        start_date: creationData.start_date,
        end_date: creationData.end_date,
      },
    );
  };

  const onUninstallationDaysChange = (value: number | null, areaId: string) => {
    setSelectedAreas(
      creationDataDispatch,
      {
        ...creationData.selectedAreas,
        [areaId]: {
          ...creationData.selectedAreas![areaId],
          uninstallation_days: value,
        },
      },
      {
        start_date: creationData.start_date,
        end_date: creationData.end_date,
      },
    );
  };

  const onInstallationSameDayChange = (value: boolean, areaId: string) => {
    setSelectedAreas(
      creationDataDispatch,
      {
        ...creationData.selectedAreas,
        [areaId]: {
          ...creationData.selectedAreas![areaId],
          installation_same_day: value,
          ...(value ? { installation_days: null } : {}),
          ...(value ? { uninstallation_days: null } : {}),
        },
      },
      {
        start_date: creationData.start_date,
        end_date: creationData.end_date,
      },
    );
  };

  const validateNextState = async () => {
    if (!Object.values(creationData.selectedAreas ?? {}).length) {
      setFieldsGeneralError(noFieldsError);
      return;
    } else {
      setFieldsGeneralError(null);
    }

    try {
      await form.validateFields();
      nextStage(creationDataDispatch);
    } catch (e) {}
  };

  const areasFetchFinished =
    FETCH_FINISHED_STATUS.includes(areas.status ?? 'LOADING') &&
    FETCH_FINISHED_STATUS.includes(areaTypes.status ?? 'LOADING');

  return (
    <CenteredContainer width="normal">
      <H1Title>Cotizador</H1Title>
      <CardPanel>
        <H2Title>Fecha de evento</H2Title>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Inicio</span>}
            name="start_date"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario selecionar una fecha de inicio.',
              },
              {
                validator: async (_, startDate: dayjs.Dayjs) => {
                  const endDate = form.getFieldValue('end_date') as dayjs.Dayjs;

                  if (!startDate || !endDate) return;

                  const finalStartDate = startDate.hour(0).minute(0).second(0);
                  const finalEndDate = endDate.hour(0).minute(0).second(0);

                  if (finalEndDate.diff(finalStartDate, 'hours') > 0) {
                    throw new Error(
                      'Debe ser el mismo día o al menos un día anterior a la fecha de fin',
                    );
                  }
                },
              },
            ]}
          >
            <DatePicker
              onChange={(date) => {
                setStartDate(creationDataDispatch, date ?? null);
              }}
            />
          </Form.Item>
          <Form.Item
            label={<span className="font-bold">Fin</span>}
            name="end_date"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario selecionar una fecha de inicio.',
              },
              {
                validator: async (_, endDate: dayjs.Dayjs) => {
                  const startDate: dayjs.Dayjs =
                    form.getFieldValue('start_date');

                  if (!endDate || !startDate) return;

                  const finalStartDate = startDate.hour(0).minute(0).second(0);
                  const finalEndDate = endDate.hour(0).minute(0).second(0);

                  if (finalEndDate.diff(finalStartDate, 'hours') < 0) {
                    throw new Error(
                      'Debe ser el mismo día o al menos un día después de la fecha de inicio',
                    );
                  }
                },
              },
            ]}
          >
            <DatePicker
              onChange={(date) => {
                setEndDate(creationDataDispatch, date ?? null);
              }}
            />
          </Form.Item>
        </div>
      </CardPanel>
      <CardPanel className="mt-4">
        <H2Title>Espacio</H2Title>
        {!areasFetchFinished ? (
          <ContentLoader />
        ) : (
          <>
            <div className="grid grid-cols-4 mt-6">
              {areaTypes &&
                areaTypes.data &&
                areaTypes.data.map((areaType) => (
                  <Form.Item
                    className="px-6"
                    key={`area-type-${areaType.id}`}
                    name={`area-type-${areaType.id}`}
                  >
                    <Label>{areaType.name}</Label>
                    <Select
                      mode="multiple"
                      options={(areasByCategoryMap[areaType.id] ?? []).map(
                        (area) => ({
                          label: area.name,
                          value: area.id,
                        }),
                      )}
                      defaultValue={Object.values(
                        creationData.selectedAreas ?? {},
                      )
                        .filter(
                          (selectedArea) =>
                            selectedArea.data.area_type_id === areaType.id,
                        )
                        .map((selectedArea) => selectedArea.data.id)}
                      onSelect={(value) => addSelectedArea(value)}
                      onDeselect={(value) => removeSelectedArea(value)}
                    />
                  </Form.Item>
                ))}
            </div>
            <p className="mt-6 text-red-600 block text-center">
              {fieldsGeneralError}
            </p>
            <div className="grid grid-cols-8 mt-6">
              <div className="col-span-1">
                <p className="font-bold">Seleccionados:</p>
              </div>
              <div className="col-span-7">
                {Object.values(creationData.selectedAreas ?? {}).map(
                  (selectedArea, index) => (
                    <div
                      className={`grid grid-cols-4 ${
                        index === 0 ? '' : 'mt-6'
                      }`}
                      key={`area-fields-${index}`}
                    >
                      <Form.Item className="px-6">
                        <Label>Nombre</Label>
                        <FakeInput>{selectedArea.data.name}</FakeInput>
                      </Form.Item>
                      <Form.Item className="px-6">
                        <Label>Días montaje</Label>
                        <InputNumber
                          value={selectedArea.installation_days}
                          min={0}
                          disabled={selectedArea.installation_same_day}
                          onChange={(value) => {
                            onInstallationDaysChange(
                              value,
                              selectedArea.data.id,
                            );
                          }}
                        />
                      </Form.Item>
                      <Form.Item className="px-6">
                        <Label>Días desmontaje</Label>
                        <InputNumber
                          value={selectedArea.uninstallation_days}
                          min={0}
                          disabled={selectedArea.installation_same_day}
                          onChange={(value) => {
                            onUninstallationDaysChange(
                              value,
                              selectedArea.data.id,
                            );
                          }}
                        />
                      </Form.Item>
                      <Form.Item className="px-6">
                        <Label>Montaje/desmontaje mismo día</Label>
                        <Switch
                          checked={selectedArea.installation_same_day}
                          onChange={(value) => {
                            onInstallationSameDayChange(
                              value,
                              selectedArea.data.id,
                            );
                          }}
                        />
                      </Form.Item>
                      {/* <Form.Item className="px-6">
                        <Button
                          type="text"
                          onClick={(e) => {
                            removeSelectedArea(selectedArea.data.id);
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Form.Item> */}
                    </div>
                  ),
                )}
              </div>
            </div>
          </>
        )}
      </CardPanel>
      <CardPanel className="mt-4">
        <H2Title>Disponibilidad</H2Title>
        <div className="grid grid-cols-8 mt-6">
          <div className="col-span-1"></div>
          <div className="col-span-7">
            {Object.values(creationData.selectedAreas ?? {}).map(
              (selectedArea, index) => (
                <div className="mt-6 w-full" key={index}>
                  <p className="font-bold text-orange-600 mb-2 block">
                    {selectedArea.data.name}
                  </p>
                  <div className="clear-both table w-full">
                    <ul className="clear-both float-left table">
                      {getDaysList(
                        getInstallationCalculatedDays(
                          creationData,
                          selectedArea,
                        ),
                      ).map((value, index) => (
                        <DayAvailabilitySquare
                          key={`installation-${index}`}
                          position={index}
                          selectedArea={selectedArea}
                          type="installation"
                        />
                      ))}
                      {getDaysList(getEventDays(creationData)).map(
                        (value, index) => (
                          <DayAvailabilitySquare
                            key={`event-${index}`}
                            position={index}
                            selectedArea={selectedArea}
                            type="event"
                          />
                        ),
                      )}
                      {getDaysList(
                        getUnistallationCalculatedDays(
                          creationData,
                          selectedArea,
                        ),
                      ).map((value, index) => (
                        <DayAvailabilitySquare
                          key={`uninstallation-${index}`}
                          position={index}
                          selectedArea={selectedArea}
                          type="uninstallation"
                        />
                      ))}
                    </ul>
                    {selectedArea.focused_date &&
                      selectedArea.availability_map &&
                      selectedArea.availability_map.hasOwnProperty(
                        selectedArea.focused_date,
                      ) &&
                      (() => {
                        const selectedAvailability =
                          selectedArea.availability_map[
                            selectedArea.focused_date
                          ];
                        return (
                          <div className="float-left pl-4">
                            <div className="grid grid-cols-2 gap-5">
                              <div className="col-span-">
                                <p className="font-bold">
                                  Disponibilidad{' '}
                                  {selectedAvailability.day.format('D MMMM')}:
                                </p>
                                <p className="text-gray-500">
                                  M2:{' '}
                                  {numberWithCommas(
                                    selectedAvailability.available,
                                    false,
                                  )}
                                  <span className="font-bold">
                                    {' '}
                                    {`(${Math.round(
                                      selectedAvailability.available /
                                        (selectedAvailability.total / 100),
                                    )}%)`}
                                  </span>
                                </p>
                                {/* <p className="text-gray-500">Personas: 5,289</p> */}
                              </div>
                              <div className="col-span-1">
                                {!!selectedAvailability.events.length && (
                                  <>
                                    <p className="font-bold block">Eventos:</p>
                                    <ul className="list-disc list-inside text-gray-500">
                                      {selectedAvailability.events.map(
                                        (event, index) => (
                                          <li key={index} className="list-item">
                                            {event.event_name} (M2:{' '}
                                            {numberWithCommas(
                                              event.used,
                                              false,
                                            )}
                                            )
                                          </li>
                                        ),
                                      )}
                                    </ul>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </CardPanel>
      <div className="w-5/12 mx-auto mt-8 grid grid-cols-2 gap-6">
        <Popconfirm
          title="¿Confirma que desea salir del cotizador?"
          description="Se perderán todos los avances."
          onConfirm={() => {
            cancel(creationDataDispatch);
            form.resetFields();
            navigate('/cotizaciones');
          }}
          okText="Si"
          cancelText="No"
        >
          <Button type="ghost" className="block shadow-md rounded-3xl">
            Cancelar
          </Button>
        </Popconfirm>
        <Button
          onClick={async () => {
            try {
              validateNextState();
            } catch (e) {}
          }}
          type="primary"
          className="block"
          disabled={!areasFetchFinished}
        >
          Siguiente
        </Button>
      </div>
    </CenteredContainer>
  );
};

export default FirstSage;
