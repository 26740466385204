import { QuoteCreateReducerState } from '@app-reducers/quote-create';
import dayjs from 'dayjs';

const DatesHeading = ({
  creationData,
  className,
}: {
  creationData: QuoteCreateReducerState;
  className?: string;
}) => {
  return (
    <>
      {creationData.start_date && creationData.end_date && (
        <p className={`text-h2-title leading-10 ${className}`}>
          {dayjs(creationData.start_date).locale('es-mx').format('D MMMM YYYY')}{' '}
          al{' '}
          {dayjs(creationData.end_date).locale('es-mx').format('D MMMM YYYY')}
        </p>
      )}
    </>
  );
};

export default DatesHeading;
