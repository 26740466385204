import axios from 'axios';
import { Modal } from 'antd';

const customAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1/` ?? '',
});

function modalError(config: any) {
  const defaults = {
    title: '',
    content: '',
    onOk() {},
  };

  Modal.error({ ...defaults, ...config });
}

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const statusCode = error.response ? error.response.status : null;
    const message = error.response ? error.response.data.message : null;

    if (statusCode === 401 && message !== 'Invalid credentials.') {
      modalError({
        title: 'La sesión ha expirado',
        message: 'Es necesario autenticarse nuevamente',
        onOk: () => {
          window.location.reload();
        },
      });

      console.log('Please login to access this resource');
    }

    return Promise.reject(error);
  },
);

// Refresh CSRF Token
// setInterval(refreshToken, 1000 * 60 * 5); // 1 hour

// function refreshToken() {
//   axios
//     .get(parentProjectURL + '/refresh-csrf')
//     .then(function (response) {
//       document.querySelector('meta[name="csrf-token"]').content = response.data;
//     })
//     .catch(function (error) {});
// }

export default customAxios;
