import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { QuoteDTO } from '@app-entities/Quote';
import { styles } from './';
import { numberWithCommas } from '@app-utils/helpers';
import { GENERAL_IVA } from '@app-utils/constants';

const QuoteAreas = (quote: QuoteDTO) => {
  const installationDiscount = quote.areas.reduce(
    (prev, quoteArea) => prev + quoteArea.installation_discount,
    0,
  );
  const uninstallationDiscount = quote.areas.reduce(
    (prev, quoteArea) => prev + quoteArea.uninstallation_discount,
    0,
  );

  return (
    <View style={{ ...styles.row, marginTop: '7pt' }}>
      <table>
        <thead>
          <tr>
            <th style={styles.tableHeaderCell}>
              <Text>Renta de Salones / Espacios</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableFirstCell,
                ...styles.tableBodyCellConceptColumn,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Salon Area</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Tamaño M2</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Tarifa diaria</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                width: '30pt',
              }}
            >
              <Text>Dias Montaje</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                width: '35pt',
              }}
            >
              <Text>Precio Dia Montaje</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                width: '30pt',
              }}
            >
              <Text>Dias Evento</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                width: '35pt',
              }}
            >
              <Text>Precio Dia Evento</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                width: '30pt',
              }}
            >
              <Text>Dias Desmontaje</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                width: '35pt',
              }}
            >
              <Text>Precio Dia Desmontaje</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Precio Horas Extra</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Horas Extra</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Total Horas Extra</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellTotalColumn,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>Total</Text>
            </td>
          </tr>
          {quote.areas.map((area, index) => (
            <tr
              style={{
                ...styles.infoTableBodyRow,
              }}
              key={index}
            >
              <td
                style={{
                  ...styles.tableBodyCell,
                  ...styles.tableFirstCell,
                  ...styles.tableBodyCellConceptColumn,
                }}
              >
                <Text>{area.name}</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                }}
              >
                <Text>{area.used_space}</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                }}
              >
                <Text>{area.event_price}</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  width: '30pt',
                }}
              >
                <Text>
                  {area.installation_same_day ? 1 : area.installation_days}
                </Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  width: '35pt',
                }}
              >
                <Text>
                  {numberWithCommas(area.installation_price * area.used_space)}
                </Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  width: '30pt',
                }}
              >
                <Text>{quote.event_days}</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  width: '35pt',
                }}
              >
                <Text>
                  {numberWithCommas(area.event_price * area.used_space)}
                </Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  width: '30pt',
                }}
              >
                <Text>
                  {area.installation_same_day ? 1 : area.uninstallation_days}
                </Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  width: '35pt',
                }}
              >
                <Text>
                  ${numberWithCommas(area.installation_price * area.used_space)}
                </Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                }}
              >
                <Text>0</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                }}
              >
                <Text>$0.00</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                }}
              >
                <Text>$0.00</Text>
              </td>
              <td
                style={{
                  ...styles.tableBodyCell,
                  ...styles.tableBodyCellTotalColumn,
                }}
              >
                <Text>${numberWithCommas(area.subtotal)}</Text>
              </td>
            </tr>
          ))}
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
                borderTop: '1px',
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
              }}
            >
              <Text>Subtotal renta de salones</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
              }}
            >
              <Text>
                ${numberWithCommas(quote.areas_subtotal + quote.areas_discount)}
              </Text>
            </td>
          </tr>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
              }}
            >
              <Text>
                Descuento del {quote.event_discount_percentage ?? 0}% en evento
              </Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
              }}
            >
              <Text>
                $
                {numberWithCommas(
                  quote.areas_discount -
                    installationDiscount -
                    uninstallationDiscount,
                )}
              </Text>
            </td>
          </tr>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
              }}
            >
              <Text>
                Descuento del {quote.installation_discount_percentage ?? 0}% en
                montaje
              </Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
              }}
            >
              <Text>${numberWithCommas(installationDiscount)}</Text>
            </td>
          </tr>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
              }}
            >
              <Text>
                Descuento del {quote.uninstallation_discount_percentage ?? 0}%
                en desmontaje
              </Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
              }}
            >
              <Text>${numberWithCommas(uninstallationDiscount)}</Text>
            </td>
          </tr>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
              }}
            >
              <Text>Subtotal con descuento</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
              }}
            >
              <Text>${numberWithCommas(quote.areas_subtotal)}</Text>
            </td>
          </tr>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
              }}
            >
              <Text>IVA {GENERAL_IVA * 100}%</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
              }}
            >
              <Text>${numberWithCommas(quote.areas_tax)}</Text>
            </td>
          </tr>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
                ...styles.tableLastRowCells,
              }}
            >
              <Text>Total renta de salas</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
                ...styles.tableLastRowCells,
              }}
            >
              <Text>
                ${numberWithCommas(quote.areas_subtotal + quote.areas_tax)}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    </View>
  );
};

export default QuoteAreas;
