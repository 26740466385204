import { AxiosResponse } from 'axios';
import { UserDTO } from '@app-entities/User';
import { UsersReducerDispatcher } from '@app-reducers/users';
import api from '../utils/api';
import { UUID } from '@app-entities/common';
import { CreateUserDTO } from '@app-entities/CreateUser';
import { LoginData } from '@app-entities/LoginData';

export const fetchUsers = async (
  dispatch: UsersReducerDispatcher,
): Promise<void> => {
  const usersResponse: AxiosResponse<UserDTO[]> = await api.get('/users');

  dispatch({
    type: 'SET_DATA',
    payload: {
      data: usersResponse.data,
    },
  });
};

export const createUser = async (data: CreateUserDTO): Promise<void> => {
  try {
    await api.post<any, AxiosResponse<any>, CreateUserDTO>(`/users`, data);
  } catch (error) {
    throw new Error('Ocurrió un error al crear');
  }
};

export const updateUser = async (
  id: UUID,
  data: CreateUserDTO,
): Promise<void> => {
  try {
    await api.put<any, AxiosResponse<any>, CreateUserDTO>(`/users/${id}`, data);
  } catch (error) {
    throw new Error('Ocurrió un error al guardar');
  }
};

export const deleteUser = async (id: UUID): Promise<void> => {
  try {
    await api.delete<any, AxiosResponse<any>>(`/users/${id}`);
  } catch (error) {
    throw new Error('Ocurrió un error al eliminar');
  }
};

export const login = async (loginData: LoginData): Promise<UserDTO> => {
  try {
    const response = await api.post<any, AxiosResponse<UserDTO>>(
      `/users/login`,
      loginData,
    );
    return response.data;
  } catch (error) {
    throw new Error('Login incorrecto');
  }
};
