import { AxiosResponse } from 'axios';
import { AreaDTO } from '@app-entities/Area';
import { AreasReducerDispatcher } from '@app-reducers/areas';
import api from '../utils/api';
import { UUID } from '@app-entities/common';
import { CreateAreaDTO } from '@app-entities/CreateArea';

export const fetchAreas = async (
  dispatch: AreasReducerDispatcher,
  areaTypeId?: string,
): Promise<void> => {
  const areasResponse: AxiosResponse<AreaDTO[]> = await api.get(
    '/areas',
    areaTypeId
      ? {
          params: {
            area_type_id: areaTypeId,
          },
        }
      : undefined,
  );

  dispatch({
    type: 'SET_DATA',
    payload: {
      data: areasResponse.data,
    },
  });
};

export const createArea = async (data: CreateAreaDTO): Promise<void> => {
  try {
    await api.post<any, AxiosResponse<any>, CreateAreaDTO>(`/areas`, data);
  } catch (error) {
    throw new Error('Ocurrió un error al crear');
  }
};

export const updateArea = async (
  id: UUID,
  data: CreateAreaDTO,
): Promise<void> => {
  try {
    await api.put<any, AxiosResponse<any>, CreateAreaDTO>(`/areas/${id}`, data);
  } catch (error) {
    throw new Error('Ocurrió un error al guardar');
  }
};

export const deleteArea = async (id: UUID): Promise<void> => {
  try {
    await api.delete<any, AxiosResponse<any>>(`/areas/${id}`);
  } catch (error) {
    throw new Error('Ocurrió un error al eliminar');
  }
};
