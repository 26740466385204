import { AxiosResponse } from 'axios';
import { ServiceDTO } from '@app-entities/Service';
import { ServicesReducerDispatcher } from '@app-reducers/services';
import api from '../utils/api';
import { CreateServiceDTO } from '@app-entities/CreateService';
import { UUID } from '@app-entities/common';
import { ServiceAvailabilityDTO } from '@app-entities/ServiceAvailability';
import dayjs from './../utils/extendedDaysJs';

export const fetchServices = async (
  dispatch: ServicesReducerDispatcher,
  serviceCategoryId?: string,
): Promise<void> => {
  const servicesResponse: AxiosResponse<ServiceDTO[]> = await api.get(
    '/services',
    serviceCategoryId
      ? {
          params: {
            service_category_id: serviceCategoryId,
          },
        }
      : undefined,
  );

  dispatch({
    type: 'SET_DATA',
    payload: {
      data: servicesResponse.data,
    },
  });
};

export const createService = async (data: CreateServiceDTO): Promise<void> => {
  try {
    await api.post<any, AxiosResponse<any>, CreateServiceDTO>(
      `/services`,
      data,
    );
  } catch (error) {
    throw new Error('Ocurrió un error al crear');
  }
};

export const updateService = async (
  id: UUID,
  data: CreateServiceDTO,
): Promise<void> => {
  try {
    await api.put<any, AxiosResponse<any>, CreateServiceDTO>(
      `/services/${id}`,
      data,
    );
  } catch (error) {
    throw new Error('Ocurrió un error al guardar');
  }
};

export const deleteService = async (id: UUID): Promise<void> => {
  try {
    await api.delete<any, AxiosResponse<any>>(`/services/${id}`);
  } catch (error) {
    throw new Error('Ocurrió un error al eliminar');
  }
};

export const getServiceAvailability = async (
  id: UUID,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
): Promise<ServiceAvailabilityDTO[]> => {
  try {
    const response = await api.get<
      any,
      AxiosResponse<ServiceAvailabilityDTO[]>
    >(`/services/${id}/availability`, {
      params: {
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Ocurrió un error');
  }
};
