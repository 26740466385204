import React from 'react';
import { Modal, Popconfirm, Button, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { categoryInfoStore } from '../../hooks/service-category-info-store';
import { H2Title } from '@app-components/Layout/Text';
import {
  deleteServiceCategory,
  fetchServiceCategories,
} from '@app-actions/service-categories';
import { useNavigate } from 'react-router-dom';
import { ServiceCategoriesReducerDispatcher } from '@app-reducers/service-categories';
import { categoryFormStore } from '../../hooks/service-category-form-store';
import { useUserSession } from 'src/hooks/user-session';
import { hasPermission } from '@app-utils/helpers';
import { PERMISSIONS } from '@app-utils/constants';

const CategoryDetailsModal = ({
  serviceCategoriesDispatch,
}: {
  serviceCategoriesDispatch: ServiceCategoriesReducerDispatcher;
}) => {
  const { user } = useUserSession();
  const isOpen = categoryInfoStore((state) => state.isModalOpen);
  const currentCategory = categoryInfoStore((state) => state.currentCategory);
  const closeModal = categoryInfoStore((state) => state.closeModal);
  const navigate = useNavigate();
  const openCategoryForm = categoryFormStore((state) => state.openForm);

  const handleDelete = async () => {
    if (currentCategory) {
      try {
        await deleteServiceCategory(currentCategory.id);
        closeModal();
        await fetchServiceCategories(serviceCategoriesDispatch);
        navigate('/servicios');
      } catch (e) {
        if (e.message) {
          message.error(e.message);
        }
      }
    }
  };

  return (
    <Modal open={isOpen} footer={null} onCancel={closeModal}>
      {currentCategory && (
        <>
          <H2Title>{currentCategory.name}</H2Title>
          <div className="w-full clear-both table mt-3 mb-4">
            {hasPermission(user?.permissions, [
              PERMISSIONS.serviceCategory.options.update.value,
            ]) && (
              <Button
                type="ghost"
                className="float-left mr-2 shadow-md rounded-3xl"
                onClick={() => openCategoryForm(currentCategory)}
                icon={<EditOutlined />}
              >
                Editar
              </Button>
            )}
            {hasPermission(user?.permissions, [
              PERMISSIONS.serviceCategory.options.delete.value,
            ]) && (
              <Popconfirm
                title="Eliminar categoría"
                onConfirm={() => handleDelete()}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type="ghost"
                  className="float-left mr-2 shadow-md rounded-3xl"
                  icon={<DeleteOutlined />}
                >
                  Eliminar
                </Button>
              </Popconfirm>
            )}
          </div>
          <div className="grid grid-cols-2 mt-2 w-full">
            <div>
              <p className="font-bold">Porcentaje de IVA:</p>
              <p>
                {currentCategory.tax_percentage
                  ? `${currentCategory.tax_percentage}%`
                  : 'Por defecto'}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2 w-full">
            <div>
              <p className="font-bold">Activo:</p>
              <p>{currentCategory.is_active ? 'Si' : 'No'}</p>
            </div>
          </div>
          <div className="w-full mt-2 pt-2 border-t-2 w-full">
            <p className="text-lg font-bold">
              Personalización de cabeceras en PDF
            </p>
          </div>
          <div className="grid grid-cols-2 mt-2 py-3 w-full">
            <div>
              <p className="font-bold">Concepto:</p>
              <p>{currentCategory.invoice_concept_label ?? 'Por defecto'}</p>
            </div>
            <div>
              <p className="font-bold">Descripción:</p>
              <p>
                {currentCategory.invoice_description_label ?? 'Por defecto'}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-2 py-3 w-full">
            <div>
              <p className="font-bold">Precio:</p>
              <p>{currentCategory.invoice_pricing_label ?? 'Por defecto'}</p>
            </div>
            <div>
              <p className="font-bold">Cantidad:</p>
              <p>{currentCategory.invoice_quantity_label ?? 'Por defecto'}</p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CategoryDetailsModal;
