import { configureSelectedArea } from '@app-actions/quote-create';
import { CreateQuoteContext } from '@app-contexts/quotes/CreateQuoteContext';
import { QuoteCreateSelectedAreaConfig } from '@app-reducers/quote-create';
import { getInstallationStartDate } from '@app-utils/helpers';
import dayjs from 'dayjs';
import { useContext } from 'react';

const DayAvailabilitySquare = ({
  position,
  selectedArea,
  type,
}: {
  position: number;
  selectedArea: QuoteCreateSelectedAreaConfig;
  type: 'installation' | 'uninstallation' | 'event';
}) => {
  const {
    creationDataReducer: [creationData, creationDataDispatch],
  } = useContext(CreateQuoteContext);

  const getApplicableDate = (): dayjs.Dayjs => {
    if (type === 'event') {
      return creationData.start_date!.add(position, 'days');
    } else if (type === 'installation') {
      const installationStartDate = getInstallationStartDate(
        creationData.start_date!,
        selectedArea.installation_days!,
      );

      return installationStartDate.add(position, 'days');
    } else {
      return creationData.end_date!.add(position + 1, 'days');
    }
  };

  const applicableDate: dayjs.Dayjs = getApplicableDate();
  const isSelected =
    applicableDate.format('YYYY-MM-DD') === selectedArea.focused_date;

  const handleDayClick = () => {
    configureSelectedArea(creationDataDispatch, {
      ...selectedArea,
      focused_date: applicableDate.format('YYYY-MM-DD'),
    });
  };

  return (
    <li
      className={`float-left mr-1 ${
        isSelected ? 'font-bold' : 'hover:text-orange-600 hover:font-bold'
      } cursor-pointer`}
      onClick={handleDayClick}
    >
      <div
        className={`w-14 block h-5 bg-green-400 border ${
          type === 'event' ? 'border-orange-600 border-solid' : ''
        }`}
      ></div>
      <p className="text-xs text-center mt-1">
        {applicableDate.format('ll').replace(/,(.*)$/, '')}
      </p>
    </li>
  );
};

export default DayAvailabilitySquare;
