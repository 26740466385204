import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import { styles } from './';

const QuoteLogos = () => {
  return (
    <View style={styles.row}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Image
            src="/bcc-logo.png"
            style={{
              width: '75pt',
              height: '18pt',
              alignSelf: 'flex-start',
              marginTop: '5pt',
            }}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Image
            src="/baja-california-logo.png"
            style={{
              width: '60pt',
              height: '28pt',
              alignSelf: 'flex-end',
              marginBottom: '5pt',
            }}
          />
        </div>
      </div>
    </View>
  );
};

export default QuoteLogos;
