import React from 'react';
import { ChildrenProp } from '../types/generic';

type CenteredContainerWidth = 'full' | 'normal' | 'narrow';
type CenteredContainerProps = ChildrenProp & {
  width: CenteredContainerWidth;
};
const centeredContainerWidthToClass = {
  full: 'w-full',
  normal: 'w-11/12 max-w-[1350px]',
  narrow: 'w-8/12',
};

const CenteredContainer = ({ children, width }: CenteredContainerProps) => {
  return (
    <div className={`${centeredContainerWidthToClass[width]} mx-auto`}>
      {children}
    </div>
  );
};

export default CenteredContainer;
