import React from 'react';
import { H2Title } from '@app-components/Layout/Text';
import { Form, Input, Select, Switch } from 'antd';
import { userFormStore } from '../../hooks/user-form-store';
import { UserDTO } from '@app-entities/User';
import { PERMISSIONS } from '@app-utils/constants';

const UserForm = ({
  currentUser,
}: {
  currentUser: Omit<UserDTO, 'password'> | null;
}) => {
  const form = userFormStore((state) => state.form) ?? undefined;

  return (
    <>
      <H2Title>{currentUser ? 'Editar' : 'Nuevo'} usuario</H2Title>
      <Form
        form={form}
        initialValues={{
          is_active: true,
          permissions: [],
        }}
      >
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Nombre</span>}
            name="name"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un nombre.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Correo</span>}
            name="email"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un correo.',
              },
              {
                type: 'email',
                message: 'Formato incorrecto',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Contraseña</span>}
            name="password"
            className="px-6"
            rules={[
              {
                required: !currentUser,
                message: 'Es necesario ingresar un correo.',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Activo</span>}
            name="is_active"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Permisos</span>}
            name="permissions"
            className="px-6"
          >
            <Select mode="multiple">
              {Object.values(PERMISSIONS)
                .map((moduleOptions, index) => {
                  return (
                    <Select.OptGroup key={index} label={moduleOptions.label}>
                      {Object.values(moduleOptions.options).map(
                        (actionOptions) => (
                          <Select.Option
                            key={actionOptions.value}
                            value={actionOptions.value}
                          >
                            {actionOptions.label}{' '}
                            {moduleOptions.label.toLowerCase()}
                          </Select.Option>
                        ),
                      )}
                    </Select.OptGroup>
                  );
                })
                .flat()}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default UserForm;
