import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import { areaTypeFormStore } from '../../hooks/area-type-form-store';
import AreaTypeForm from './AreaTypeForm';
import {
  createAreaType,
  fetchAreaTypes,
  updateAreaType,
} from '@app-actions/area-types';
import { CreateAreaTypeDTO } from '@app-entities/CreateAreaType';
import { AreaTypesReducerDispatcher } from '@app-reducers/area-types';
import { useNavigate } from 'react-router-dom';
import { AreaTypeDTO } from '@app-entities/AreaType';
import { areaTypeInfoStore } from '../../hooks/area-type-info-store';

const AreaTypeCreateUpdateModal = ({
  areaTypesDispatch,
  setSelectedAreaType,
}: {
  areaTypesDispatch: AreaTypesReducerDispatcher;
  setSelectedAreaType: React.Dispatch<React.SetStateAction<AreaTypeDTO | null>>;
}) => {
  const [createFormInstance] = Form.useForm();
  const isOpen = areaTypeFormStore((state) => state.isFormOpen);
  const form = areaTypeFormStore((state) => state.form);
  const currentAreaType = areaTypeFormStore((state) => state.currentAreaType);
  const closeForm = areaTypeFormStore((state) => state.closeForm);
  const setFormInstance = areaTypeFormStore((state) => state.setFormInstance);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const navigate = useNavigate();
  const openAreaTypeInfoModal = areaTypeInfoStore((state) => state.openModal);

  useEffect(() => {
    setFormInstance(createFormInstance);
  }, []);

  const handleOk = async () => {
    setIsCreateLoading(true);
    try {
      let createdAreaType: AreaTypeDTO | null = null;
      await form?.validateFields();
      const areaTypeData: CreateAreaTypeDTO = {
        name: form?.getFieldValue('name'),
        is_active: form?.getFieldValue('is_active'),
      };

      if (!currentAreaType) {
        createdAreaType = await createAreaType(areaTypeData);
      } else {
        const updatedAreaType: AreaTypeDTO = await updateAreaType(
          currentAreaType.id,
          areaTypeData,
        );
        openAreaTypeInfoModal(updatedAreaType);
        setSelectedAreaType(updatedAreaType);
      }

      closeForm();
      await fetchAreaTypes(areaTypesDispatch);

      if (createdAreaType) {
        navigate(`/areas/${createdAreaType.id}`);
      }
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }

    setIsCreateLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      confirmLoading={isCreateLoading}
      onCancel={closeForm}
    >
      <AreaTypeForm currentAreaType={currentAreaType} />
    </Modal>
  );
};

export default AreaTypeCreateUpdateModal;
