import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { QuoteDTO } from '@app-entities/Quote';
import { QuoteAreaServiceDTO } from '@app-entities/QuoteAreaService';
import { styles } from './';
import { getInstallationStartDate, numberWithCommas } from '@app-utils/helpers';
import { UUID } from '@app-entities/common';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import dayjs from '@app-utils/extendedDaysJs';

const QuoteTotals = (quote: QuoteDTO) => {
  const servicesByCategory: {
    [key: UUID]: {
      category: Omit<
        ServiceCategoryDTO,
        'tax_percentage' | 'is_active' | 'created_at' | 'updated_at'
      >;
      subtotal: number;
      tax: number;
    };
  } = {};

  quote.areas.forEach((quoteArea) => {
    quoteArea.services.forEach((quoteAreaService) => {
      if (!servicesByCategory.hasOwnProperty(quoteAreaService.category.id)) {
        servicesByCategory[quoteAreaService.category.id] = {
          category: quoteAreaService.category,
          subtotal: 0,
          tax: 0,
        };
      }

      servicesByCategory[quoteAreaService.category.id].subtotal +=
        quoteAreaService.total;
      servicesByCategory[quoteAreaService.category.id].tax +=
        quoteAreaService.total *
        (quoteAreaService.category_tax_percentage / 100);
    });

    quoteArea.generic_services.forEach((quoteAreaGenericService) => {
      if (
        !servicesByCategory.hasOwnProperty(quoteAreaGenericService.category.id)
      ) {
        servicesByCategory[quoteAreaGenericService.category.id] = {
          category: quoteAreaGenericService.category,
          subtotal: 0,
          tax: 0,
        };
      }

      servicesByCategory[quoteAreaGenericService.category.id].subtotal +=
        quoteAreaGenericService.total;
      servicesByCategory[quoteAreaGenericService.category.id].tax +=
        quoteAreaGenericService.total *
        (quoteAreaGenericService.category_tax_percentage / 100);
    });
  });

  const serviceCategoriesTotal = Object.values(servicesByCategory).reduce(
    (previousValue, serviceCategory) => {
      return previousValue + serviceCategory.subtotal + serviceCategory.tax;
    },
    0,
  );

  const areasTotal = quote.areas_subtotal + quote.areas_tax;

  return (
    <View style={{ ...styles.row, marginTop: '7pt' }}>
      <table>
        <thead>
          <tr>
            <th style={styles.tableHeaderCell}>
              <Text>TOTALES SERVICIOS COMPLEMENTARIOS</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
                ...(Object.keys(servicesByCategory).length === 0
                  ? styles.tableLastRowCells
                  : {}),
                width: '65%',
                textAlign: 'left',
              }}
            >
              <Text>Gran Total Renta de Salas</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellTotalColumn,
                ...styles.tableBodySubHeaderCell,
                width: '35%',
              }}
            >
              <Text>${numberWithCommas(areasTotal)}</Text>
            </td>
          </tr>
          {Object.values(servicesByCategory).map((serviceCategory, index) => {
            return (
              <tr
                style={{
                  ...styles.infoTableBodyRow,
                }}
              >
                <td
                  style={{
                    ...styles.tableBodyCell,
                    ...styles.tableFirstCell,
                    ...styles.tableBodySubHeaderCell,
                    ...(index + 1 === Object.keys(servicesByCategory).length
                      ? styles.tableLastRowCells
                      : {}),
                    width: '65%',
                    textAlign: 'left',
                  }}
                >
                  <Text>Gran Total {serviceCategory.category.name}</Text>
                </td>
                <td
                  style={{
                    ...styles.tableBodyCell,
                    ...styles.tableBodyCellTotalColumn,
                    ...styles.tableBodySubHeaderCell,
                    ...(index + 1 === Object.keys(servicesByCategory).length
                      ? styles.tableLastRowCells
                      : {}),
                    width: '35%',
                  }}
                >
                  <Text>
                    $
                    {numberWithCommas(
                      serviceCategory.subtotal + serviceCategory.tax,
                    )}
                  </Text>
                </td>
              </tr>
            );
          })}
          <tr
            style={{
              ...styles.infoTableBodyRow,
            }}
          >
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodyCellSubtotalIndent,
                width: '50%',
              }}
            ></td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellSubtotalLabel,
                ...styles.tableHeaderCell,
                borderTopWidth: 0,
                width: '15%',
              }}
            >
              <Text>Gran total</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableBodyCellTotalColumn,
                ...styles.tableHeaderCell,
                borderTopWidth: 0,
                width: '35%',
              }}
            >
              <Text>
                ${numberWithCommas(areasTotal + serviceCategoriesTotal)}
              </Text>
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...styles.tableHeaderCell,
              }}
            >
              <Text>Fechas de Montaje y Desmontaje:</Text>
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableFirstCell,
                ...styles.infoTableBodyCellRight,
              }}
            >
              {quote.areas.map((quoteArea, index) => {
                const installationStartDate = getInstallationStartDate(
                  dayjs(quote.event_start_date),
                  quoteArea.installation_days,
                );
                const uninstallationEndDate = dayjs(quote.event_end_date).add(
                  quoteArea.uninstallation_days,
                  'days',
                );

                return (
                  <Text key={index}>
                    {quoteArea.name}: Montaje el{' '}
                    {installationStartDate.format('DD-MM-YYYY')} y desmontaje el{' '}
                    {uninstallationEndDate.format('DD-MM-YYYY')}
                  </Text>
                );
              })}
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...styles.tableHeaderCell,
              }}
            >
              <Text>Diferenciadores:</Text>
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableFirstCell,
                ...styles.infoTableBodyCellRight,
              }}
            >
              <Text>
                AMPLIOS ESPACIOS, 100% SANITIZADOS PARA LA SEGURIDAD SANITARIA
                DE TODOS LOS ASISTENTES
              </Text>
              <Text>PERSONAL CAPACITADO EN SEGURIDAD SANITARIA</Text>
              <Text>AREAS DE REGISTRO TOTALMENTE GRATIS</Text>
              <Text>
                MAS DE 800 CAJONES DE ESTACIONAMIENTO TOTALMENTE GRATUITO
              </Text>
              <Text>
                ACOMPAÑAMIENTO DESDE LA PROSPECCION DE TU EVENTO HASTA EL
                TERMINO DEL MISMO
              </Text>
              <Text>
                CALENDARIZACION DE PAGOS CON EL FIN DE UNA BUENA ADMINISTRACION
                DE Y ORGANIZACIÓN EN LA LIQUIDACION DEL EVENTO, DE ACUERDO A SUS
                NECESIDADES
              </Text>
              <Text>
                GENERAMOS CONVENIOS MODIFICATORIOS SIN AMONESTACION O CARGO
                ADICIONAL.
              </Text>
              <Text>
                AMPLIOS ESPACIOS PARA CARGA Y DESCARGA DE EQUIPO, AL MOMENTO DE
                MONTAR Y DESMONTAR TU EVENTO
              </Text>
              <Text>
                ENTREGAMOS INFORME DE EVENTO CON LA FINALIDAD DE QUE EL CLIENTA
                TENGA UNA RADIOGRAFIA DEL EVENTO
              </Text>
              <Text>
                APOYAMOS A LA VINCULACION DE TRAMITOLOGIA CON LOS DISTINTOS
                ACTORES, EN TEMAS DE TRAMITES ESTATALES Y MUNICIPALES
              </Text>
              <Text>
                APOYAMOS A LA VINCULACION DE SERVICIOS INTEGRALES TURISTICOS CON
                LAS OFICINAS DE ATENCION A VISITANTES(OCV ́S)
              </Text>
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...styles.tableHeaderCell,
              }}
            >
              <Text>Consideraciones:</Text>
            </td>
          </tr>
          <tr>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.tableFirstCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableLastRowCells,
              }}
            >
              <Text>
                1.- Los horarios aplicables son de 8:00 a 22:00 horas, a partir
                de este horario se considera costo por hora extra.
              </Text>

              <Text>
                2.- Las tarifas antes descritas son en pesos mexicanos. En
                tarifa Congreso Incluye: sillas, tablones, medios tablones y
              </Text>
              <Text>
                mantelería básica de acuerdo al inventario del Baja California
                Center. No incluye Equipo Audiovisual, ni alimentos y
              </Text>
              <Text>bebidas, Seguridad y limpieza.</Text>

              <Text>
                3.- Las tarifas de renta de salones para montaje y desmontaje se
                cobran un 50% de descuento.
              </Text>

              <Text>
                4.- Los servicios de Alimentos y Bebidas así como el mobiliario
                y equipo para dicho fin son exclusivos del recinto y
              </Text>
              <Text>
                deberán cotizarse y contratarse con éste. Y deberá liquidarse
                directamente con el proveedor oficial del recinto
              </Text>

              <Text>
                5.- Los servicios de Limpieza al interior de los salones
                rentados son obligación del cliente; Y deberá liquidarse
              </Text>
              <Text>directamente con el proveedor oficial del recinto.</Text>

              <Text>
                6.- Los servicios de Vigilancia al interior de los salones
                rentados y sus accesos son responsabilidad del cliente; Y deberá
              </Text>
              <Text>
                liquidarse directamente con el proveedor oficial del recinto.
              </Text>

              <Text>
                7.- Esta cotización es solo para evaluación del cliente, NO
                constituye un compromiso de renta hasta que se firme el
              </Text>
              <Text>
                contrato de arrendamiento correspondiente entre ambas partes. y
                anticipo correspondiente.
              </Text>

              <Text>
                8.- Las fechas y salones cotizados están sujetas a cambio sin
                previo aviso. Solo se respetan hasta la firma del contrato.
              </Text>
              <Text>y anticipo correspondiente.</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </View>
  );
};

export default QuoteTotals;
