import {
  getQuoteTotalsSummary,
  getTotalsPerArea,
  setEventDiscountPercentage,
  setInstallationDiscountPercentage,
  setUninstallationDiscountPercentage,
} from '@app-actions/quote-create';
import CardPanel from '@app-components/Layout/CardPanel';
import { Label } from '@app-components/Layout/Form';
import { H2Title } from '@app-components/Layout/Text';
import { CreateQuoteContext } from '@app-contexts/quotes/CreateQuoteContext';
import { QuoteCreateSelectedServiceConfig } from '@app-reducers/quote-create';
import {
  getEntryTypeLabelByKey,
  isNotEmpty,
  numberWithCommas,
} from '@app-utils/helpers';
import { InputNumber } from 'antd';
import { useContext } from 'react';

const QuoteTotals = ({
  showDiscounts = false,
}: {
  showDiscounts?: boolean;
}) => {
  const {
    creationDataReducer: [creationData, creationDataDispatch],
    serviceCategoriesReducer: [
      serviceCategoriesState,
      serviceCategoriesDispatch,
    ],
  } = useContext(CreateQuoteContext);

  const totalsPerAreaMap: {
    [x: string]: number;
  } = getTotalsPerArea(creationData);

  const selectedServices = Object.values(creationData.selectedAreas ?? {})
    .map((selectedArea) => Object.values(selectedArea.selected_services ?? {}))
    .flat();
  const genericServices = Object.values(creationData.selectedAreas ?? {})
    .map((selectedArea) => Object.values(selectedArea.generic_services ?? {}))
    .flat();

  const totalsSummary = getQuoteTotalsSummary(
    creationData,
    serviceCategoriesState,
  );

  const onEventDiscountChange = (value: number | null) => {
    setEventDiscountPercentage(creationDataDispatch, value);
  };

  const onInstallationDiscountChange = (value: number | null) => {
    setInstallationDiscountPercentage(creationDataDispatch, value);
  };

  const onUninstallationDiscountChange = (value: number | null) => {
    setUninstallationDiscountPercentage(creationDataDispatch, value);
  };

  return (
    <CardPanel>
      <H2Title>Cotización</H2Title>
      <div className="mt-6">
        <p className="font-bold mb-3">Salones</p>
        <ul className="w-full mb-4 text-gray-500">
          {Object.values(creationData.selectedAreas || {}).map(
            (selectedArea, index) => (
              <li
                key={index}
                className="border-b border-gray-300 py-2 grid grid-cols-8 "
              >
                <div className="col-span-5">
                  <p className="font-bold text-gray-700">
                    {selectedArea.data.name}
                  </p>
                  <p>
                    <span>Tarifa: </span>
                    {selectedArea.entry_type &&
                      getEntryTypeLabelByKey(selectedArea.entry_type)}
                  </p>
                </div>
                <p className="col-span-3 text-right">
                  ${numberWithCommas(totalsPerAreaMap[selectedArea.data.id])}
                </p>
              </li>
            ),
          )}
        </ul>

        {serviceCategoriesState &&
          serviceCategoriesState.data &&
          serviceCategoriesState.data.map(
            (serviceCategory, serviceCategoryIndex) => {
              const categorySelectedServices: QuoteCreateSelectedServiceConfig[] =
                selectedServices.filter(
                  (selectedService) =>
                    selectedService.data.service_category_id ===
                    serviceCategory.id,
                );
              const categoryGenericServices = genericServices.filter(
                (genericService) =>
                  genericService.category.id === serviceCategory.id,
              );

              return (
                (!!categorySelectedServices.length ||
                  !!categoryGenericServices.length) && (
                  <div className="mb-4" key={serviceCategoryIndex}>
                    <p className="font-bold text-gray-700">
                      {serviceCategory.name}
                    </p>
                    {categorySelectedServices.map((selectedService, index) => {
                      const useDayQuantity =
                        selectedService.data.enable_day_quantity ||
                        selectedService.data.has_stock;

                      return (
                        <div
                          className="grid grid-cols-8 text-gray-500"
                          key={`selected-${index}`}
                        >
                          <div className="col-span-5">
                            <p>{selectedService.data.name}</p>
                          </div>
                          <p className="col-span-3 text-right">
                            {selectedService.quantity && (
                              <>
                                x{selectedService.quantity}
                                {useDayQuantity &&
                                  ` x${selectedService.days ?? ''} días`}{' '}
                                = $
                                {numberWithCommas(
                                  selectedService.data.price *
                                    (useDayQuantity
                                      ? selectedService.days ?? 1
                                      : 1) *
                                    selectedService.quantity,
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      );
                    })}
                    {categoryGenericServices.map((genericService, index) => (
                      <div
                        className="grid grid-cols-8 text-gray-500"
                        key={`generic-${index}`}
                      >
                        <div className="col-span-5">
                          <p>{genericService.name}</p>
                        </div>
                        <p className="col-span-3 text-right">
                          {genericService.quantity &&
                            (genericService.is_included ||
                              (genericService.price !== undefined &&
                                genericService.price !== null)) && (
                              <>
                                x{genericService.quantity}
                                {isNotEmpty(genericService.days) &&
                                  ` x${genericService.days ?? ''} días`}{' '}
                                = $
                                {numberWithCommas(
                                  (genericService.is_included
                                    ? 0
                                    : genericService.price!) *
                                    (isNotEmpty(genericService.days)
                                      ? genericService.days ?? 1
                                      : 1) *
                                    genericService.quantity,
                                )}
                              </>
                            )}
                        </p>
                      </div>
                    ))}
                  </div>
                )
              );
            },
          )}

        <div className="text-right py-4 border-b border-gray-300">
          <p className="font-bold">Subtotal</p>
          <p className="text-gray-500">
            ${numberWithCommas(totalsSummary.subtotal)}
          </p>
        </div>
        <div className="text-right py-4 border-b border-gray-300">
          <p className="font-bold">IVA</p>
          <p className="text-gray-500">
            ${numberWithCommas(totalsSummary.subtotal_iva)}
          </p>
        </div>
        <div className="grid grid-cols-5 gap-2">
          <div className="col-span-3 py-3">
            {creationData.current_stage === 3 && (
              <>
                <Label>Descuentos</Label>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1">
                    <p>Evento</p>
                    <InputNumber
                      className="w-full"
                      min={0}
                      max={100}
                      step={1}
                      addonAfter="%"
                      value={creationData.event_discount_percentage}
                      onKeyDown={(e) => {
                        if (e.key === 'enter') {
                          e.preventDefault();
                        }
                      }}
                      onChange={onEventDiscountChange}
                    />
                  </div>
                  <div className="col-span-1">
                    <p>Montaje</p>
                    <InputNumber
                      className="w-full"
                      min={0}
                      max={100}
                      step={1}
                      addonAfter="%"
                      value={creationData.installation_discount_percentage}
                      onKeyDown={(e) => {
                        if (e.key === 'enter') {
                          e.preventDefault();
                        }
                      }}
                      onChange={onInstallationDiscountChange}
                    />
                  </div>
                  <div className="col-span-1">
                    <p>Desmontaje</p>
                    <InputNumber
                      className="w-full"
                      min={0}
                      max={100}
                      step={1}
                      addonAfter="%"
                      value={creationData.uninstallation_discount_percentage}
                      onKeyDown={(e) => {
                        if (e.key === 'enter') {
                          e.preventDefault();
                        }
                      }}
                      onChange={onUninstallationDiscountChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="text-right py-4 col-span-2">
            <p className="font-bold">Gran Total</p>
            <p className="text-gray-500">
              ${numberWithCommas(totalsSummary.total)}
            </p>
          </div>
        </div>
      </div>
    </CardPanel>
  );
};

export default QuoteTotals;
