import React from 'react';
import { AreaTypeDTO } from '@app-entities/AreaType';
import { HTTPFetchStateStatus } from '@app-entities/common';

export interface AreaTypesReducerState {
  status?: HTTPFetchStateStatus;
  data?: AreaTypeDTO[];
}
export interface AreaTypesReducerAction {
  type: string;
  payload: AreaTypesReducerState;
}
export type AreaTypesReducerDispatcher = React.Dispatch<AreaTypesReducerAction>;
export type AreaTypesReducer = (
  state: AreaTypesReducerState,
  action: AreaTypesReducerAction,
) => {};

export const areaTypesReducer = (
  state: AreaTypesReducerState,
  action: AreaTypesReducerAction,
): AreaTypesReducerState => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload.status };
    case 'SET_DATA':
      return { ...state, status: 'READY', data: action.payload.data };
    default:
      return state;
  }
};
