import React, { useEffect, useReducer, useState } from 'react';
import { Button, Popconfirm, Table, message } from 'antd';
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { fetchServiceCategories } from '@app-actions/service-categories';
import { deleteService, fetchServices } from '@app-actions/services';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import MainLayout from '@app-components/Layout/MainLayout';
import { serviceCategoriesReducer } from '@app-reducers/service-categories';
import { servicesReducer } from '@app-reducers/services';
import { FETCH_FINISHED_STATUS, PERMISSIONS } from '@app-utils/constants';
import ContentLoader from '@app-components/Layout/ContentLoader';
import { H2Title } from '@app-components/Layout/Text';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { ServiceDTO } from '@app-entities/Service';
import ServiceCreateUpdateModal from './ServiceCreateUpdateModal';
import { serviceFormStore } from '../../hooks/service-form-store';
import { UUID } from '@app-entities/common';
import CategoryDetailsModal from './CategoryDetailsModal';
import { categoryInfoStore } from '../../hooks/service-category-info-store';
import { categoryFormStore } from '../../hooks/service-category-form-store';
import ServiceCategoryCreateUpdateModal from './ServiceCategoryCreateUpdateModal';
import { withAuthentication } from '@app-components/HOCs/withAuthentication';
import { useUserSession } from 'src/hooks/user-session';
import { hasPermission } from '@app-utils/helpers';

const { Column } = Table;

const ServicesListPage = () => {
  const { user } = useUserSession();
  const openCategoryModal = categoryInfoStore((state) => state.openModal);
  const openServiceForm = serviceFormStore((state) => state.openForm);
  const openCategoryForm = categoryFormStore((state) => state.openForm);
  const [serviceCategories, serviceCategoriesDispatch] = useReducer(
    serviceCategoriesReducer,
    {},
  );
  const [selectedServiceCategory, setSelectedServiceCategory] =
    useState<ServiceCategoryDTO | null>(null);
  const [services, servicesDispatch] = useReducer(servicesReducer, {});
  const { id: serviceCategoryId } = useParams();

  useEffect(() => {
    (async () => {
      fetchServiceCategories(serviceCategoriesDispatch);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedServiceCategory) {
        fetchServices(servicesDispatch, selectedServiceCategory.id);
      }
    })();
  }, [selectedServiceCategory]);

  const serviceCategoriesFetchFinished = FETCH_FINISHED_STATUS.includes(
    serviceCategories.status ?? 'LOADING',
  );
  const servicesFetchFinished = FETCH_FINISHED_STATUS.includes(
    serviceCategories.status ?? 'LOADING',
  );

  useEffect(() => {
    (async () => {
      if (serviceCategoriesFetchFinished) {
        const foundServiceCategory = serviceCategories.data?.find(
          (serviceCategory) => serviceCategory.id === serviceCategoryId,
        );

        if (foundServiceCategory) {
          setSelectedServiceCategory(foundServiceCategory);
        } else {
          const firstServiceCategory = serviceCategories.data?.find(
            (serviceCategory) => serviceCategory,
          );
          setSelectedServiceCategory(firstServiceCategory ?? null);
        }
      }
    })();
  }, [serviceCategories.status, serviceCategoryId]);

  const handleDeleteService = async (id: UUID) => {
    try {
      await deleteService(id);
      fetchServices(servicesDispatch, selectedServiceCategory!.id);
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }
  };

  const updateServicesPermission = hasPermission(user?.permissions, [
    PERMISSIONS.service.options.update.value,
  ]);

  const deleteServicesPermission = hasPermission(user?.permissions, [
    PERMISSIONS.service.options.delete.value,
  ]);

  return (
    <>
      {selectedServiceCategory && (
        <>
          <ServiceCreateUpdateModal
            serviceCategory={selectedServiceCategory}
            servicesDispatch={servicesDispatch}
          />
          <CategoryDetailsModal
            serviceCategoriesDispatch={serviceCategoriesDispatch}
          />
          <ServiceCategoryCreateUpdateModal
            serviceCategoriesDispatch={serviceCategoriesDispatch}
            setSelectedServiceCategory={setSelectedServiceCategory}
          />
        </>
      )}
      <MainLayout>
        <CenteredContainer width="full">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-1">
              <CardPanel>
                <H2Title>Categorías</H2Title>
                {!serviceCategoriesFetchFinished ? (
                  <ContentLoader />
                ) : (
                  <ul className="mt-2">
                    {serviceCategories.data &&
                      serviceCategories.data.map((serviceCategory) => (
                        <li
                          className={`w-full  block font-bold shadow-md mb-2 text-xs ${
                            selectedServiceCategory &&
                            serviceCategory.id === selectedServiceCategory.id
                              ? 'bg-orange-600 text-white'
                              : 'bg-white'
                          }`}
                          key={serviceCategory.id}
                        >
                          <Link
                            to={`/servicios/${serviceCategory.id}`}
                            className="p-4 block w-full"
                          >
                            {serviceCategory.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
                {hasPermission(user?.permissions, [
                  PERMISSIONS.serviceCategory.options.create.value,
                ]) && (
                  <Button
                    onClick={() => openCategoryForm()}
                    type="primary"
                    className="block mt-4 shadow-md rounded-3xl"
                    icon={<PlusCircleOutlined />}
                  >
                    Nueva categoría
                  </Button>
                )}
              </CardPanel>
            </div>
            <div className="col-span-5">
              <CardPanel>
                {!serviceCategoriesFetchFinished ? (
                  <ContentLoader />
                ) : (
                  selectedServiceCategory && (
                    <>
                      <div className="border-b-2 py-5">
                        <div className="grid grid-cols-2">
                          <div className="col-span-1">
                            {<H2Title>{selectedServiceCategory.name}</H2Title>}
                            {hasPermission(user?.permissions, [
                              PERMISSIONS.serviceCategory.options.view.value,
                            ]) && (
                              <Button
                                type="ghost"
                                className="mt-2 block shadow-md rounded-3xl"
                                onClick={() =>
                                  openCategoryModal(selectedServiceCategory)
                                }
                              >
                                <InfoCircleOutlined />
                              </Button>
                            )}
                          </div>
                          <div className="col-span-1">
                            {hasPermission(user?.permissions, [
                              PERMISSIONS.service.options.create.value,
                            ]) && (
                              <Button
                                onClick={() => openServiceForm()}
                                type="primary"
                                className="block float-right"
                                icon={<PlusCircleOutlined />}
                              >
                                Nuevo item
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        {!servicesFetchFinished ? (
                          <ContentLoader />
                        ) : (
                          hasPermission(user?.permissions, [
                            PERMISSIONS.service.options.view.value,
                          ]) && (
                            <Table
                              dataSource={(services.data ?? []).map(
                                (
                                  quote,
                                  index,
                                ): ServiceDTO & { key: number } => ({
                                  ...quote,
                                  key: index,
                                }),
                              )}
                            >
                              <Column
                                title="Nombre"
                                dataIndex="name"
                                key="name"
                              />
                              <Column
                                title="Especificación"
                                dataIndex="description"
                                key="description"
                              />
                              <Column
                                title="Precio"
                                dataIndex="price"
                                key="price"
                              />
                              <Column
                                title="Tipo de cobro por día"
                                dataIndex="enable_day_quantity"
                                key="enable_day_quantity"
                                render={(value, service: ServiceDTO) =>
                                  service.enable_day_quantity ? 'Si' : 'No'
                                }
                              />
                              <Column
                                title="Cantidad"
                                dataIndex="quantity"
                                key="quantity"
                                render={(value, service: ServiceDTO) =>
                                  service.has_stock ? value : '-'
                                }
                              />
                              <Column
                                title="Acciones"
                                dataIndex="actions"
                                key="actions"
                                render={(value, service: ServiceDTO) => (
                                  <div className="clear-both">
                                    {updateServicesPermission && (
                                      <button
                                        className="float-left mr-2"
                                        onClick={() => openServiceForm(service)}
                                      >
                                        <EditOutlined className="text-xl" />
                                      </button>
                                    )}
                                    {deleteServicesPermission && (
                                      <Popconfirm
                                        title="Eliminar servicio"
                                        onConfirm={() =>
                                          handleDeleteService(service.id)
                                        }
                                        okText="Si"
                                        cancelText="No"
                                      >
                                        <button className="float-left mr-2">
                                          <DeleteOutlined className="text-xl" />
                                        </button>
                                      </Popconfirm>
                                    )}
                                  </div>
                                )}
                              />
                            </Table>
                          )
                        )}
                      </div>
                    </>
                  )
                )}
              </CardPanel>
            </div>
          </div>
        </CenteredContainer>
      </MainLayout>
    </>
  );
};

export default withAuthentication(ServicesListPage);
