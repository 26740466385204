import React from 'react';
import { ServiceDTO } from '@app-entities/Service';
import { HTTPFetchStateStatus } from '@app-entities/common';

export interface ServicesReducerState {
  status?: HTTPFetchStateStatus;
  data?: ServiceDTO[];
}
export interface ServicesReducerAction {
  type: string;
  payload: ServicesReducerState;
}
export type ServicesReducerDispatcher = React.Dispatch<ServicesReducerAction>;
export type ServicesReducer = (
  state: ServicesReducerState,
  action: ServicesReducerAction,
) => {};

export const servicesReducer = (
  state: ServicesReducerState,
  action: ServicesReducerAction,
): ServicesReducerState => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload.status };
    case 'SET_DATA':
      return { ...state, status: 'READY', data: action.payload.data };
    default:
      return state;
  }
};
