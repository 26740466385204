import React from 'react';
import { ChildrenProp } from '../types/generic';

export const H1Title = ({ children }: ChildrenProp) => {
  return <h1 className="text-black text-h1-title mb-2">{children}</h1>;
};

export const H2Title = ({ children }: ChildrenProp) => {
  return (
    <div className="table w-full">
      <h2 className="text-black text-h2-title mb-2 relative">
        {children}
        <span className="absolute -bottom-2 left-0 w-32 bg-orange-500 h-1"></span>
      </h2>
    </div>
  );
};
