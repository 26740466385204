import React, { useState } from 'react';
import { H2Title } from '@app-components/Layout/Text';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import { areaFormStore } from '../../hooks/area-form-store';
import { AreaDTO } from '@app-entities/Area';
import { PRICING_TYPE, PRICING_TYPES } from '@app-utils/constants';
import { AreaPricingType } from '@app-entities/AreaPricingType';

const AreaForm = ({ currentArea }: { currentArea: AreaDTO | null }) => {
  const [showDynamicPriceFields, setShowDynamicPriceFields] = useState<boolean>(
    currentArea?.pricing_type === PRICING_TYPE.DYNAMIC,
  );
  const form = areaFormStore((state) => state.form) ?? undefined;

  const handlePricingTypeChange = (pricingType: AreaPricingType) => {
    setShowDynamicPriceFields(pricingType === PRICING_TYPE.DYNAMIC);
  };

  return (
    <>
      <H2Title>{currentArea ? 'Editar' : 'Nueva'} área</H2Title>
      <Form
        form={form}
        initialValues={{
          is_active: true,
          only_full_area: false,
        }}
      >
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Nombre</span>}
            name="name"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar un nombre.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Alto</span>}
            name="height"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar la altura.',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            label={<span className="font-bold">Ancho</span>}
            name="width"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar la anchura.',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Largo</span>}
            name="length"
            className="px-6"
            rules={[
              {
                required: true,
                message: 'Es necesario ingresar el largo.',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-6">
          <Form.Item
            label={<span className="font-bold">Tipo de precio</span>}
            name="pricing_type"
            className="px-6"
          >
            <Select
              options={Object.values(PRICING_TYPES)}
              onChange={(value) => handlePricingTypeChange(value)}
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 mt-6 border-t-2 pt-4">
          <p className="text-lg px-6 font-bold">Año actual</p>
        </div>
        {!showDynamicPriceFields && (
          <div className="grid grid-cols-2 mt-6">
            <Form.Item
              label={<span className="font-bold">Precio fijo</span>}
              name="fixed_price"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: 'Es necesario ingresar un precio fijo.',
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </div>
        )}
        {showDynamicPriceFields && (
          <>
            <div className="grid grid-cols-1 mt-6">
              <Form.Item
                label={<span className="font-bold">Precio de exposición</span>}
                name="expo_price"
                className="px-6"
                rules={[
                  {
                    required: true,
                    message: 'Es necesario ingresar un precio de exposición.',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </div>
            <div className="grid grid-cols-1 mt-6">
              <Form.Item
                label={<span className="font-bold">Precio de congreso</span>}
                name="congress_price"
                className="px-6"
                rules={[
                  {
                    required: true,
                    message: 'Es necesario ingresar un precio de congreso.',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </div>
          </>
        )}
        <div className="grid grid-cols-2 mt-6 border-t-2 pt-4">
          <p className="text-lg px-6 font-bold">Año próximo</p>
        </div>
        {!showDynamicPriceFields && (
          <div className="grid grid-cols-2 mt-6">
            <Form.Item
              label={<span className="font-bold">Precio fijo</span>}
              name="next_fixed_price"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: 'Es necesario ingresar un precio fijo.',
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </div>
        )}
        {showDynamicPriceFields && (
          <>
            <div className="grid grid-cols-1 mt-6">
              <Form.Item
                label={<span className="font-bold">Precio de exposición</span>}
                name="next_expo_price"
                className="px-6"
                rules={[
                  {
                    required: true,
                    message: 'Es necesario ingresar un precio de exposición.',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </div>
            <div className="grid grid-cols-1 mt-6">
              <Form.Item
                label={<span className="font-bold">Precio de congreso</span>}
                name="next_congress_price"
                className="px-6"
                rules={[
                  {
                    required: true,
                    message: 'Es necesario ingresar un precio de congreso.',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </div>
          </>
        )}
        <div className="grid grid-cols-2 mt-6">
          <Form.Item
            label={<span className="font-bold">Activo</span>}
            name="is_active"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={<span className="font-bold">Cotizar área completa</span>}
            name="only_full_area"
            valuePropName="checked"
            className="px-6"
          >
            <Switch />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default AreaForm;
