import React from 'react';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import MainLayout from '../../components/Layout/MainLayout';
import { withAuthentication } from '@app-components/HOCs/withAuthentication';

const DashboardPage = () => {
  return (
    <MainLayout>
      <CenteredContainer width="full"></CenteredContainer>
    </MainLayout>
  );
};

export default withAuthentication(DashboardPage);
