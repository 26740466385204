import dayjs from '@app-utils/extendedDaysJs';
import {
  QuoteCreateGenericServiceConfig,
  QuoteCreateReducerDispatcher,
  QuoteCreateReducerState,
  QuoteCreateSelectedAreaConfig,
  QuoteCreateSelectedAreasConfigMap,
  QuoteCreateSelectedServiceConfig,
} from '@app-reducers/quote-create';
import {
  ENTRY_TYPE,
  EVENT_TYPES,
  GENERAL_IVA,
  NIGHT_ENTRY_PRICE,
  PRICING_TYPE,
} from '@app-utils/constants';
import {
  calculateDays,
  getInstallationStartDate,
  isNotEmpty,
} from '@app-utils/helpers';
import { AxiosResponse } from 'axios';
import api from '../utils/api';
import { CreateQuoteDTO } from '@app-entities/CreateQuote';
import { EventType } from '@app-entities/EventType';
import { EntryType } from '@app-entities/EntryType';
import { CreateQuoteAreaDTO } from '@app-entities/CreateQuoteArea';
import { creationDataInitialValue } from '@app-contexts/quotes/CreateQuoteContextProvider';
import { CreateAreaServiceDTO } from '@app-entities/CreateAreaService';
import { ServiceCategoriesReducerState } from '@app-reducers/service-categories';
import { UUID } from '@app-entities/common';
import { AreaAvailabilityDTO } from '@app-entities/AreasAvailability';
import { CreateAreaGenericServiceDTO } from '@app-entities/CreateAreaGenericService';

export const setStartDate = async (
  dispatch: QuoteCreateReducerDispatcher,
  startDate: dayjs.Dayjs | null,
): Promise<void> => {
  dispatch({
    type: 'SET_START_DATE',
    payload: {
      start_date: startDate,
    },
  });
};

export const setEndDate = async (
  dispatch: QuoteCreateReducerDispatcher,
  endDate: dayjs.Dayjs | null,
): Promise<void> => {
  dispatch({
    type: 'SET_END_DATE',
    payload: {
      end_date: endDate,
    },
  });
};

export const setEventDiscountPercentage = async (
  dispatch: QuoteCreateReducerDispatcher,
  eventDiscountPercentage: number | null,
): Promise<void> => {
  dispatch({
    type: 'SET_EVENT_DISCOUNT_PERCENTAGE',
    payload: {
      event_discount_percentage: eventDiscountPercentage,
    },
  });
};

export const setInstallationDiscountPercentage = async (
  dispatch: QuoteCreateReducerDispatcher,
  installationDiscountPercentage: number | null,
): Promise<void> => {
  dispatch({
    type: 'SET_INSTALLATION_DISCOUNT_PERCENTAGE',
    payload: {
      installation_discount_percentage: installationDiscountPercentage,
    },
  });
};

export const setUninstallationDiscountPercentage = async (
  dispatch: QuoteCreateReducerDispatcher,
  uninstallationDiscountPercentage: number | null,
): Promise<void> => {
  dispatch({
    type: 'SET_UNINSTALLATION_DISCOUNT_PERCENTAGE',
    payload: {
      uninstallation_discount_percentage: uninstallationDiscountPercentage,
    },
  });
};

export const setSelectedAreas = async (
  dispatch: QuoteCreateReducerDispatcher,
  selectedAreas: QuoteCreateSelectedAreasConfigMap,
  currentSelectedDates: Pick<
    QuoteCreateReducerState,
    'start_date' | 'end_date'
  >,
): Promise<void> => {
  const availabilities: Promise<any>[] = [];

  if (currentSelectedDates.start_date && currentSelectedDates.end_date) {
    Object.keys(selectedAreas).forEach((selectedAreaKey) => {
      const selectedArea = selectedAreas[selectedAreaKey];
      let startDate: dayjs.Dayjs;
      let endDate: dayjs.Dayjs;

      if (selectedArea.installation_days) {
        startDate = getInstallationStartDate(
          currentSelectedDates.start_date!,
          selectedArea.installation_days,
        );
      } else {
        startDate = currentSelectedDates.start_date!;
      }

      if (selectedArea.uninstallation_days) {
        endDate = currentSelectedDates.end_date!.add(
          selectedArea.uninstallation_days,
          'days',
        );
      } else {
        endDate = currentSelectedDates.end_date!;
      }

      if (!selectedAreas[selectedAreaKey].availability_map) {
        selectedAreas[selectedAreaKey].availability_map = {};
      }

      availabilities.push(
        new Promise<void>(async (resolve, reject) => {
          try {
            const availabilityResponse: AxiosResponse<AreaAvailabilityDTO[]> =
              await api.get(`/areas/${selectedArea.data.id}/availability`, {
                params: {
                  start_date: startDate.format('YYYY-MM-DD'),
                  end_date: endDate.format('YYYY-MM-DD'),
                },
              });

            availabilityResponse.data.forEach((dayAvailability) => {
              const currentDay = dayjs(dayAvailability.day);
              selectedAreas[selectedAreaKey].availability_map![
                currentDay.format('YYYY-MM-DD')
              ] = { ...dayAvailability, day: currentDay };
            });
            resolve();
          } catch (err) {
            reject(err);
          }
        }),
      );
    });
  }

  await Promise.all(availabilities);

  dispatch({
    type: 'SET_SELECTED_AREAS',
    payload: {
      selectedAreas: selectedAreas,
    },
  });
};

export const removeSelectedArea = async (
  dispatch: QuoteCreateReducerDispatcher,
  areaId: UUID,
): Promise<void> => {
  dispatch({
    type: 'REMOVE_SELECTED_AREA',
    payload: areaId,
  });
};

export const configureSelectedArea = async (
  dispatch: QuoteCreateReducerDispatcher,
  selectedArea: QuoteCreateSelectedAreaConfig,
): Promise<void> => {
  dispatch({
    type: 'CONFIGURE_SELECTED_AREA',
    payload: selectedArea,
  });
};

export const nextStage = async (
  dispatch: QuoteCreateReducerDispatcher,
): Promise<void> => {
  dispatch({
    type: 'SET_NEXT_STAGE',
    payload: {},
  });
};

export const previousStage = async (
  dispatch: QuoteCreateReducerDispatcher,
): Promise<void> => {
  dispatch({
    type: 'SET_PREVIOUS_STAGE',
    payload: {},
  });
};

export type QuoteCreateUpdateFormData = {
  event_name: string;
  company: string;
  atention_to: string;
  phone: string;
  email: string;
  tax_invoice_data: string;
  event_start_date: string;
  event_end_date: string;
  requester_name: string;
  expected_attendees: number;
  userId: UUID;
};

export const create = async (
  dispatch: QuoteCreateReducerDispatcher,
  createData: QuoteCreateReducerState,
  formData: QuoteCreateUpdateFormData,
): Promise<void> => {
  try {
    await api.post<any, AxiosResponse<any>, CreateQuoteDTO>(
      '/quotes',
      getDataPayload(createData, formData),
    );
  } catch (error) {
    throw new Error('Ocurrió un error al crear');
  }

  dispatch({
    type: 'RESET_FORM',
    payload: creationDataInitialValue,
  });
};

export const update = async (
  dispatch: QuoteCreateReducerDispatcher,
  id: UUID,
  updateData: QuoteCreateReducerState,
  formData: QuoteCreateUpdateFormData,
): Promise<void> => {
  try {
    await api.put<any, AxiosResponse<any>, CreateQuoteDTO>(
      `/quotes/${id}`,
      getDataPayload(updateData, formData),
    );
  } catch (error) {
    throw new Error('Ocurrió un error al guardar');
  }

  dispatch({
    type: 'RESET_FORM',
    payload: creationDataInitialValue,
  });
};

const getDataPayload = (
  createData: QuoteCreateReducerState,
  formData: QuoteCreateUpdateFormData,
) => {
  return {
    event_name: formData.event_name,
    company: formData.company,
    atention_to: formData.atention_to,
    phone: formData.phone,
    email: formData.email,
    tax_invoice_data: formData.tax_invoice_data,
    event_start_date: createData.start_date?.format() ?? '',
    event_end_date: createData.end_date?.format() ?? '',
    requester_name: formData.requester_name,
    expected_attendees: formData.expected_attendees,
    event_discount_percentage: createData.event_discount_percentage ?? null,
    installation_discount_percentage:
      createData.installation_discount_percentage ?? null,
    uninstallation_discount_percentage:
      createData.uninstallation_discount_percentage ?? null,
    userId: formData.userId,
    areas: Object.values(createData.selectedAreas ?? {}).map(
      (selectedArea): CreateQuoteAreaDTO => ({
        id: selectedArea.data.id,
        event_type: selectedArea.event_type as EventType,
        entry_type: selectedArea.entry_type as EntryType,
        used_space: selectedArea.used_space as number,
        installation_days: selectedArea.installation_days ?? null,
        uninstallation_days: selectedArea.uninstallation_days ?? null,
        installation_same_day: selectedArea.installation_same_day ?? false,
        services: Object.values(selectedArea.selected_services ?? {}).map(
          (selectedService): CreateAreaServiceDTO => ({
            id: selectedService.data.id,
            quantity: selectedService.quantity!,
            days: selectedService.days ?? null,
            is_included: !!selectedService.is_included,
          }),
        ),
        generic_services: Object.values(
          selectedArea.generic_services ?? {},
        ).map(
          (genericService): CreateAreaGenericServiceDTO => ({
            name: genericService.name!,
            description: genericService.description!,
            quantity: genericService.quantity!,
            days: genericService.days ?? null,
            price: !!genericService.is_included ? 0 : genericService.price!,
            is_included: !!genericService.is_included,
            service_category_id: genericService.category.id,
          }),
        ),
      }),
    ),
  };
};

export const cancel = (dispatch: QuoteCreateReducerDispatcher) => {
  dispatch({
    type: 'RESET_FORM',
    payload: creationDataInitialValue,
  });
};

export const getTotalFromSelectedArea = (
  selectedArea: QuoteCreateSelectedAreaConfig,
  creationData: QuoteCreateReducerState,
): number => {
  if (
    !selectedArea.event_type ||
    !selectedArea.entry_type ||
    !creationData.start_date ||
    !creationData.end_date
  )
    return 0;

  const areaData = selectedArea.data;
  let pricePerEventDay: number = 0;
  let eventDays: number = 0;
  const isForNextYear =
    parseInt(creationData.start_date.format('YYYY')) >
    parseInt(dayjs().format('YYYY'));

  if (areaData.pricing_type === PRICING_TYPE.FIXED) {
    pricePerEventDay = isForNextYear
      ? areaData.next_fixed_price!
      : areaData.fixed_price!;
  } else {
    if (selectedArea.event_type === EVENT_TYPES.CONGRESS.value) {
      pricePerEventDay = isForNextYear
        ? areaData.next_congress_price!
        : areaData.congress_price!;
    } else if (selectedArea.event_type === EVENT_TYPES.EXPO.value) {
      pricePerEventDay = isForNextYear
        ? areaData.next_expo_price!
        : areaData.expo_price!;
    }
  }

  let intsallationPrice: number = 0;

  if (selectedArea.entry_type === ENTRY_TYPE.NIGHT.value) {
    intsallationPrice = NIGHT_ENTRY_PRICE;
  } else if (selectedArea.entry_type === ENTRY_TYPE.NORMAL.value) {
    intsallationPrice = pricePerEventDay / 2;
  }

  if (creationData.start_date && creationData.end_date) {
    eventDays = calculateDays(creationData.start_date, creationData.end_date);
  }

  const eventAmount: number =
    pricePerEventDay * eventDays * (selectedArea.used_space ?? 0);
  const eventDiscount =
    eventAmount * ((creationData.event_discount_percentage ?? 0) / 100);

  const installationDays = selectedArea.installation_same_day
    ? 1
    : selectedArea.installation_days ?? 0;
  const uninstallationDays = selectedArea.installation_same_day
    ? 1
    : selectedArea.uninstallation_days ?? 0;

  const installationAmount: number =
    intsallationPrice * installationDays * (selectedArea.used_space ?? 0);
  const uninstallationAmount: number =
    intsallationPrice * uninstallationDays * (selectedArea.used_space ?? 0);
  const discountOnInstallation =
    installationAmount *
    ((creationData.installation_discount_percentage ?? 0) / 100);
  const discountOnUninstallation =
    uninstallationAmount *
    ((creationData.uninstallation_discount_percentage ?? 0) / 100);

  return (
    eventAmount -
    eventDiscount +
    (installationAmount - discountOnInstallation) +
    (uninstallationAmount - discountOnUninstallation)
  );
};

export const getAreasSubtotal = (
  creationData: QuoteCreateReducerState,
): number => {
  return Object.values(creationData.selectedAreas ?? {}).reduce(
    (previous, currentArea) => {
      const areaTotal: number = getTotalFromSelectedArea(
        currentArea,
        creationData,
      );
      return previous + areaTotal;
    },
    0,
  );
};

export const getTotalsPerArea = (
  creationData: QuoteCreateReducerState,
): {
  [x: string]: number;
} => {
  const totalsPerAreaMap: {
    [x: string]: number;
  } = {};
  Object.values(creationData.selectedAreas ?? {}).forEach((currentArea) => {
    totalsPerAreaMap[currentArea.data.id] = getTotalFromSelectedArea(
      currentArea,
      creationData,
    );
  });
  return totalsPerAreaMap;
};

export const getIvaPerServiceCategoryMap = (
  serviceCategoriesState: ServiceCategoriesReducerState,
) => {
  const ivaPerServiceCategoryMap: {
    [x: string]: number;
  } = {};
  serviceCategoriesState.data?.forEach((serviceCategory) => {
    ivaPerServiceCategoryMap[serviceCategory.id] =
      serviceCategory.tax_percentage;
  });

  return ivaPerServiceCategoryMap;
};

export const getServiceTotal = (
  service: QuoteCreateSelectedServiceConfig,
): number => {
  let serviceTotal: number = 0;

  if (!service.is_included && service.quantity) {
    const useDayQuantity =
      service.data.enable_day_quantity || service.data.has_stock;
    const days = useDayQuantity ? service.days! : 1;
    serviceTotal = service.quantity * days * service.data.price;
  }

  return serviceTotal;
};

export const getGenericServiceTotal = (
  service: QuoteCreateGenericServiceConfig,
): number => {
  let serviceTotal: number = 0;

  if (
    !service.is_included &&
    service.quantity &&
    service.price !== null &&
    service.price !== undefined
  ) {
    const days = isNotEmpty(service.days) ? service.days! : 1;
    serviceTotal = service.quantity * days * service.price;
  }

  return serviceTotal;
};

export const getServicesSubtotal = (
  selectedServices: QuoteCreateSelectedServiceConfig[],
  genericServices: QuoteCreateGenericServiceConfig[],
): number => {
  const selectedServicesSubtotal = selectedServices.reduce(
    (previous, selectedService) => {
      return previous + getServiceTotal(selectedService);
    },
    0,
  );
  const genericServicesSubtotal = genericServices.reduce(
    (previous, genericService) => {
      return previous + getGenericServiceTotal(genericService);
    },
    0,
  );
  return selectedServicesSubtotal + genericServicesSubtotal;
};

export const getServicesIva = (
  selectedServices: QuoteCreateSelectedServiceConfig[],
  genericServices: QuoteCreateGenericServiceConfig[],
  ivaPerServiceCategoryMap: {
    [x: string]: number;
  },
): number => {
  const selectedServicesIva = selectedServices.reduce(
    (previous, selectedService) => {
      return (
        previous +
        parseFloat(
          (
            getServiceTotal(selectedService) *
            (ivaPerServiceCategoryMap[
              selectedService.data.service_category_id
            ] /
              100)
          ).toFixed(2),
        )
      );
    },
    0,
  );
  const genericServicesIva = genericServices.reduce(
    (previous, genericService) => {
      return (
        previous +
        parseFloat(
          (
            getGenericServiceTotal(genericService) *
            (ivaPerServiceCategoryMap[genericService.category.id] / 100)
          ).toFixed(2),
        )
      );
    },
    0,
  );
  return selectedServicesIva + genericServicesIva;
};

export interface QuoteTotalsSummary {
  areas_subtotal: number;
  services_subtotal: number;
  services_iva: number;
  areas_iva: number;
  subtotal: number;
  subtotal_iva: number;
  total: number;
}

export const getQuoteTotalsSummary = (
  creationData: QuoteCreateReducerState,
  serviceCategories: ServiceCategoriesReducerState,
): QuoteTotalsSummary => {
  const selectedServices = Object.values(creationData.selectedAreas ?? {})
    .map((selectedArea) => Object.values(selectedArea.selected_services ?? {}))
    .flat();
  const genericServices = Object.values(creationData.selectedAreas ?? {})
    .map((selectedArea) => Object.values(selectedArea.generic_services ?? {}))
    .flat();
  const ivaPerServiceCategoryMap: {
    [x: string]: number;
  } = getIvaPerServiceCategoryMap(serviceCategories);
  const areasSubtotal = getAreasSubtotal(creationData);
  const servicesSubtotal = getServicesSubtotal(
    selectedServices,
    genericServices,
  );
  const subtotal = parseFloat((areasSubtotal + servicesSubtotal).toFixed(2));
  const areasIva = parseFloat((areasSubtotal * GENERAL_IVA).toFixed(2));
  const servicesIva = getServicesIva(
    selectedServices,
    genericServices,
    ivaPerServiceCategoryMap,
  );
  const subtotalIva = parseFloat((areasIva + servicesIva).toFixed(2));
  const total = parseFloat((subtotal + subtotalIva).toFixed(2));

  return {
    areas_subtotal: areasSubtotal,
    services_subtotal: servicesSubtotal,
    services_iva: servicesIva,
    areas_iva: areasIva,
    subtotal: subtotal,
    subtotal_iva: subtotalIva,
    total: total,
  };
};

export const getEventDays = (creationData: QuoteCreateReducerState) => {
  if (!creationData.start_date || !creationData.end_date) {
    return 0;
  }
  return calculateDays(creationData.start_date, creationData.end_date);
};

export const getInstallationCalculatedDays = (
  creationData: QuoteCreateReducerState,
  selectedArea: QuoteCreateSelectedAreaConfig,
): number => {
  if (!creationData.start_date || !selectedArea.installation_days) {
    return 0;
  }

  return selectedArea.installation_days;
};

export const getUnistallationCalculatedDays = (
  creationData: QuoteCreateReducerState,
  selectedArea: QuoteCreateSelectedAreaConfig,
): number => {
  if (!creationData.end_date || !selectedArea.uninstallation_days) {
    return 0;
  }
  return selectedArea.uninstallation_days;
};
