import { ENTRY_TYPE } from './constants';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';
import dayjs from './extendedDaysJs';

export const getInstallationStartDate = (
  startDate: dayjs.Dayjs,
  installationDays: number,
): dayjs.Dayjs => {
  return startDate.subtract(installationDays, 'days');
};

export const getEntryTypeLabelByKey = (key: string): string | undefined => {
  const entryType = Object.values(ENTRY_TYPE).find(
    (entryType) => entryType.value === key,
  );
  return entryType?.label;
};

export const numberWithCommas = (value: number, keepDecimals = true) => {
  var parts = value.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return keepDecimals ? parts.join('.') : parts[0];
};

export const calculateDays = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  if (!startDate || !endDate) return 0;
  if (startDate.toDate() >= endDate.toDate()) return 0;
  return Math.ceil(
    (endDate.toDate().getTime() - startDate.toDate().getTime()) /
      (1000 * 3600 * 24),
  );
};

export const isPhoneNumber = (value: string, region: CountryCode) => {
  try {
    var phoneNum = parsePhoneNumberFromString(value, region);
    var result =
      phoneNum === null || phoneNum === void 0 ? void 0 : phoneNum.isValid();
    return !!result;
  } catch (error) {
    // logging?
    return false;
  }
};

export const undefinedIfEmpty = (value: string) => {
  return value === '' ? undefined : value;
};

export const isNotEmpty = (value: any) => {
  return value === !'' || (value !== undefined && value !== null);
};

export const hasPermission = (
  permissionsList: string[] | null | undefined,
  searchedPermissions: string[],
  allMatch: boolean = false,
): boolean => {
  if (!Array.isArray(permissionsList)) return false;

  const permissionsResolution = searchedPermissions.map((searched) =>
    permissionsList.includes(searched),
  );

  if (allMatch) {
    const someNotFound = permissionsResolution.find(
      (wasPermissionFound) => !wasPermissionFound,
    );

    return someNotFound !== undefined ? false : true;
  } else {
    const someFound = permissionsResolution.find(
      (wasPermissionFound) => wasPermissionFound,
    );

    return someFound !== undefined;
  }
};
