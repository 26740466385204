import React from 'react';
import { AreaDTO } from '@app-entities/Area';
import { UUID, DateString } from '@app-entities/common';
import dayjs from 'dayjs';
import { ServiceDTO } from '@app-entities/Service';
import { EntryType } from '@app-entities/EntryType';
import { EventType } from '@app-entities/EventType';
import { AreaAvailabilityDTO } from '@app-entities/AreasAvailability';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';

type QuoteCreateAreaAvailabilityMap = {
  [key: DateString]: Pick<
    AreaAvailabilityDTO,
    'available' | 'events' | 'used' | 'total'
  > & {
    day: dayjs.Dayjs;
  };
};

export type QuoteCreateSelectedServiceConfig = {
  data: ServiceDTO;
  is_included?: boolean;
  quantity?: number | null;
  days?: number | null;
};

export type QuoteCreateGenericServiceConfig = {
  id: UUID;
  category: ServiceCategoryDTO;
  name?: string | null;
  description?: string | null;
  quantity?: number | null;
  days?: number | null;
  price?: number | null;
  is_included?: boolean;
};

export type QuoteCreateSelectedServiceConfigMap = {
  [key: UUID]: QuoteCreateSelectedServiceConfig;
};

export type QuoteCreateSelectedAreaConfig = {
  data: AreaDTO;
  installation_days?: number | null;
  uninstallation_days?: number | null;
  installation_same_day?: boolean;
  focused_date?: DateString;
  availability_map?: QuoteCreateAreaAvailabilityMap;
  entry_type?: EntryType;
  event_type?: EventType;
  used_space?: number | null;
  selected_services?: QuoteCreateSelectedServiceConfigMap;
  generic_services?: QuoteCreateGenericServiceConfig[];
};

export type QuoteCreateSelectedAreasConfigMap = {
  [key: UUID]: QuoteCreateSelectedAreaConfig;
};

export interface QuoteCreateReducerState {
  start_date?: dayjs.Dayjs | null;
  end_date?: dayjs.Dayjs | null;
  selectedAreas?: QuoteCreateSelectedAreasConfigMap;
  current_stage?: number;
  event_discount_percentage?: number | null;
  installation_discount_percentage?: number | null;
  uninstallation_discount_percentage?: number | null;
}

export type QuoteCreateReducerAction =
  | QuoteCreateReducerDefaultAction
  | QuoteCreateReducerRemoveSelectedAreasAction
  | QuoteCreateReducerConfigureSelectedAreasAction
  | QuoteCreateReducerSetPreloadedDataAction;

export interface QuoteCreateReducerDefaultAction {
  type:
    | 'SET_START_DATE'
    | 'SET_END_DATE'
    | 'SET_EVENT_DISCOUNT_PERCENTAGE'
    | 'SET_INSTALLATION_DISCOUNT_PERCENTAGE'
    | 'SET_UNINSTALLATION_DISCOUNT_PERCENTAGE'
    | 'SET_SELECTED_AREAS'
    | 'SET_NEXT_STAGE'
    | 'SET_PREVIOUS_STAGE'
    | 'RESET_FORM'
    | null
    | undefined;
  payload: Partial<QuoteCreateReducerState>;
}

export interface QuoteCreateReducerRemoveSelectedAreasAction {
  type: 'REMOVE_SELECTED_AREA';
  payload: UUID;
}

export interface QuoteCreateReducerConfigureSelectedAreasAction {
  type: 'CONFIGURE_SELECTED_AREA';
  payload: QuoteCreateSelectedAreaConfig;
}

export interface QuoteCreateReducerSetPreloadedDataAction {
  type: 'SET_PRELOADED_DATA';
  payload: QuoteCreateReducerState;
}

export type QuoteCreateReducerDispatcher =
  React.Dispatch<QuoteCreateReducerAction>;

export type QuoteCreateReducer = (
  state: QuoteCreateReducerState,
  action: QuoteCreateReducerAction,
) => {};

export const quoteCreateReducer = (
  state: QuoteCreateReducerState,
  action: QuoteCreateReducerAction,
): QuoteCreateReducerState => {
  switch (action.type) {
    case 'SET_PRELOADED_DATA':
      return { ...state, ...action.payload };
    case 'SET_START_DATE':
      return { ...state, start_date: action.payload.start_date };
    case 'SET_END_DATE':
      return { ...state, end_date: action.payload.end_date };
    case 'SET_EVENT_DISCOUNT_PERCENTAGE':
      return {
        ...state,
        event_discount_percentage: action.payload.event_discount_percentage,
      };
    case 'SET_INSTALLATION_DISCOUNT_PERCENTAGE':
      return {
        ...state,
        installation_discount_percentage:
          action.payload.installation_discount_percentage,
      };
    case 'SET_UNINSTALLATION_DISCOUNT_PERCENTAGE':
      return {
        ...state,
        uninstallation_discount_percentage:
          action.payload.uninstallation_discount_percentage,
      };
    case 'SET_SELECTED_AREAS':
      return { ...state, selectedAreas: { ...action.payload.selectedAreas } };
    case 'REMOVE_SELECTED_AREA':
      const newState = { ...state, selectedAreas: { ...state.selectedAreas } };
      delete newState.selectedAreas[action.payload];
      return newState;
    case 'CONFIGURE_SELECTED_AREA':
      return {
        ...state,
        selectedAreas: {
          ...state.selectedAreas,
          [action.payload.data.id]: { ...action.payload },
        },
      };
    case 'SET_NEXT_STAGE':
      return { ...state, current_stage: state.current_stage! + 1 };
    case 'SET_PREVIOUS_STAGE':
      return { ...state, current_stage: state.current_stage! - 1 };
    case 'RESET_FORM':
      return { ...action.payload };
    default:
      return state;
  }
};
