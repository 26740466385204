import { ServiceDTO } from '@app-entities/Service';
import { FormInstance } from 'antd';
import { create } from 'zustand';

interface ServiceFormState {
  isFormOpen: boolean;
  currentService: ServiceDTO | null;
  form: FormInstance | null;
  openForm: (service?: ServiceDTO, values?: { [key: string]: any }) => void;
  setFormInstance: (form: FormInstance) => void;
  closeForm: () => void;
}

export const serviceFormStore = create<ServiceFormState>((set) => ({
  isFormOpen: false,
  form: null,
  currentService: null,
  openForm: (service) =>
    set((state) => {
      if (state.form) {
        state.form.resetFields();

        if (service) {
          state.form.setFieldsValue(service);
        }
      }

      return { currentService: service ?? null, isFormOpen: true };
    }),
  setFormInstance: (form) => set((state) => ({ form })),
  closeForm: () => set((state) => ({ isFormOpen: false })),
}));
