import { ChildrenProp, ClassnameProp } from '../types/generic';

const CardPanel = ({
  children,
  className = '',
}: ChildrenProp & ClassnameProp) => {
  return (
    <div className={`bg-white p-8 shadow-lg w-full ${className}`}>
      {children}
    </div>
  );
};

export default CardPanel;
