import React from 'react';
import { QuoteDTO } from '@app-entities/Quote';
import { HTTPFetchStateStatus } from '@app-entities/common';

export interface QuotesReducerState {
  status?: HTTPFetchStateStatus;
  data?: QuoteDTO[];
}
export interface QuotesReducerAction {
  type: string;
  payload: QuotesReducerState;
}
export type QuotesReducerDispatcher = React.Dispatch<QuotesReducerAction>;
export type QuotesReducer = (
  state: QuotesReducerState,
  action: QuotesReducerAction,
) => {};

export const quotesReducer = (
  state: QuotesReducerState,
  action: QuotesReducerAction,
): QuotesReducerState => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload.status };
    case 'SET_DATA':
      return { ...state, status: 'READY', data: action.payload.data };
    default:
      return state;
  }
};
