import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { QuoteDTO } from '@app-entities/Quote';
import { styles } from './';

const QuoteDetails = (quote: QuoteDTO) => {
  return (
    <View style={styles.row}>
      <table>
        <thead>
          <tr>
            <th style={{ ...styles.tableHeaderCell, textAlign: 'right' }}>
              <Text>DETALLES DE RESERVACION:</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>FECHA DE LA COTIZACION:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{dayjs(quote.created_at).format('DD/MM/YYYY')}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>ATENCION A:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{quote.atention_to}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>EMPRESA:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{quote.company}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>RESERVACION EN SISTEMA NUMERO:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>
                {quote.folio}-{quote.numbering}
              </Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>ASISTENTES CALCULADOS:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{quote.expected_attendees}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>NOMBRE DEL EVENTO:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{quote.event_name}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>FECHA DEL EVENTO:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{dayjs(quote.event_start_date).format('DD/MM/YYYY')}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>VENDEDOR:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
              }}
            >
              <Text>{quote.user.name}</Text>
            </td>
          </tr>
          <tr style={styles.infoTableBodyRow}>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellLeft,
                ...styles.tableFirstCell,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableLastRowCells,
              }}
            >
              <Text>SOLICITANTE:</Text>
            </td>
            <td
              style={{
                ...styles.tableBodyCell,
                ...styles.infoTableBodyCellRight,
                ...styles.tableBodySubHeaderCell,
                ...styles.tableLastRowCells,
              }}
            >
              <Text>{quote.atention_to}</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </View>
  );
};

export default QuoteDetails;
