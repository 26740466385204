import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { QuoteDTO } from '@app-entities/Quote';
import { QuoteAreaServiceDTO } from '@app-entities/QuoteAreaService';
import { styles } from './';
import { numberWithCommas } from '@app-utils/helpers';
import { UUID } from '@app-entities/common';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { QuoteAreaGenericServiceDTO } from '@app-entities/QuoteAreaGenericService';

const QuoteCategories = (quote: QuoteDTO) => {
  const servicesByCategory: {
    [key: UUID]: {
      category: Omit<
        ServiceCategoryDTO,
        'tax_percentage' | 'is_active' | 'created_at' | 'updated_at'
      >;
      services: QuoteAreaServiceDTO[];
      generic_services: QuoteAreaGenericServiceDTO[];
    };
  } = {};

  quote.areas.forEach((quoteArea) => {
    quoteArea.services.forEach((quoteAreaService) => {
      if (servicesByCategory.hasOwnProperty(quoteAreaService.category.id)) {
        servicesByCategory[quoteAreaService.category.id].services.push(
          quoteAreaService,
        );
      } else {
        servicesByCategory[quoteAreaService.category.id] = {
          category: quoteAreaService.category,
          services: [quoteAreaService],
          generic_services: [],
        };
      }
    });
    quoteArea.generic_services.forEach((quoteAreaGenericService) => {
      if (
        servicesByCategory.hasOwnProperty(quoteAreaGenericService.category.id)
      ) {
        servicesByCategory[
          quoteAreaGenericService.category.id
        ].generic_services.push(quoteAreaGenericService);
      } else {
        servicesByCategory[quoteAreaGenericService.category.id] = {
          category: quoteAreaGenericService.category,
          services: [],
          generic_services: [quoteAreaGenericService],
        };
      }
    });
  });

  return (
    <>
      {Object.values(servicesByCategory).map((serviceCategory) => {
        const servicesSubtotal = Object.values(serviceCategory.services).reduce(
          (previous, service) => {
            return previous + service.total;
          },
          0,
        );
        const genericServicesSubtotal = Object.values(
          serviceCategory.generic_services,
        ).reduce((previous, genericService) => {
          return previous + genericService.total;
        }, 0);
        const subtotal = servicesSubtotal + genericServicesSubtotal;
        const categoryTaxPercentage = serviceCategory.services.length
          ? serviceCategory.services[0].category_tax_percentage
          : serviceCategory.generic_services[0].category_tax_percentage;
        const iva = subtotal * (categoryTaxPercentage / 100);

        return (
          <View
            style={{ ...styles.row, marginTop: '7pt' }}
            key={serviceCategory.category.id}
          >
            <table>
              <thead>
                <tr>
                  <th style={styles.tableHeaderCell}>
                    <Text>{serviceCategory.category.name}</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    ...styles.infoTableBodyRow,
                  }}
                >
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableFirstCell,
                      ...styles.tableBodyCellConceptColumn,
                      ...styles.tableBodySubHeaderCell,
                    }}
                  >
                    <Text>
                      {serviceCategory.category.invoice_concept_label ??
                        'Concepto'}
                    </Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      width: '147pt',
                    }}
                  >
                    <Text>
                      {serviceCategory.category.invoice_description_label ??
                        'Descripción'}
                    </Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      width: '35pt',
                    }}
                  >
                    <Text>
                      {serviceCategory.category.invoice_quantity_label ??
                        'Cantidad'}
                    </Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      width: '65pt',
                    }}
                  >
                    <Text>
                      {serviceCategory.category.invoice_pricing_label ??
                        'Precio por unidad'}
                    </Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      width: '78pt',
                    }}
                  >
                    <Text>Días</Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodyCellTotalColumn,
                      ...styles.tableBodySubHeaderCell,
                    }}
                  >
                    <Text>Total</Text>
                  </td>
                </tr>
                {serviceCategory.services.map((service, index) => (
                  <tr
                    style={{
                      ...styles.infoTableBodyRow,
                    }}
                    key={index}
                  >
                    <td
                      style={{
                        ...styles.tableBodyCell,
                        ...styles.tableFirstCell,
                        ...styles.tableBodyCellConceptColumn,
                      }}
                    >
                      <Text>{service.name}</Text>
                    </td>
                    <td
                      style={{
                        ...styles.tableBodyCell,
                        width: '147pt',
                      }}
                    >
                      <Text>{service.description}</Text>
                    </td>
                    <td
                      style={{
                        ...styles.tableBodyCell,
                        width: '35pt',
                      }}
                    >
                      <Text>{service.quantity}</Text>
                    </td>
                    <td
                      style={{
                        ...styles.tableBodyCell,
                        width: '65pt',
                      }}
                    >
                      <Text>
                        $
                        {numberWithCommas(
                          service.is_included ? 0 : service.price,
                        )}
                      </Text>
                    </td>
                    <td
                      style={{
                        ...styles.tableBodyCell,
                        width: '78pt',
                      }}
                    >
                      <Text>{service.days}</Text>
                    </td>
                    <td
                      style={{
                        ...styles.tableBodyCell,
                        ...styles.tableBodyCellTotalColumn,
                      }}
                    >
                      <Text>{numberWithCommas(service.total)}</Text>
                    </td>
                  </tr>
                ))}
                {serviceCategory.generic_services.map(
                  (genericService, index) => (
                    <tr
                      style={{
                        ...styles.infoTableBodyRow,
                      }}
                      key={index}
                    >
                      <td
                        style={{
                          ...styles.tableBodyCell,
                          ...styles.tableFirstCell,
                          ...styles.tableBodyCellConceptColumn,
                        }}
                      >
                        <Text>{genericService.name}</Text>
                      </td>
                      <td
                        style={{
                          ...styles.tableBodyCell,
                          width: '147pt',
                        }}
                      >
                        <Text>{genericService.description}</Text>
                      </td>
                      <td
                        style={{
                          ...styles.tableBodyCell,
                          width: '35pt',
                        }}
                      >
                        <Text>{genericService.quantity}</Text>
                      </td>
                      <td
                        style={{
                          ...styles.tableBodyCell,
                          width: '65pt',
                        }}
                      >
                        <Text>
                          $
                          {numberWithCommas(
                            genericService.is_included
                              ? 0
                              : genericService.price,
                          )}
                        </Text>
                      </td>
                      <td
                        style={{
                          ...styles.tableBodyCell,
                          width: '78pt',
                        }}
                      >
                        <Text>{genericService.days}</Text>
                      </td>
                      <td
                        style={{
                          ...styles.tableBodyCell,
                          ...styles.tableBodyCellTotalColumn,
                        }}
                      >
                        <Text>{numberWithCommas(genericService.total)}</Text>
                      </td>
                    </tr>
                  ),
                )}
                <tr
                  style={{
                    ...styles.infoTableBodyRow,
                  }}
                >
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodyCellSubtotalIndent,
                      borderTop: '1px',
                    }}
                  ></td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      ...styles.tableBodyCellSubtotalLabel,
                    }}
                  >
                    <Text>Subtotal</Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      ...styles.tableBodyCellTotalColumn,
                    }}
                  >
                    <Text>${numberWithCommas(subtotal)}</Text>
                  </td>
                </tr>
                <tr
                  style={{
                    ...styles.infoTableBodyRow,
                  }}
                >
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodyCellSubtotalIndent,
                    }}
                  ></td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      ...styles.tableBodyCellSubtotalLabel,
                    }}
                  >
                    <Text>IVA {categoryTaxPercentage}%</Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      ...styles.tableBodyCellTotalColumn,
                    }}
                  >
                    <Text>${numberWithCommas(iva)}</Text>
                  </td>
                </tr>
                <tr
                  style={{
                    ...styles.infoTableBodyRow,
                  }}
                >
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodyCellSubtotalIndent,
                    }}
                  ></td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      ...styles.tableBodyCellSubtotalLabel,
                      ...styles.tableLastRowCells,
                    }}
                  >
                    <Text>Total</Text>
                  </td>
                  <td
                    style={{
                      ...styles.tableBodyCell,
                      ...styles.tableBodySubHeaderCell,
                      ...styles.tableBodyCellTotalColumn,
                      ...styles.tableLastRowCells,
                    }}
                  >
                    <Text>${numberWithCommas(subtotal + iva)}</Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </View>
        );
      })}
    </>
  );
};

export default QuoteCategories;
