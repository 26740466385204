import MainLayout from '../../components/Layout/MainLayout';

const Error404Page = () => {
  return (
    <MainLayout>
      <div className=" text-red-800 font-bold text-xl text-center w-96 mx-auto mt-40">
        404 - Página no encontrada
      </div>
    </MainLayout>
  );
};

export default Error404Page;
