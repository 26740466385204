import { HTTPFetchStateStatus } from '@app-entities/common';

export const EVENT_TYPES = {
  EXPO: {
    value: 'EXPO',
    label: 'Exposición',
  },
  CONGRESS: {
    value: 'CONGRESS',
    label: 'Congreso',
  },
};

export const PRICING_TYPES = {
  FIXED: {
    value: 'FIXED',
    label: 'Fijo',
  },
  DYNAMIC: {
    value: 'DYNAMIC',
    label: 'Dinámico',
  },
};

export const PRICING_TYPE = {
  FIXED: 'FIXED',
  DYNAMIC: 'DYNAMIC',
};

export const ENTRY_TYPE = {
  NORMAL: {
    value: 'NORMAL',
    label: 'Normal',
  },
  NIGHT: {
    value: 'NIGHT',
    label: 'Nocturna',
  },
};

export const FETCH_FINISHED_STATUS: HTTPFetchStateStatus[] = ['READY', 'ERROR'];

export const GENERAL_IVA: number = 0.08;

export const NIGHT_ENTRY_PRICE: number = 44;

export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const PHONE_REGEX =
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const PERMISSIONS = {
  user: {
    label: 'Usuarios',
    options: {
      // create: {
      //   value: 'USER_CREATE',
      //   label: 'Crear',
      // },
      // update: {
      //   value: 'USER_UPDATE',
      //   label: 'Actualizar',
      // },
      // view: {
      //   value: 'USER_VIEW',
      //   label: 'Ver',
      // },
      // delete: {
      //   value: 'USER_DELETE',
      //   label: 'Eliminar',
      // },
      admin: {
        value: 'USER_ADMIN',
        label: 'Administrar',
      },
    },
  },
  serviceCategory: {
    label: 'Categorías',
    options: {
      create: {
        value: 'SERVICE_CATEGORY_CREATE',
        label: 'Crear',
      },
      update: {
        value: 'SERVICE_CATEGORY_UPDATE',
        label: 'Actualizar',
      },
      view: {
        value: 'SERVICE_CATEGORY_VIEW',
        label: 'Ver',
      },
      delete: {
        value: 'SERVICE_CATEGORY_DELETE',
        label: 'Eliminar',
      },
    },
  },
  service: {
    label: 'Servicios',
    options: {
      create: {
        value: 'SERVICE_CREATE',
        label: 'Crear',
      },
      update: {
        value: 'SERVICE_UPDATE',
        label: 'Actualizar',
      },
      view: {
        value: 'SERVICE_VIEW',
        label: 'Ver',
      },
      delete: {
        value: 'SERVICE_DELETE',
        label: 'Eliminar',
      },
    },
  },
  areaType: {
    label: 'Clasificaciónes de área',
    options: {
      create: {
        value: 'AREA_TYPE_CREATE',
        label: 'Crear',
      },
      update: {
        value: 'AREA_TYPE_UPDATE',
        label: 'Actualizar',
      },
      view: {
        value: 'AREA_TYPE_VIEW',
        label: 'Ver',
      },
      delete: {
        value: 'AREA_TYPE_DELETE',
        label: 'Eliminar',
      },
    },
  },
  area: {
    label: 'Áreas',
    options: {
      create: {
        value: 'AREA_CREATE',
        label: 'Crear',
      },
      update: {
        value: 'AREA_UPDATE',
        label: 'Actualizar',
      },
      view: {
        value: 'AREA_VIEW',
        label: 'Ver',
      },
      delete: {
        value: 'AREA_DELETE',
        label: 'Eliminar',
      },
    },
  },
  quote: {
    label: 'Cotizaciones',
    options: {
      create: {
        value: 'QUOTE_CREATE',
        label: 'Crear',
      },
      updateSelf: {
        value: 'QUOTE_UPDATE_SELF',
        label: 'Actualizar propias',
      },
      update: {
        value: 'QUOTE_UPDATE',
        label: 'Actualizar',
      },
      viewSelf: {
        value: 'QUOTE_VIEW_SELF',
        label: 'Ver propias',
      },
      view: {
        value: 'QUOTE_VIEW',
        label: 'Ver',
      },
      delete: {
        value: 'QUOTE_DELETE',
        label: 'Eliminar',
      },
      approve: {
        value: 'QUOTE_APPROVE',
        label: 'Aprobar',
      },
    },
  },
};
