const DashboardIcon = () => (
  <svg
    id="menu_dashboard"
    data-name="menu dashboard"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    viewBox="0 0 25.236 21.187"
  >
    <path
      id="Trazado_7543"
      data-name="Trazado 7543"
      d="M2725.98-7884.875a3.479,3.479,0,0,0,3.476-3.476,3.445,3.445,0,0,0-3.476-3.452,3.445,3.445,0,0,0-3.452,3.477A3.439,3.439,0,0,0,2725.98-7884.875Zm0-6.165a2.713,2.713,0,0,1,2.714,2.714,2.692,2.692,0,0,1-2.714,2.689,2.712,2.712,0,0,1-2.714-2.714A2.707,2.707,0,0,1,2725.98-7891.04Z"
      transform="translate(-2705.006 7891.803)"
      fill="#ef9751"
    />
    <path
      id="Trazado_7544"
      data-name="Trazado 7544"
      d="M2733.413-7882.382a.449.449,0,0,0,.167.047h0c.048.023.119.023.19.047a.514.514,0,0,0,.191.024h.047v.476h.571v-.5h.024a.852.852,0,0,0,.214-.095.841.841,0,0,0,.191-.12h0a1.023,1.023,0,0,0,.143-.166,1.426,1.426,0,0,0,.119-.19l.071-.214h0a.739.739,0,0,0,.024-.238h0a.524.524,0,0,0-.024-.19h0a.629.629,0,0,0-.071-.191.611.611,0,0,0-.1-.167h0c-.048-.047-.072-.1-.119-.143a.426.426,0,0,0-.167-.119.589.589,0,0,0-.167-.1c-.071-.023-.119-.071-.19-.095a1.727,1.727,0,0,1-.19-.071h0a.185.185,0,0,1-.1-.048c-.024-.024-.072-.024-.095-.047h0c-.024-.024-.072-.024-.1-.048h0l-.071-.071h0a.123.123,0,0,1-.048-.1.142.142,0,0,1-.024-.1v-.119c0-.024.024-.071.024-.1h0c.024-.024.024-.072.048-.1l.071-.071a.179.179,0,0,1,.119-.048h0a.214.214,0,0,1,.119-.024h.143a.212.212,0,0,1,.119.024,1.038,1.038,0,0,1,.119.023h0c.023,0,.071.024.1.024h0c.047,0,.071.024.095.024l.119.047.024-.143.047-.38v-.1l-.095-.023c-.024,0-.072-.023-.1-.023h0c-.024,0-.048-.024-.095-.024a1.071,1.071,0,0,0-.119-.023c-.048,0-.072-.024-.119-.024h-.024v-.452h-.572v.477h-.024a.846.846,0,0,0-.214.094h0a.428.428,0,0,0-.167.12h0a1,1,0,0,0-.143.167.471.471,0,0,0-.1.19l-.072.214a.814.814,0,0,0-.024.238h0a.525.525,0,0,0,.024.19.609.609,0,0,0,.072.19h0c.024.048.072.119.1.167h0c.047.047.072.1.119.143h0a.429.429,0,0,0,.167.119.585.585,0,0,0,.167.1,1.41,1.41,0,0,1,.191.1h0a1.574,1.574,0,0,1,.19.072h0a.18.18,0,0,1,.1.047c.024.024.071.024.1.047s.048.048.072.048l.071.072c.024.023.024.047.048.095h0a1.033,1.033,0,0,1,.024.119h0v.119a.181.181,0,0,1-.048.1h0l-.071.071h0a.327.327,0,0,1-.1.071h0c-.024.024-.072.024-.1.048h0a1.035,1.035,0,0,1-.119.024h-.309a1.041,1.041,0,0,1-.119-.024.335.335,0,0,1-.142-.048c-.048-.024-.1-.024-.143-.047-.024,0-.048-.024-.072-.024s-.047-.024-.071-.024l-.143-.071-.024.167-.048.428v.072l.072.024A.316.316,0,0,1,2733.413-7882.382Z"
      transform="translate(-2713.271 7887.501)"
      fill="#ef9751"
    />
    <path
      id="Trazado_7545"
      data-name="Trazado 7545"
      d="M2661.826-7853.319a.383.383,0,0,0,.214-.072l5.166-3.689,5,2.07a.427.427,0,0,0,.381-.047l4.023-3.19a.406.406,0,0,0,.071-.548.405.405,0,0,0-.547-.071l-3.857,3.071-4.975-2.071a.379.379,0,0,0-.357.047l-5.333,3.809a.38.38,0,0,0-.1.523A.347.347,0,0,0,2661.826-7853.319Z"
      transform="translate(-2657.373 7866.174)"
      fill="#ef9751"
    />
    <path
      id="Trazado_7546"
      data-name="Trazado 7546"
      d="M2667.81-7839.413h-1.762v-11.021a.376.376,0,0,0-.381-.381h-3.5a.376.376,0,0,0-.381.381v11.021H2660.5v-7.213a.376.376,0,0,0-.381-.381H2656.6a.376.376,0,0,0-.381.381v7.213h-1.285v-7.784a.376.376,0,0,0-.381-.38h-3.5a.375.375,0,0,0-.381.38v7.784h-1.286v-5.546a.376.376,0,0,0-.381-.381h-3.523a.376.376,0,0,0-.381.381v5.546h-1.785a.376.376,0,0,0-.381.382.376.376,0,0,0,.381.381h24.472a.376.376,0,0,0,.381-.381A.344.344,0,0,0,2667.81-7839.413Zm-5.261-10.641h2.738v10.641h-2.738Zm-5.571,3.809h2.738v6.832h-2.738Zm-5.547-.571h2.738v7.4h-2.738Zm-5.571,2.238h2.738v5.165h-2.738Z"
      transform="translate(-2642.933 7859.837)"
      fill="#ef9751"
    />
  </svg>
);

export default DashboardIcon;
