import React, { useEffect, useReducer } from 'react';
import { Button, Popconfirm, Table, message } from 'antd';
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { deleteUser, fetchUsers } from '@app-actions/users';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import MainLayout from '@app-components/Layout/MainLayout';
import { usersReducer } from '@app-reducers/users';
import { FETCH_FINISHED_STATUS } from '@app-utils/constants';
import ContentLoader from '@app-components/Layout/ContentLoader';
import { H2Title } from '@app-components/Layout/Text';
import { UserDTO } from '@app-entities/User';
import UserCreateUpdateModal from './UserCreateUpdateModal';
import { userFormStore } from '../../hooks/user-form-store';
import { UUID } from '@app-entities/common';
import { withAuthentication } from '@app-components/HOCs/withAuthentication';

const { Column } = Table;

const UsersListPage = () => {
  const openUserForm = userFormStore((state) => state.openForm);
  const [users, usersDispatch] = useReducer(usersReducer, {});

  useEffect(() => {
    (async () => {
      fetchUsers(usersDispatch);
    })();
  }, []);

  const usersFetchFinished = FETCH_FINISHED_STATUS.includes(
    users.status ?? 'LOADING',
  );

  const handleDeleteUser = async (id: UUID) => {
    try {
      await deleteUser(id);
      fetchUsers(usersDispatch);
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }
  };

  return (
    <>
      <UserCreateUpdateModal usersDispatch={usersDispatch} />
      <MainLayout>
        <CenteredContainer width="full">
          <CardPanel>
            <div className="border-b-2 py-5">
              <div className="grid grid-cols-2">
                <div className="col-span-1">{<H2Title>Usuarios</H2Title>}</div>
                <div className="col-span-1">
                  <Button
                    onClick={() => openUserForm()}
                    type="primary"
                    className="block float-right"
                    icon={<PlusCircleOutlined />}
                  >
                    Nuevo usuario
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-6">
              {!usersFetchFinished ? (
                <ContentLoader />
              ) : (
                <Table
                  dataSource={(users.data ?? []).map(
                    (quote, index): UserDTO & { key: number } => ({
                      ...quote,
                      key: index,
                    }),
                  )}
                >
                  <Column title="Nombre" dataIndex="name" key="name" />
                  <Column title="Correo" dataIndex="email" key="email" />
                  <Column
                    title="Activo"
                    dataIndex="is_active"
                    key="is_active"
                    render={(value: boolean) => (value ? 'Si' : 'No')}
                  />
                  <Column
                    title="Acciones"
                    dataIndex="actions"
                    key="actions"
                    render={(value, user: UserDTO) => (
                      <div className="clear-both">
                        <button
                          className="float-left mr-2"
                          onClick={() => {
                            const { password, ...isolatedUserData } = user;
                            openUserForm(isolatedUserData);
                          }}
                        >
                          <EditOutlined className="text-xl" />
                        </button>
                        <Popconfirm
                          title="Eliminar usuario"
                          onConfirm={() => handleDeleteUser(user.id)}
                          okText="Si"
                          cancelText="No"
                        >
                          <button className="float-left mr-2">
                            <DeleteOutlined className="text-xl" />
                          </button>
                        </Popconfirm>
                      </div>
                    )}
                  />
                </Table>
              )}
            </div>
          </CardPanel>
        </CenteredContainer>
      </MainLayout>
    </>
  );
};

export default withAuthentication(UsersListPage);
