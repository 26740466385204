import DashboardPage from '../pages/Dashboard';
import { RouteObject } from 'react-router';
import Error404Page from '../pages/Error/Error404';
import QuotesCreatePage from '../pages/Quotes/Create';
import QuotesListPage from '@app-pages/Quotes/List';
import QuotesCalendarPage from '@app-pages/Quotes/Calendar';
import ServicesListPage from '@app-pages/Services/List';
import AreasListPage from '@app-pages/Areas/List';
import UsersListPage from '@app-pages/Users/List';
import LoginPage from '@app-pages/Login';

const routes: RouteObject[] = [
  {
    errorElement: <Error404Page />,
  },
  {
    path: '/',
    element: <DashboardPage />,
  },
  {
    path: '/cotizador',
    element: <QuotesCreatePage />,
  },
  {
    path: '/cotizaciones',
    element: <QuotesListPage activeQuotes={false} archivedQuotes={false} />,
  },
  {
    path: '/cotizaciones/:id',
    element: <QuotesCreatePage />,
  },
  {
    path: '/cotizaciones/cerradas',
    element: <QuotesListPage activeQuotes={true} archivedQuotes={false} />,
  },
  {
    path: '/cotizaciones/archivadas',
    element: <QuotesListPage activeQuotes={false} archivedQuotes={true} />,
  },
  {
    path: '/cotizaciones/calendario',
    element: <QuotesCalendarPage />,
  },
  {
    path: '/servicios',
    element: <ServicesListPage />,
  },
  {
    path: '/servicios/:id',
    element: <ServicesListPage />,
  },
  {
    path: '/areas',
    element: <AreasListPage />,
  },
  {
    path: '/areas/:id',
    element: <AreasListPage />,
  },
  {
    path: '/usuarios',
    element: <UsersListPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
];

export default routes;
