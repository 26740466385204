import { AreaTypeDTO } from '@app-entities/AreaType';
import { FormInstance } from 'antd';
import { create } from 'zustand';

interface AreaTypeFormState {
  isFormOpen: boolean;
  currentAreaType: AreaTypeDTO | null;
  form: FormInstance | null;
  openForm: (areaType?: AreaTypeDTO) => void;
  setFormInstance: (form: FormInstance) => void;
  closeForm: () => void;
}

export const areaTypeFormStore = create<AreaTypeFormState>((set) => ({
  isFormOpen: false,
  form: null,
  currentAreaType: null,
  openForm: (areaType) =>
    set((state) => {
      if (state.form) {
        state.form.resetFields();

        if (areaType) {
          state.form.setFieldsValue(areaType);
        }
      }

      return { currentAreaType: areaType ?? null, isFormOpen: true };
    }),
  setFormInstance: (form) => set((state) => ({ form })),
  closeForm: () => set((state) => ({ isFormOpen: false })),
}));
