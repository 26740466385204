import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSession } from 'src/hooks/user-session';

export const withAuthentication = (GuardedComponent: React.FC<any>) => {
  const Authenticated = (props: any) => {
    const { isAuthenticated, isResolved } = useUserSession();
    const navigator = useNavigate();

    useEffect(() => {
      if (isResolved && !isAuthenticated) {
        navigator('/login');
      }
    }, [isResolved, isAuthenticated]);

    return !isAuthenticated ? <></> : <GuardedComponent {...props} />;
  };

  return Authenticated;
};
