import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import UserForm from './UserForm';
import { userFormStore } from '../../hooks/user-form-store';
import { createUser, fetchUsers, updateUser } from '@app-actions/users';
import { CreateUserDTO } from '@app-entities/CreateUser';
import { UsersReducerDispatcher } from '@app-reducers/users';
import { isNotEmpty } from '@app-utils/helpers';

const UserCreateUpdateModal = ({
  usersDispatch,
}: {
  usersDispatch: UsersReducerDispatcher;
}) => {
  const [createFormInstance] = Form.useForm();
  const isOpen = userFormStore((state) => state.isFormOpen);
  const form = userFormStore((state) => state.form);
  const currentUser = userFormStore((state) => state.currentUser);
  const closeForm = userFormStore((state) => state.closeForm);
  const setFormInstance = userFormStore((state) => state.setFormInstance);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  useEffect(() => {
    setFormInstance(createFormInstance);
  }, []);

  const handleOk = async () => {
    console.log(form?.getFieldsValue());
    setIsCreateLoading(true);
    try {
      await form?.validateFields();
      const password = form?.getFieldValue('password');
      const userData: CreateUserDTO = {
        name: form?.getFieldValue('name'),
        email: form?.getFieldValue('email'),
        password: isNotEmpty(password) ? password : undefined,
        permissions: form?.getFieldValue('permissions') ?? [],
        is_active: form?.getFieldValue('is_active'),
      };

      if (!currentUser) {
        await createUser(userData);
      } else {
        await updateUser(currentUser.id, userData);
      }

      closeForm();
      fetchUsers(usersDispatch);
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }

    setIsCreateLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      confirmLoading={isCreateLoading}
      onCancel={closeForm}
    >
      <UserForm currentUser={currentUser} />
    </Modal>
  );
};

export default UserCreateUpdateModal;
