import { UserDTO } from '@app-entities/User';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const useUserSession = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isResolved, setIsResolved] = useState<boolean>(false);
  const [user, setUser] = useState<UserDTO | null>(null);

  useEffect(() => {
    const cookieData = Cookies.get('bcc_session');
    let user: UserDTO | null = null;

    try {
      user = cookieData ? JSON.parse(cookieData) : null;
    } catch (e) {}

    if (user) {
      setUser(user);
      setIsAuthenticated(true);
    }

    setIsResolved(true);
  }, []);

  return {
    isAuthenticated,
    isResolved,
    user,
  };
};
