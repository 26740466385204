import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { create } from 'zustand';

interface CategoryInfoState {
  isModalOpen: boolean;
  currentCategory: ServiceCategoryDTO | null;
  openModal: (category: ServiceCategoryDTO) => void;
  closeModal: () => void;
}

export const categoryInfoStore = create<CategoryInfoState>((set) => ({
  isModalOpen: false,
  currentCategory: null,
  openModal: (category) =>
    set((state) => {
      return { currentCategory: category, isModalOpen: true };
    }),
  closeModal: () => set((state) => ({ isModalOpen: false })),
}));
