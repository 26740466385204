import React, { useEffect, useReducer, useState } from 'react';
import { Button, Popconfirm, Table, message } from 'antd';
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { fetchAreaTypes } from '@app-actions/area-types';
import { deleteArea, fetchAreas } from '@app-actions/areas';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import MainLayout from '@app-components/Layout/MainLayout';
import { areaTypesReducer } from '@app-reducers/area-types';
import { areasReducer } from '@app-reducers/areas';
import {
  FETCH_FINISHED_STATUS,
  PERMISSIONS,
  PRICING_TYPE,
  PRICING_TYPES,
} from '@app-utils/constants';
import ContentLoader from '@app-components/Layout/ContentLoader';
import { H2Title } from '@app-components/Layout/Text';
import { AreaTypeDTO } from '@app-entities/AreaType';
import { AreaDTO } from '@app-entities/Area';
import AreaCreateUpdateModal from './AreaCreateUpdateModal';
import { areaFormStore } from '../../hooks/area-form-store';
import { UUID } from '@app-entities/common';
import AreaTypeDetailsModal from './AreaTypeDetailsModal';
import { areaTypeInfoStore } from '../../hooks/area-type-info-store';
import { areaTypeFormStore } from '../../hooks/area-type-form-store';
import AreaTypeCreateUpdateModal from './AreaTypeCreateUpdateModal';
import { hasPermission, numberWithCommas } from '@app-utils/helpers';
import dayjs from '@app-utils/extendedDaysJs';
import { withAuthentication } from '@app-components/HOCs/withAuthentication';
import { useUserSession } from 'src/hooks/user-session';

const { Column } = Table;

const AreasListPage = () => {
  const { user } = useUserSession();
  const openAreaTypeModal = areaTypeInfoStore((state) => state.openModal);
  const openAreaForm = areaFormStore((state) => state.openForm);
  const openAreaTypeForm = areaTypeFormStore((state) => state.openForm);
  const [areaTypes, areaTypesDispatch] = useReducer(areaTypesReducer, {});
  const [selectedAreaType, setSelectedAreaType] = useState<AreaTypeDTO | null>(
    null,
  );
  const [areas, areasDispatch] = useReducer(areasReducer, {});
  const { id: areaTypeId } = useParams();

  useEffect(() => {
    (async () => {
      fetchAreaTypes(areaTypesDispatch);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedAreaType) {
        fetchAreas(areasDispatch, selectedAreaType.id);
      }
    })();
  }, [selectedAreaType]);

  const areaTypesFetchFinished = FETCH_FINISHED_STATUS.includes(
    areaTypes.status ?? 'LOADING',
  );
  const areasFetchFinished = FETCH_FINISHED_STATUS.includes(
    areas.status ?? 'LOADING',
  );

  useEffect(() => {
    (async () => {
      if (areaTypesFetchFinished) {
        const foundAreaType = areaTypes.data?.find(
          (areaType) => areaType.id === areaTypeId,
        );

        if (foundAreaType) {
          setSelectedAreaType(foundAreaType);
        } else {
          const firstAreaType = areaTypes.data?.find((areaType) => areaType);
          setSelectedAreaType(firstAreaType ?? null);
        }
      }
    })();
  }, [areaTypes.status, areaTypeId]);

  const handleDeleteArea = async (id: UUID) => {
    try {
      await deleteArea(id);
      fetchAreas(areasDispatch, selectedAreaType!.id);
    } catch (e) {
      if (e.message) {
        message.error(e.message);
      }
    }
  };

  const currentYear: string = dayjs().format('YYYY');
  const nextYear: string = dayjs().add(1, 'year').format('YYYY');

  const updateAreasPermission = hasPermission(user?.permissions, [
    PERMISSIONS.area.options.update.value,
  ]);

  const deleteAreasPermission = hasPermission(user?.permissions, [
    PERMISSIONS.area.options.delete.value,
  ]);

  return (
    <>
      {selectedAreaType && (
        <>
          <AreaCreateUpdateModal
            areaType={selectedAreaType}
            areasDispatch={areasDispatch}
          />
          <AreaTypeDetailsModal areaTypesDispatch={areaTypesDispatch} />
          <AreaTypeCreateUpdateModal
            areaTypesDispatch={areaTypesDispatch}
            setSelectedAreaType={setSelectedAreaType}
          />
        </>
      )}
      <MainLayout>
        <CenteredContainer width="full">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-1">
              <CardPanel>
                <H2Title>Clasificaciones</H2Title>
                {!areaTypesFetchFinished ? (
                  <ContentLoader />
                ) : (
                  <ul className="mt-2">
                    {areaTypes.data &&
                      areaTypes.data.map((areaType) => (
                        <li
                          className={`w-full  block font-bold shadow-md mb-2 text-xs ${
                            selectedAreaType &&
                            areaType.id === selectedAreaType.id
                              ? 'bg-orange-600 text-white'
                              : 'bg-white'
                          }`}
                          key={areaType.id}
                        >
                          <Link
                            to={`/areas/${areaType.id}`}
                            className="p-4 block w-full"
                          >
                            {areaType.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
                {hasPermission(user?.permissions, [
                  PERMISSIONS.areaType.options.create.value,
                ]) && (
                  <Button
                    onClick={() => openAreaTypeForm()}
                    type="primary"
                    className="block mt-4 shadow-md rounded-3xl"
                    icon={<PlusCircleOutlined />}
                  >
                    Nueva clasificación
                  </Button>
                )}
              </CardPanel>
            </div>
            <div className="col-span-5">
              <CardPanel>
                {!areaTypesFetchFinished ? (
                  <ContentLoader />
                ) : (
                  selectedAreaType && (
                    <>
                      <div className="border-b-2 py-5">
                        <div className="grid grid-cols-2">
                          <div className="col-span-1">
                            {<H2Title>{selectedAreaType.name}</H2Title>}
                            {hasPermission(user?.permissions, [
                              PERMISSIONS.areaType.options.view.value,
                            ]) && (
                              <Button
                                type="ghost"
                                className="mt-2 block shadow-md rounded-3xl"
                                onClick={() =>
                                  openAreaTypeModal(selectedAreaType)
                                }
                              >
                                <InfoCircleOutlined />
                              </Button>
                            )}
                          </div>
                          <div className="col-span-1">
                            {hasPermission(user?.permissions, [
                              PERMISSIONS.area.options.create.value,
                            ]) && (
                              <Button
                                onClick={() => openAreaForm()}
                                type="primary"
                                className="block float-right"
                                icon={<PlusCircleOutlined />}
                              >
                                Nueva área
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        {!areasFetchFinished ? (
                          <ContentLoader />
                        ) : (
                          hasPermission(user?.permissions, [
                            PERMISSIONS.area.options.view.value,
                          ]) && (
                            <Table
                              dataSource={(areas.data ?? []).map(
                                (quote, index): AreaDTO & { key: number } => ({
                                  ...quote,
                                  key: index,
                                }),
                              )}
                            >
                              <Column
                                title="Nombre"
                                dataIndex="name"
                                key="name"
                              />
                              <Column
                                title="Área"
                                dataIndex="total_area"
                                key="total_area"
                                render={(value, record, index) =>
                                  numberWithCommas(value)
                                }
                              />
                              <Column
                                title="Tipo de precio"
                                dataIndex="pricing_type"
                                key="pricing_type"
                                render={(value, record, index) =>
                                  Object.values(PRICING_TYPES).find(
                                    (type) => type.value === value,
                                  )?.label
                                }
                              />
                              <Column
                                title={
                                  <>
                                    P. Fijo{' '}
                                    <span className="text-xs">
                                      ({currentYear})
                                    </span>
                                  </>
                                }
                                dataIndex="fixed_price"
                                key="fixed_price"
                                render={(value, record: AreaDTO, index) =>
                                  record.pricing_type === PRICING_TYPE.FIXED
                                    ? value
                                      ? `$${numberWithCommas(value)}`
                                      : ''
                                    : '-'
                                }
                              />
                              <Column
                                title={
                                  <>
                                    P. Fijo{' '}
                                    <span className="text-xs">
                                      ({nextYear})
                                    </span>
                                  </>
                                }
                                dataIndex="next_fixed_price"
                                key="next_fixed_price"
                                render={(value, record: AreaDTO, index) =>
                                  record.pricing_type === PRICING_TYPE.FIXED
                                    ? value
                                      ? `$${numberWithCommas(value)}`
                                      : ''
                                    : '-'
                                }
                              />
                              <Column
                                title={
                                  <>
                                    P. Expo{' '}
                                    <span className="text-xs">
                                      ({currentYear})
                                    </span>
                                  </>
                                }
                                dataIndex="expo_price"
                                key="expo_price"
                                render={(value, record: AreaDTO, index) =>
                                  record.pricing_type === PRICING_TYPE.DYNAMIC
                                    ? value
                                      ? `$${numberWithCommas(value)}`
                                      : ''
                                    : '-'
                                }
                              />
                              <Column
                                title={
                                  <>
                                    P. Expo{' '}
                                    <span className="text-xs">
                                      ({nextYear})
                                    </span>
                                  </>
                                }
                                dataIndex="next_expo_price"
                                key="next_expo_price"
                                render={(value, record: AreaDTO, index) =>
                                  record.pricing_type === PRICING_TYPE.DYNAMIC
                                    ? value
                                      ? `$${numberWithCommas(value)}`
                                      : ''
                                    : '-'
                                }
                              />
                              <Column
                                title={
                                  <>
                                    P. Congr.{' '}
                                    <span className="text-xs">
                                      ({currentYear})
                                    </span>
                                  </>
                                }
                                dataIndex="congress_price"
                                key="congress_price"
                                render={(value, record: AreaDTO, index) =>
                                  record.pricing_type === PRICING_TYPE.DYNAMIC
                                    ? value
                                      ? `$${numberWithCommas(value)}`
                                      : ''
                                    : '-'
                                }
                              />
                              <Column
                                title={
                                  <>
                                    P. Congr.{' '}
                                    <span className="text-xs">
                                      ({nextYear})
                                    </span>
                                  </>
                                }
                                dataIndex="next_congress_price"
                                key="next_congress_price"
                                render={(value, record: AreaDTO, index) =>
                                  record.pricing_type === PRICING_TYPE.DYNAMIC
                                    ? value
                                      ? `$${numberWithCommas(value)}`
                                      : ''
                                    : '-'
                                }
                              />
                              <Column
                                title="Cotizar completo"
                                dataIndex="only_full_area"
                                key="only_full_area"
                                render={(value, record: AreaDTO, index) =>
                                  value ? 'Si' : 'No'
                                }
                              />
                              <Column
                                title="Acciones"
                                dataIndex="actions"
                                key="actions"
                                render={(value, area: AreaDTO) => (
                                  <div className="clear-both">
                                    {updateAreasPermission && (
                                      <button
                                        className="float-left mr-2"
                                        onClick={() => openAreaForm(area)}
                                      >
                                        <EditOutlined className="text-xl" />
                                      </button>
                                    )}
                                    {deleteAreasPermission && (
                                      <Popconfirm
                                        title="Eliminar area"
                                        onConfirm={() =>
                                          handleDeleteArea(area.id)
                                        }
                                        okText="Si"
                                        cancelText="No"
                                      >
                                        <button className="float-left mr-2">
                                          <DeleteOutlined className="text-xl" />
                                        </button>
                                      </Popconfirm>
                                    )}
                                  </div>
                                )}
                              />
                            </Table>
                          )
                        )}
                      </div>
                    </>
                  )
                )}
              </CardPanel>
            </div>
          </div>
        </CenteredContainer>
      </MainLayout>
    </>
  );
};

export default withAuthentication(AreasListPage);
