import DashboardIcon from '@app-components/Icons/DashboardIcon';
import QuotesIcon from '@app-components/Icons/QuotesIcon';
import QuotingIcon from '@app-components/Icons/QuotingIcon';
import ServicesIcon from '@app-components/Icons/ServicesIcon';
import UsersIcon from '@app-components/Icons/UsersIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import { ChildrenProp } from '../types/generic';
import { useUserSession } from 'src/hooks/user-session';
import { PERMISSIONS } from '@app-utils/constants';
import { hasPermission } from '@app-utils/helpers';

const MenuItem = ({
  children,
  icon,
  link,
}: ChildrenProp & { icon: React.ReactNode; link: string }) => {
  return (
    <li className="w-full border-b border-b-zinc-600 last:border-b-0 text-[10px] text-center text-white hover:text-orange-600 hover:cursor-pointer hover:font-bold">
      <Link to={link} className="block py-2">
        {/* <span className="w-12 h-10 block mb-2 mx-auto bg-gray-600"></span> */}
        <div className="text-xl mx-auto table mb-2">{icon}</div>
        <span>{children}</span>
      </Link>
    </li>
  );
};

const Menu = () => {
  const { user } = useUserSession();
  const viewQuoteCreationPage = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.create.value,
  ]);
  const viewQuotesPage = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.view.value,
    PERMISSIONS.quote.options.viewSelf.value,
  ]);
  const viewServicesPage = hasPermission(user?.permissions, [
    PERMISSIONS.serviceCategory.options.view.value,
    PERMISSIONS.service.options.view.value,
  ]);
  const viewAreasPage = hasPermission(user?.permissions, [
    PERMISSIONS.areaType.options.view.value,
    PERMISSIONS.area.options.view.value,
  ]);
  const viewUsersPage = hasPermission(user?.permissions, [
    PERMISSIONS.user.options.admin.value,
  ]);

  return (
    <ul className="menu py-8">
      {viewQuoteCreationPage && (
        <MenuItem icon={<QuotingIcon />} link="/cotizador">
          Cotizador
        </MenuItem>
      )}
      {viewQuotesPage && (
        <MenuItem icon={<QuotesIcon />} link="/cotizaciones">
          Cotizaciones
        </MenuItem>
      )}
      <MenuItem icon={<DashboardIcon />} link="/">
        Dashboard
      </MenuItem>
      {viewServicesPage && (
        <MenuItem icon={<ServicesIcon />} link="/servicios">
          Servicios
        </MenuItem>
      )}
      {viewAreasPage && (
        <MenuItem icon={<ServicesIcon />} link="/areas">
          Áreas
        </MenuItem>
      )}
      {viewUsersPage && (
        <MenuItem icon={<UsersIcon />} link="/usuarios">
          Admin user
        </MenuItem>
      )}
    </ul>
  );
};

export default Menu;
