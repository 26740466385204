import React, { useReducer } from 'react';
import { Form } from 'antd';
import { ChildrenProp } from '@app-components/types/generic';
import { areasReducer } from '@app-reducers/areas';
import { areaTypesReducer } from '@app-reducers/area-types';
import {
  CreateQuoteContext,
  CreateQuoteContextValue,
} from './CreateQuoteContext';
import { quoteCreateReducer } from '@app-reducers/quote-create';
import { serviceCategoriesReducer } from '@app-reducers/service-categories';
import { servicesReducer } from '@app-reducers/services';

export const creationDataInitialValue = {
  current_stage: 1,
};

export const CreateQuoteContextProvider = ({ children }: ChildrenProp) => {
  const [form] = Form.useForm();
  const [areaTypesState, areaTypesDispatch] = useReducer(areaTypesReducer, {});
  const [areasState, areasDispatch] = useReducer(areasReducer, {});
  const [serviceCategoriesState, serviceCategoriesDispatch] = useReducer(
    serviceCategoriesReducer,
    {},
  );
  const [servicesState, servicesDispatch] = useReducer(servicesReducer, {});
  const [creationDataState, creationDataDispatch] = useReducer(
    quoteCreateReducer,
    creationDataInitialValue,
  );
  const contextValue: CreateQuoteContextValue = {
    areasReducer: [areasState, areasDispatch],
    areaTypesReducer: [areaTypesState, areaTypesDispatch],
    serviceCategoriesReducer: [
      serviceCategoriesState,
      serviceCategoriesDispatch,
    ],
    servicesReducer: [servicesState, servicesDispatch],
    form,
    creationDataReducer: [creationDataState, creationDataDispatch],
  };

  return (
    <CreateQuoteContext.Provider value={contextValue}>
      {children}
    </CreateQuoteContext.Provider>
  );
};
