import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { ChildrenProp, ClassnameProp } from '@app-components/types/generic';

type TabItemProps = ChildrenProp &
  ClassnameProp & {
    isActive?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    link?: string;
  };

export const TabItem = ({
  isActive = false,
  className = '',
  children,
  onClick,
  link = '',
}: TabItemProps) => {
  return (
    <Link to={link}>
      <button
        className={`text-black ${
          isActive ? 'font-bold' : ''
        } text-h3-title px-4 float-let relative top-0 left-0 ${className}`}
        onClick={onClick}
      >
        {children}
        {isActive && (
          <span className="absolute -bottom-5 w-full h-1 bg-orange-600 left-0"></span>
        )}
      </button>
    </Link>
  );
};
