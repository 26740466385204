import { AxiosResponse } from 'axios';
import { AreaTypeDTO } from '@app-entities/AreaType';
import { AreaTypesReducerDispatcher } from '@app-reducers/area-types';
import api from '../utils/api';
import { CreateAreaTypeDTO } from '@app-entities/CreateAreaType';
import { UUID } from '@app-entities/common';

export const fetchAreaTypes = async (
  dispatch: AreaTypesReducerDispatcher,
): Promise<void> => {
  const areaTypesResponse: AxiosResponse<AreaTypeDTO[]> = await api.get(
    '/areas/types',
  );
  dispatch({
    type: 'SET_DATA',
    payload: {
      data: areaTypesResponse.data,
    },
  });
};

export const createAreaType = async (
  data: CreateAreaTypeDTO,
): Promise<AreaTypeDTO> => {
  try {
    const response = await api.post<
      any,
      AxiosResponse<AreaTypeDTO>,
      CreateAreaTypeDTO
    >(`/areas/types`, data);

    return response.data;
  } catch (error) {
    throw new Error('Ocurrió un error al crear');
  }
};

export const updateAreaType = async (
  id: UUID,
  data: CreateAreaTypeDTO,
): Promise<AreaTypeDTO> => {
  try {
    const response = await api.put<
      any,
      AxiosResponse<AreaTypeDTO>,
      CreateAreaTypeDTO
    >(`/areas/types/${id}`, data);

    return response.data;
  } catch (error) {
    throw new Error('Ocurrió un error al guardar');
  }
};

export const deleteAreaType = async (id: UUID): Promise<void> => {
  try {
    await api.delete<any, AxiosResponse<any>>(`/areas/types/${id}`);
  } catch (error) {
    throw new Error('Ocurrió un error al eliminar');
  }
};
