import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { QuoteDTO } from '@app-entities/Quote';
import QuoteDetails from './QuoteDetails';
import QuoteLogos from './QuoteLogos';
import QuoteAreas from './QuoteAreas';
import QuoteCategories from './QuoteCategories';
import QuoteTotals from './QuoteTotals';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 50,
    fontFamily: 'Helvetica',
  },
  row: {},
  tableHeaderCell: {
    backgroundColor: '#131137',
    color: '#fff',
    border: '1px solid #9abacc',
    borderBottomWidth: '0px',
    padding: '1pt 2pt',
    fontSize: '3.5pt',
    fontWeight: 'black',
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase',
  },
  tableBodyCell: {
    border: '1pt solid #9abacc',
    borderLeftWidth: '0px',
    borderBottomWidth: '0px',
    padding: '1pt 2pt',
    fontSize: '4pt',
    textAlign: 'center',
    width: '26pt',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  tableBodySubHeaderCell: {
    fontFamily: 'Helvetica-Bold',
  },
  tableFirstCell: {
    borderLeftWidth: '1pt',
  },
  tableLastRowCells: {
    borderBottomWidth: '1pt',
  },
  infoTableBodyRow: { display: 'flex', flexDirection: 'row' },
  infoTableBodyCellLeft: {
    width: '125pt',
    textAlign: 'right',
  },
  infoTableBodyCellRight: {
    textAlign: 'left',
    width: '100%',
  },
  tableBodyCellConceptColumn: {
    width: '100pt',
  },
  tableBodyCellTotalColumn: {
    width: '70pt',
  },
  tableBodyCellSubtotalLabel: {
    width: 78,
    textAlign: 'left',
    alignContent: 'flex-start',
  },
  tableBodyCellSubtotalIndent: {
    width: 347,
    borderTop: '0px',
  },
});

const QuoteLogosSection = () => {
  return QuoteLogos();
};

const QuoteDetailsSection = ({ quote }: { quote: QuoteDTO }) => {
  return QuoteDetails(quote);
};

const QuoteAreasSection = ({ quote }: { quote: QuoteDTO }) => {
  return QuoteAreas(quote);
};

const QuoteCategoriesSection = ({ quote }: { quote: QuoteDTO }) => {
  return QuoteCategories(quote);
};

const QuoteTotalsSection = ({ quote }: { quote: QuoteDTO }) => {
  return QuoteTotals(quote);
};

const PDFDocument = (quote: QuoteDTO) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <QuoteLogosSection />
      <QuoteDetailsSection quote={quote} />
      <QuoteAreasSection quote={quote} />
      <QuoteCategoriesSection quote={quote} />
      <QuoteTotalsSection quote={quote} />
    </Page>
  </Document>
);

export default PDFDocument;
