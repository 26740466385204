import React, { useState } from 'react';
import LogoBlackIcon from '@app-components/Icons/LogoBlackIcon';
import { Button, Form, Input, message } from 'antd';
import { login } from '@app-actions/users';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { UserDTO } from '@app-entities/User';

const Login = () => {
  const [form] = Form.useForm();
  const [checkingLogin, setCheckingLogin] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setCheckingLogin(true);
    try {
      const user: UserDTO = await login({
        email: form.getFieldValue('email'),
        password: form.getFieldValue('password'),
      });

      Cookies.set('bcc_session', JSON.stringify(user), {
        expires: 1,
        sameSite: 'strict',
        secure: true,
      });

      navigate('/');
    } catch (e) {
      message.error(e.message);
      setCheckingLogin(false);
    }
  };

  return (
    <div className="App pl-20 w-full min-h-screen bg-gray-50">
      <div className="w-96 h-96 mx-auto pt-40">
        <div className="table mx-auto w-60">
          <img src="./logo-complete.svg" />
          {/* <LogoBlackIcon /> */}
        </div>

        <Form form={form} layout="vertical">
          <div className="grid grid-cols-1 mt-6">
            <Form.Item
              label={<span className="font-bold">Correo</span>}
              name="email"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: 'Es necesario ingresar un correo.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="grid grid-cols-1 mt-6">
            <Form.Item
              label={<span className="font-bold">Contraseña</span>}
              name="password"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: 'Es necesario ingresar una contraseña.',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>
          <div className="grid grid-cols-1 mt-6">
            <Button
              onClick={handleLogin}
              type="primary"
              className="block"
              disabled={checkingLogin}
              loading={checkingLogin}
            >
              Acceder
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
