import { UserDTO } from '@app-entities/User';
import { FormInstance } from 'antd';
import { create } from 'zustand';

interface UserFormState {
  isFormOpen: boolean;
  currentUser: Omit<UserDTO, 'password'> | null;
  form: FormInstance | null;
  openForm: (
    user?: Omit<UserDTO, 'password'>,
    values?: { [key: string]: any },
  ) => void;
  setFormInstance: (form: FormInstance) => void;
  closeForm: () => void;
}

export const userFormStore = create<UserFormState>((set) => ({
  isFormOpen: false,
  form: null,
  currentUser: null,
  openForm: (user) =>
    set((state) => {
      if (state.form) {
        state.form.resetFields();

        if (user) {
          state.form.setFieldsValue(user);
        }
      }

      return { currentUser: user ?? null, isFormOpen: true };
    }),
  setFormInstance: (form) => set((state) => ({ form })),
  closeForm: () => set((state) => ({ isFormOpen: false })),
}));
