import { AxiosResponse } from 'axios';
import { ServiceCategoryDTO } from '@app-entities/ServiceCategory';
import { ServiceCategoriesReducerDispatcher } from '@app-reducers/service-categories';
import api from '../utils/api';
import { UUID } from '@app-entities/common';
import { CreateServiceCategoryDTO } from '@app-entities/CreateServiceCategory';

export const fetchServiceCategories = async (
  dispatch: ServiceCategoriesReducerDispatcher,
): Promise<void> => {
  const serviceCategoriesResponse: AxiosResponse<ServiceCategoryDTO[]> =
    await api.get('/services/categories');
  dispatch({
    type: 'SET_DATA',
    payload: {
      data: serviceCategoriesResponse.data,
    },
  });
};

export const createServiceCategory = async (
  data: CreateServiceCategoryDTO,
): Promise<ServiceCategoryDTO> => {
  try {
    const response = await api.post<
      any,
      AxiosResponse<ServiceCategoryDTO>,
      CreateServiceCategoryDTO
    >(`/services/categories`, data);

    return response.data;
  } catch (error) {
    throw new Error('Ocurrió un error al crear');
  }
};

export const updateServiceCategory = async (
  id: UUID,
  data: CreateServiceCategoryDTO,
): Promise<ServiceCategoryDTO> => {
  try {
    const response = await api.put<
      any,
      AxiosResponse<ServiceCategoryDTO>,
      CreateServiceCategoryDTO
    >(`/services/categories/${id}`, data);

    return response.data;
  } catch (error) {
    throw new Error('Ocurrió un error al guardar');
  }
};

export const deleteServiceCategory = async (id: UUID): Promise<void> => {
  try {
    await api.delete<any, AxiosResponse<any>>(`/services/categories/${id}`);
  } catch (error) {
    throw new Error('Ocurrió un error al eliminar');
  }
};
