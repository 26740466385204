import { AreaDTO } from '@app-entities/Area';
import { FormInstance } from 'antd';
import { create } from 'zustand';

interface AreaFormState {
  isFormOpen: boolean;
  currentArea: AreaDTO | null;
  form: FormInstance | null;
  openForm: (area?: AreaDTO, values?: { [key: string]: any }) => void;
  setFormInstance: (form: FormInstance) => void;
  closeForm: () => void;
}

export const areaFormStore = create<AreaFormState>((set) => ({
  isFormOpen: false,
  form: null,
  currentArea: null,
  openForm: (area) =>
    set((state) => {
      if (state.form) {
        state.form.resetFields();

        if (area) {
          state.form.setFieldsValue(area);
        }
      }

      return { currentArea: area ?? null, isFormOpen: true };
    }),
  setFormInstance: (form) => set((state) => ({ form })),
  closeForm: () => set((state) => ({ isFormOpen: false })),
}));
