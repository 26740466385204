import React, { useEffect, useReducer, useState } from 'react';
import CardPanel from '@app-components/Layout/CardPanel';
import CenteredContainer from '@app-components/Layout/CenteredContainer';
import MainLayout from '@app-components/Layout/MainLayout';
import { Calendar, Select } from 'antd';
import { HeaderRender } from 'antd/es/calendar/generateCalendar';
import dayjs from '@app-utils/extendedDaysJs';
import { H2Title } from '@app-components/Layout/Text';
import { quotesReducer } from '@app-reducers/quotes';
import { fetchQuotes } from '@app-actions/quotes';
import { QuoteDTO } from '@app-entities/Quote';
import { getInstallationStartDate, hasPermission } from '@app-utils/helpers';
import { PERMISSIONS } from '@app-utils/constants';
import { useUserSession } from 'src/hooks/user-session';

const calendarHeader: HeaderRender<dayjs.Dayjs> = ({
  value,
  type,
  onChange,
  onTypeChange,
}) => {
  const year = value.year();
  const month = value.month();

  return (
    <div className="grid grid-cols-2 w-full">
      <div className="col-span-1">
        <H2Title>{value.format('MMMM')}</H2Title>
      </div>
      <div className="col-span-1">
        {/* <Select className="w-60 float-right" defaultValue={year}>
          {Array.from(Array(2))
            .map((value, index) => (
              <Select.Option key={year - index} value={year - index}>
                {year - index}
              </Select.Option>
            ))
            .reverse()}
          {Array.from(Array(2)).map((value, index) => (
            <Select.Option key={index + index + 1} value={year + index + 1}>
              {year + index + 1}
            </Select.Option>
          ))}
        </Select>

        <Select className="w-60 float-right mr-2" defaultValue={month}>
          {Array.from(Array(12)).map((value, index) => (
            <Select.Option key={index} value={index}>
              {dayjs().month(index).format('MMMM')}
            </Select.Option>
          ))}
        </Select> */}
      </div>
    </div>
  );
};

type OrderedQuotesDTO = {
  [key: string]: QuoteDTO[];
};

const QuotesCalendarPage = () => {
  const { user } = useUserSession();
  const [quotes, quotesDispatch] = useReducer(quotesReducer, {});
  const [orderedQuotes, setOrderedQuotes] = useState<OrderedQuotesDTO>({});

  const canViewAll = hasPermission(user?.permissions, [
    PERMISSIONS.quote.options.view.value,
  ]);

  useEffect(() => {
    (async () => {
      fetchQuotes(quotesDispatch);
    })();
  }, []);

  useEffect(() => {
    if (quotes.data) {
      const newOrderedQuotes: OrderedQuotesDTO = {};
      quotes.data.forEach((quote) => {
        if (!canViewAll && quote.user.id !== user?.id) return;

        const finalStartDate = getInstallationStartDate(
          dayjs(quote.event_start_date),
          Math.max(
            ...quote.areas.map((quoteArea) => quoteArea.installation_days),
          ),
        )
          .hour(0)
          .minute(0)
          .second(0);
        const finalEndDate = dayjs(quote.event_end_date)
          .add(
            Math.max(
              ...quote.areas.map((quoteArea) => quoteArea.uninstallation_days),
            ),
          )
          .hour(23)
          .minute(59)
          .second(59);

        const diffDays = finalEndDate.diff(finalStartDate, 'days');

        for (let i = 0; i <= diffDays; i++) {
          const currentDay = finalStartDate.add(i, 'day');
          const currentDateKey = currentDay.format('YYYY-MM-DD');

          if (newOrderedQuotes.hasOwnProperty(currentDateKey)) {
            newOrderedQuotes[currentDateKey] = [
              ...newOrderedQuotes[currentDateKey],
              quote,
            ];
          } else {
            newOrderedQuotes[currentDateKey] = [quote];
          }
        }
      });

      setOrderedQuotes(newOrderedQuotes);
    }
  }, [quotes]);

  const dateCellRender = (value: dayjs.Dayjs) => {
    const dateQuotes: QuoteDTO[] =
      orderedQuotes[value.format('YYYY-MM-DD')] ?? [];

    return (
      <ul className="w-full">
        {dateQuotes.map((quote, index) => (
          <li
            className="w-full px-2 py-1 bg-orange-600 text-white rounded-sm mb-1"
            key={index}
          >
            <p className="font-bold text-xs">{quote.event_name}</p>
            <p className="text-xs">{quote.atention_to}</p>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <MainLayout>
      <CenteredContainer width="full">
        <CardPanel>
          <Calendar
            headerRender={calendarHeader}
            mode="month"
            cellRender={dateCellRender}
          />
        </CardPanel>
      </CenteredContainer>
    </MainLayout>
  );
};

export default QuotesCalendarPage;
