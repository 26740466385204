import { AxiosResponse } from 'axios';
import { QuoteDTO } from '@app-entities/Quote';
import { QuotesReducerDispatcher } from '@app-reducers/quotes';
import api from '../utils/api';
import { HTTPFetchStateStatus, UUID } from '@app-entities/common';

export const fetchQuotes = async (
  dispatch: QuotesReducerDispatcher,
  params: {
    is_active?: boolean;
    is_archived?: boolean;
  } = {},
): Promise<void> => {
  const quotesResponse: AxiosResponse<QuoteDTO[]> = await api.get('/quotes', {
    params,
  });
  dispatch({
    type: 'SET_DATA',
    payload: {
      data: quotesResponse.data,
    },
  });
};

export const fetchQuote = async (id: UUID): Promise<QuoteDTO> => {
  const quotesResponse: AxiosResponse<QuoteDTO> = await api.get(
    `/quotes/${id}`,
  );
  return quotesResponse.data;
};

export const approveQuote = async (
  dispatch: QuotesReducerDispatcher,
  id: UUID,
): Promise<void> => {
  await api.patch(`/quotes/${id}/approve`);
};

export const toggleArchiveQuote = async (
  dispatch: QuotesReducerDispatcher,
  id: UUID,
): Promise<void> => {
  await api.patch(`/quotes/${id}/toggleArchive`);
};

export const setLoadingQuotes = async (
  dispatch: QuotesReducerDispatcher,
  status: HTTPFetchStateStatus,
) => {
  dispatch({
    type: 'SET_STATUS',
    payload: {
      status,
    },
  });
};
